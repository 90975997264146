'use strict';

import videoChatView from './video-chat.view.html';
import {VideoChatController} from './video-chat.controller.js';
import messageCarouselView from './dialogs/message-carousel.view.html';
import './video-chat.style.scss';

function config($stateProvider) {
    $stateProvider.state('meeting.videoChat', {
        url: 'video-chat',
        template: videoChatView,
        controller: 'VideoChatController',
        controllerAs: 'videoChat'
    });
}

export default angular
    .module('states.videoChat', [])
    .config(config)
    .controller('VideoChatController', VideoChatController)
    .run(($templateCache) => {
        $templateCache.put('custom-templates/message-carousel.view.html', messageCarouselView);
    });
