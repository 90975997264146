'use strict';

import './upload-session-assets.style.scss';

export default class uploadSessionAssetsController {
    constructor($uibModalInstance) {
        'ngInject';

        this.$uibModalInstance = $uibModalInstance;
    }

    ok() {
        this.$uibModalInstance.close();
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }
}
