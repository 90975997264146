import React from 'react';
import {observer} from 'mobx-react';
import {MeetingController} from '../../meeting.controller';
import {EndMeeting} from '../modal/end-meeting/EndMeeting';
import {Main} from '../main/Main';
import {TermsAndConditions} from '../modal/terms-and-conditions/Component';
import {MessageAlertWithCallback} from '@techsee/techsee-ui-common/lib/message-alert-with-callback';

import './Meeting.scss';

type MeetingLayoutProps = {
    meetingController: MeetingController;
};

@observer
export class MeetingLayout extends React.Component<MeetingLayoutProps> {
    render() {
        const {meetingController} = this.props;

        if (!meetingController.termsAndConditionsController) {
            return null;
        }

        return (
            <React.Fragment>
                {meetingController.messageAlertWithCallback.display && (
                    <MessageAlertWithCallback
                        label={meetingController.messageAlertWithCallback.label}
                        color={meetingController.messageAlertWithCallback.color}
                        callback={meetingController.messageAlertWithCallback.callback}
                        callbackMessage={
                            meetingController.messageAlertWithCallback.callbackMessage
                        }></MessageAlertWithCallback>
                )}
                <div className={'meeting-root-layout use-common-styles'}>
                    <div className={'meeting-popup-container'}>
                        <TermsAndConditions controller={meetingController.termsAndConditionsController} />
                        <EndMeeting />
                    </div>
                    <div className={'meeting-middle-area'}>
                        <Main controller={meetingController} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
