//TODO this service should do refactor after get rid of angular because the hash, when we remove angular so change it to query params
export interface IUrlUtilsService {
    getParamValue(param: string): string | null;
    setParamValue(param: string, value: string): void;
    getAbsoluteUrl(): string;
}

export class TsUrlUtilsService implements IUrlUtilsService {
    constructor() {}

    getParamValue(param: string): string | null {
        const currentHash = window.location.hash;
        const queryString = currentHash.includes('?') ? currentHash.split('?')[1] : window.location.search.substring(1); // Use search if hash doesn't have query params
        const params = new URLSearchParams(queryString);
        return params.get(param);
    }

    setParamValue(param: string, value: string): void {
        const currentHash = window.location.hash;
        const [path, queryString] = currentHash.includes('?')
            ? currentHash.split('?')
            : [currentHash, window.location.search.substring(1)]; // Use search if no hash query params

        const params = new URLSearchParams(queryString);
        params.set(param, value);

        if (currentHash.includes('#')) {
            // Update hash-based URL
            const newHash = `${path}?${params.toString()}`;
            window.location.hash = newHash;
        } else {
            // Update search-based URL
            const newSearch = `?${params.toString()}`;
            window.history.replaceState(null, '', `${window.location.pathname}${newSearch}`);
        }
    }

    getAbsoluteUrl(): string {
        return window.location.href;
    }
}
