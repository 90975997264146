'use strict';

import isEmpty from 'lodash/isEmpty';

import {CHECK_PENDING_REQUEST_INTERVAL, CHECK_PENDING_REQUEST_ATTEMPTS} from './ts-state-helper.settings';

// Service that wraps $state.go and allows changes in url, only when they are
// initiated by the program. i.e. it blocks use of the back button to switch
// between states.
export class TsStateHelperService {
    constructor($state, $rootScope, $http) {
        'ngInject';

        this.enabled = false;
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$http = $http;

        this._init();
    }

    _init() {
        let _resettingState = false;

        this.$rootScope.$on('$stateChangeStart', (ev, toState, toParams, fromState, fromParams) => {
            if (this.enabled && !this.bypass) {
                ev.preventDefault();

                // re-insert the state in the browser history, since stopping
                // the state from changing during the press of the back-button,
                // doesn't prevent the browser from popping it.
                if (!_resettingState) {
                    _resettingState = true;
                    this.$state.go(fromState, fromParams).finally(() => {
                        _resettingState = false;
                    });
                }
            }
        });
    }

    enable() {
        this.enabled = true;
    }

    disable() {
        this.enabled = false;
    }

    go(state, params, options) {
        this.bypass = true;

        return this.$state.go(state, params, options).finally(() => {
            this.bypass = false;
        });
    }

    safeGo(state, params, options) {
        if (this.safeGoInProgress) {
            return;
        }

        this.safeGoInProgress = true;

        let attempts = 0;

        this.checkPendingRequests = () => {
            try {
                if (!isEmpty(this.$http.pendingRequests) && attempts < CHECK_PENDING_REQUEST_ATTEMPTS) {
                    attempts++;

                    return setTimeout(() => {
                        this.checkPendingRequests();
                    }, CHECK_PENDING_REQUEST_INTERVAL);
                }

                this.safeGoInProgress = false;

                this.go(state, params, options);
            } catch (e) {
                this.safeGoInProgress = false;

                throw e;
            }
        };

        this.checkPendingRequests();
    }
}
