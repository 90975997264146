'use strict';
// @ts-ignore
import COMMON_STATUS_MESSAGES from '@techsee/techsee-common/lib/constants/status-messages.constant';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';

export const STATUS_MESSAGES = COMMON_STATUS_MESSAGES;

export const ROOM_COUNTER_TYPES = {
    CLIENT_SENT_IMAGES: 'clientSentImages',
    CLIENT_SENT_VIDEOS: 'clientSentVideos',
    CLIENT_RECEIVED_ANNOTATED_IMAGES: 'clientReceivedImages'
};

export const OBSERVER_JOIN_ALERT_TIMEOUT = 5000;
export const SWITCH_CAMERA_TIMEOUT = 5000;

export const ROOM_MODES = MeetingMode;

export const CAMERA_ACCESS = {
    CAMERA_APPROVED: 'Approved',
    CAMERA_REJECTED: 'Rejected'
};

export const PHOTO_SELECTION_STATE = {
    IN_PHOTO_CHAT: 'IN_PHOTO_CHAT',
    IN_CAMERA_DIALOG: 'IN_CAMERA_DIALOG'
};

export const VIEW_PAGES = {
    MAIN: 'main',
    CHAT: 'chat',
    IMAGE: 'image',
    SPEEDTEST: 'speedtest'
};

export const GALLERY_VIEW_PAGES = {
    MAIN: 'main',
    CHAT: 'chat',
    IMAGE: 'image',
    SPEEDTEST: 'speedtest'
};

export const MEDIA_COMPLETION_POPUP_TIMEOUT = 3000;
