'use strict';

import {tsScrollDownDirective} from './ts-scroll-down/ts-scroll-down.directive.js';
import {tsVideoPublisherDirective} from './ts-video-publisher/ts-video-publisher.directive.js';
import {tsFixTouchLinksDirective} from './ts-fix-touch-links/ts-fix-touch-links.directive.js';
import {tsAutoFocusDirective} from './ts-auto-focus/ts-auto-focus.directive.js';
import {hideModalBackdrop} from './hide-modal-backdrop/hide-modal-backdrop.js';
import {tsPinchZoomableDirective} from './ts-pinch-zoomable/ts-pinch-zoomable.directive';
import {onCarouselChangeDirective} from './on-carousel-change/on-carousel-change.directive';
import {compileDirective} from './compile/compile.directive';

export default angular
    .module('app.directives', ['passwordValidator.directive'])
    .directive('tsScrollDown', tsScrollDownDirective)
    .directive('tsVideoPublisher', tsVideoPublisherDirective)
    .directive('tsFixTouchLinks', tsFixTouchLinksDirective)
    .directive('tsAutoFocus', tsAutoFocusDirective)
    .directive('compile', compileDirective)
    .directive('hideModalBackdrop', hideModalBackdrop)
    .directive('pinchZoomable', tsPinchZoomableDirective)
    .directive('onCarouselChange', onCarouselChangeDirective);
