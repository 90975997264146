import React from 'react';
import {TechseeBaseComponent} from '../_shared/techsee-base-component';
import {IDesignable} from '../_shared/reusable-props';
import {RatingStar} from './rating-star';
import './styles.scss';

interface IRating extends IDesignable {
    onChange?: Function;
    value: number;
    max: number;
}

export class Rating extends TechseeBaseComponent<IRating> {
    renderInternal() {
        const {className, onChange, value, max} = this.props;

        this.addCssClass('rating');
        this.addCssClass(className);

        const stars = [];

        for (let i = 0; i < max; i++) {
            stars.push(<RatingStar key={i} active={i < value} value={i + 1} onClick={onChange} />);
        }

        return <div className={this.renderCssClasses()}>{stars}</div>;
    }

    static defaultProps: Readonly<IRating> = {
        value: 0,
        max: 5
    };
}
