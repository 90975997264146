import React, {RefObject} from 'react';
import {observer} from 'mobx-react';
import {MagicMarkerComponent, Multiparty} from '@techsee/techsee-media-service-client';
import {IVideoController} from './VideoController';
import {WaitingForAgentScreen} from './WaitingForAgentScreen';

import './VideoComponent.scss';

export type VideoComponentProps = {
    controller: IVideoController;
};

export const Video: React.FC<VideoComponentProps> = observer((props: VideoComponentProps): JSX.Element => {
    const {
        controller: {
            translate,
            multipartyController,
            onPermissionsModalVisibilityChanged,
            videoLayoutType,
            onEndMeetingRequested,
            magicMarkerController,
            isPeerMediaPermissionRejected,
            isAgentClickOnScreenShare,
            remoteController,
            isMobile
        }
    } = props;

    if (!multipartyController) {
        return <WaitingForAgentScreen text={translate('FACE_MEET.VIDEO.CONNECTING_TO_AGENT')} />;
    }

    const translations = {
        connectingCaption: translate('FACE_MEET.VIDEO.CONNECTING'),
        mediaRejectedAccessDenied: translate('REACT.MEDIA_REJECTED.VIEW.PERMISSION_DENIED'),
        mediaRejectedTextAndroid1: translate('REACT.MEDIA_REJECTED.VIEW.ANDROID1'),
        mediaRejectedTextAndroid2: translate('REACT.MEDIA_REJECTED.VIEW.ANDROID2'),
        mediaRejectedTextAndroid3: translate('REACT.MEDIA_REJECTED.VIEW.ANDROID3'),
        mediaRejectedTextAndroid4: translate('REACT.MEDIA_REJECTED.VIEW.ANDROID4'),
        mediaRejectedTextAndroid5: translate('REACT.MEDIA_REJECTED.VIEW.ANDROID5'),
        mediaRejectedTextChrome1: translate('REACT.MEDIA_REJECTED.VIEW.CHROME1'),
        mediaRejectedTextChrome2: translate('REACT.MEDIA_REJECTED.VIEW.CHROME2'),
        mediaRejectedTextChrome3: translate('REACT.MEDIA_REJECTED.VIEW.CHROME3'),
        mediaRejectedTextIos1: translate('REACT.MEDIA_REJECTED.VIEW.IOS1'),
        mediaRejectedTextIos2: translate('REACT.MEDIA_REJECTED.VIEW.IOS2'),
        mediaRejectedTextIos3: translate('REACT.MEDIA_REJECTED.VIEW.IOS3'),
        mediaRejectedModalTitle: translate('REACT.MEDIA_REJECTED.VIEW.TITLE'),
        waitingForFirstPeerCaption: translate('FACE_MEET.VIDEO.WAITING_FOR_AGENT'),
        mediaRejectedTipClick: translate('FACE_MEET.MEDIA_REJECTED.TIP.CLICK'),
        mediaRejectedTipLearn: translate('FACE_MEET.MEDIA_REJECTED.TIP.LEARN'),
        stopSharing: translate('FACE_MEET.DESKTOP_SHARING.STOP_SHARING')
    };

    const remoteDrawings = magicMarkerController.remoteDrawings;
    const magicMarkerOptions = magicMarkerController.options;
    const magicMarker =
        magicMarkerController.shouldDisplayComponent &&
        (({floatingElements, scale}: {floatingElements: RefObject<HTMLElement>[]; scale?: number}) => (
            <MagicMarkerComponent
                options={magicMarkerOptions}
                onDrawingsUpdate={magicMarkerController.sendDrawings}
                remoteDrawings={remoteDrawings}
                floatingElements={floatingElements}
                onRemoteDrawingsDisplayEnd={magicMarkerController.clearRemoteDrawings}
                scale={scale}
            />
        ));

    return (
        <div className={'video'}>
            <Multiparty
                isPeerMediaPermissionRejected={isPeerMediaPermissionRejected}
                controller={multipartyController}
                onEndMeetingRequested={onEndMeetingRequested}
                selectedLayoutType={videoLayoutType}
                translations={translations}
                onPermissionsModalVisibilityChanged={onPermissionsModalVisibilityChanged}
                videoOverlay={magicMarker || undefined}
                isAgentClickOnScreenShare={isAgentClickOnScreenShare}
                remoteController={remoteController}
                isMobile={isMobile}
            />
        </div>
    );
});
