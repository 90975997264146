'use strict';

import photoChatView from './photo-chat.view.html';
import {PhotoChatController} from './photo-chat.controller.js';
import './photo-chat.style.scss';

function config($stateProvider) {
    $stateProvider.state('meeting.photoChat', {
        url: 'photo-chat',
        template: photoChatView,
        controller: 'PhotoChatController',
        controllerAs: 'photoChat'
    });
}

export default angular
    .module('states.photoChat', [])
    .config(config)
    .controller('PhotoChatController', PhotoChatController);
