'use strict';

import changePasswordView from './change-password.view.html';
import {ChangePasswordController} from './change-password.controller.js';
import './change-password.style.scss';

function config($stateProvider) {
    $stateProvider.state('changePassword', {
        url: BASE_PATH + 'changePassword',
        template: changePasswordView,
        controller: 'ChangePasswordController',
        controllerAs: 'changePassword',
        resolve: {
            currentUser: (db) =>
                db.User.find('current')
                    .then((user) => user)
                    .catch((err) => {
                        window.location.href = DEFAULT_REDIRECT_URL;

                        // reject to prevent state from loading before the redirect happens
                        throw err;
                    }),
            accountData: (db, currentUser) => {
                if (!currentUser || !currentUser.accountId) {
                    return [];
                }

                return db.Account.find(currentUser.accountId, {bypassCache: true});
            }
        }
    });
}

export default angular
    .module('states.changePassword', [])
    .config(config)
    .controller('ChangePasswordController', ChangePasswordController);
