import includes from 'lodash/includes';
import find from 'lodash/find';

import './ts-fullscreen-gallery.style.scss';
import template from './ts-fullscreen-gallery.view.html';

import {IconColors, IconSizes} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';

class TsFullscreenGalleryController {
    constructor(tsFullscreenGalleryService, $filter) {
        'ngInject';

        this.isGalleryOpen = false;
        this.activeMessage = 0;
        this.isLast = false;
        this.isFirst = false;
        this._$filter = $filter;
        this.iconOptions = {IconColors, IconSizes};

        tsFullscreenGalleryService.on(tsFullscreenGalleryService.$events.OPEN_GALLERY, (eventArgs) => {
            const context = eventArgs.context;

            if (!includes(tsFullscreenGalleryService.$context, context)) {
                throw new Error('Full screen gallery context is not supported');
            }

            const filterData = $filter('fsGalleryMessagesFilter')(this.messages);

            if (context === tsFullscreenGalleryService.$context.NEW_MESSAGE_RECEIVED) {
                this.activeMessage = filterData.length - 1;
            } else if (context === tsFullscreenGalleryService.$context.MESSAGE_CLICKED) {
                const message =
                    find(filterData, (msg) => msg.index === eventArgs.data) || filterData[filterData.length - 1];

                this.activeMessage = filterData.indexOf(message);
            } else if (context === tsFullscreenGalleryService.$context.SET_COMPLETED) {
                const message =
                    find(filterData, (msg) => msg.index === eventArgs.data.index) || filterData[filterData.length - 1];

                this.activeMessage = filterData.indexOf(message);
            }

            this.isGalleryOpen = true;
        });

        tsFullscreenGalleryService.on(tsFullscreenGalleryService.$events.CLOSE_GALLERY, () => {
            this.isGalleryOpen = false;
        });
    }

    onSlideChanged(nextSlide) {
        const filterData = this._$filter('fsGalleryMessagesFilter')(this.messages);
        const currentIndex = nextSlide.slide.index;

        this.isLast = currentIndex === filterData.length - 1;
        this.isFirst = currentIndex === 0;
    }
}

export function tsFullscreenGalleryDirective() {
    return {
        restrict: 'EA',
        template: template,
        transclude: true,
        replace: true,
        scope: {},
        controller: TsFullscreenGalleryController,
        controllerAs: 'fsGallery',
        bindToController: {
            messages: '=',
            newDesign: '=',
            onClose: '&',
            showCloseBtn: '='
        }
    };
}
