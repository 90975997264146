'use strict';

import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {ICustomStringService} from '@techsee/techsee-client-infra/lib/services/CustomString';

export interface ITsTranslationHelper {
    getAccountCustomStrings(): any;
    storeAccountCustomStrings(accountId: string): Promise<void>;
}

export class TsTranslationHelper implements ITsTranslationHelper {
    private customStringsApi: ICustomStringService;
    private accountCustomStrings: any;

    constructor(customStringsApi: ICustomStringService) {
        this.customStringsApi = customStringsApi;
        this.accountCustomStrings = {};
    }

    /**
     * gets and stores custom translations for an account
     * @param accountId
     */
    storeAccountCustomStrings(accountId: string) {
        if (!accountId) {
            return Promise.resolve({});
        }

        return (
            this.customStringsApi
                .getCustomStrings(accountId, PlatformType.mobile_web)
                // eslint-disable-next-line no-return-assign
                .then((result: any) => (this.accountCustomStrings = result.data))
        );
    }

    getAccountCustomStrings() {
        return this.accountCustomStrings;
    }
}
