import React from 'react';
import {observer} from 'mobx-react';
import {MeetingController} from '../../meeting.controller';
import {Video} from '../video/Video';

export type MainComponentProps = {
    controller: MeetingController;
};

export const Main: React.FC<MainComponentProps> = observer(({controller}): React.ReactElement | null =>
    controller.videoController ? <Video controller={controller.videoController} /> : null
);
