import PinchZoom from '@techsee/pinch-zoom-js';

export function tsPinchZoomableDirective($timeout) {
    'ngInject';

    return {
        restrict: 'A',
        scope: false,
        link: function (scope, elem) {
            const DNAME = 'pinchZoom';

            elem.on('load', function () {
                $timeout(() => {
                    let pinchZoom = elem.data(DNAME);

                    if (!pinchZoom) {
                        pinchZoom = new PinchZoom(elem[0]);
                        pinchZoom.enable();
                        elem.data(DNAME, pinchZoom);
                    }
                });
            });

            elem.bind('$destroy', () => {
                let pinchZoom = elem.data(DNAME);

                if (pinchZoom) {
                    pinchZoom.disable();
                    pinchZoom = null;
                    elem.data(DNAME, pinchZoom);
                }
            });
        }
    };
}
