'use strict';

export const TEST_UPLOAD_STATUS = {
    STARTED: 1,
    WAITING_TO_RETRY: 2,
    RETRY_STARTED: 3,
    FINISHED: 4,
    ERROR: 5
};

export const TEST_UPLOAD_RETRY_DELAY = 1000;
