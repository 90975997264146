'use strict';

import tsBusyBackgroundView from './ts-busy-background.view.html';
import './ts-busy-background.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

class tsBusyBackgroundController {
    constructor($rootScope, $localStorage) {
        'ngInject';

        this.rootScope = $rootScope;
        this.localStorage = $localStorage;
        this._brandingService = getRootStore().brandingService;
    }

    get theme() {
        return this.rootScope.THEME || '';
    }

    get themePath() {
        return this.theme + '/watermark-photo.png';
    }

    get requireImage() {
        return this.rootScope.requireImage;
    }

    get mobileBranding() {
        return this._brandingService.getBrandingData();
    }
}

export function tsBusyBackgroundDirective() {
    'ngInject';

    return {
        template: tsBusyBackgroundView,
        restrict: 'E',
        scope: {},
        bindToController: {},
        controller: tsBusyBackgroundController,
        controllerAs: 'vm'
    };
}
