import React, {Component} from 'react';
import {EndMeetingController} from './EndMeetingController';
import {observer} from 'mobx-react';
import ConfirmationModal from '../../../../components/generic/confirmation-modal/index';

import './styles.scss';

interface IEndMeeting {
    controller: EndMeetingController;
}

@observer
export class EndMeetingComponent extends Component<IEndMeeting, any> {
    render() {
        const {controller} = this.props;

        if (!controller || !controller.isEndMeetingVisible) {
            return <React.Fragment></React.Fragment>;
        }

        return (
            <ConfirmationModal
                className={'end-meeting'}
                show={controller.isEndMeetingVisible}
                isCloseVisible={false}
                onNegativeClick={() => controller.hideEndMeeting()}
                onPositiveClick={() => controller.onEndMeeting()}
                contentText={controller.translate('REACT.END_POP_UP.VIEW.TITLE')}
                positiveText={controller.translate('REACT.END_POP_UP.VIEW.LEAVE_SESSION')}
                negativeText={controller.translate('REACT.END_POP_UP.VIEW.CANCEL')}></ConfirmationModal>
        );
    }
}
