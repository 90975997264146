'use strict';
import tsStateTitleView from './ts-state-title.view.html';
import './ts-state-title.style.scss';

export function tsStateTitleDirective() {
    'ngInject';

    return {
        template: tsStateTitleView,
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {}
    };
}
