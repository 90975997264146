import React from 'react';
import {observer} from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import {TechseeBaseComponent} from '../../_shared/techsee-base-component';
import {SimpleLabel} from '../labels/simple';
import {LabelWithIcon} from '../labels/with-icon';
import {IDesignable, IUnique} from '../../_shared/reusable-props';

import './styles.scss';

interface IFormGroupDefaults {
    isRequired?: boolean;
    label?: string;
    labelFor?: string;
    errors?: string[];
    showErrors?: boolean;
    iconName?: string;
}

export interface IFormGroupProps extends IFormGroupDefaults, IUnique, IDesignable {}

@observer
export class TsFormGroup extends TechseeBaseComponent<IFormGroupProps, {}> {
    renderInternal() {
        const {uid, label, className, isRequired} = this.props;

        this.addCssClass(className);
        this.addCssClassIf('required', isRequired);
        this.addCssClassIf('no-label', isEmpty(label));

        return (
            <FormGroup
                id={uid + '-field-container'}
                className={this.renderCssClasses()}
                validationState={this.validationState}>
                {!isEmpty(label) && this.getLabelComponent()}
                {this.props.children}
                <div className={this.errorMessages ? 'errors-container' : 'errors-container-empty'}>
                    {this.errorMessages}
                </div>
            </FormGroup>
        );
    }

    private getLabelComponent() {
        const {iconName, labelFor, label} = this.props;

        return !iconName ? (
            <SimpleLabel labelFor={labelFor} className={'control-label'}>
                {label}:
            </SimpleLabel>
        ) : (
            <LabelWithIcon labelFor={labelFor} icon={iconName} className={'control-label'}>
                {label}:
            </LabelWithIcon>
        );
    }

    private get validationState() {
        return this.props.showErrors && this.props.errors && this.props.errors.length > 0 ? 'error' : null;
    }

    private get errorMessages() {
        const {errors, showErrors} = this.props;

        return showErrors && errors
            ? errors.map((error, index) => <div key={index} dangerouslySetInnerHTML={{__html: error}}></div>)
            : null;
    }

    static defaultProps: Readonly<IFormGroupDefaults> = {
        isRequired: false,
        label: '',
        labelFor: '',
        errors: [],
        showErrors: false,
        iconName: ''
    };
}

export default TsFormGroup;
