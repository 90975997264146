import React from 'react';
import {inject, IReactComponent, observer} from 'mobx-react';
import {ITranslate} from '../../../../services/LocalizationService';

import './style.scss';

interface Props {
    translate: ITranslate;
    requireImage: any;
}

const mapStoresToProps = (stores: any) =>
    ({
        translate: stores.translate,
        requireImage: stores.requireImage
    }) as Props;

const WebRtcNotSupportedInternal: React.FC<Props> = observer(
    ({translate, requireImage}): React.ReactElement => (
        <div className='webrtc-ns-container'>
            <img className='webrtc-ns-logo' src={requireImage('techsee-logo-blue.png')} />
            <div className='webrtc-ns-title'>{translate('REACT.END_WEBRTC.TITLE')}</div>
            <div className='webrtc-ns-text'>{translate('REACT.END_WEBRTC.MESSAGE')}</div>
        </div>
    )
);

export const WebRtcNotSupported = inject(mapStoresToProps)(WebRtcNotSupportedInternal) as IReactComponent;
