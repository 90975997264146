import {inject, IReactComponent} from 'mobx-react';
import * as view from './view';

const mapStoresToProps = (stores: any) =>
    ({
        ctrl: stores.surveyController,
        requireImage: stores.requireImage,
        translate: stores.translate
    }) as view.ISurveyViewProps;

const SurveyView = inject(mapStoresToProps)(view.SurveyView) as IReactComponent;

export default SurveyView;
