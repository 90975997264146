import get from 'lodash/get';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../app.bootstrap';
import {MobileChatApiService} from '../ts-chat-api/MobileChatApi';
import {TsEventService} from '../ts-event-service/ts-event-service';
import {MobileAppMediaService, IMobileAppMediaService} from '../media-service-api/MobileAppMediaService';

export interface ITsFlashlightHelper {
    isTorchOnHintVisible: boolean;
    isTorchOn: boolean;
    initMobileAppMediaService(mobileAppMediaService: MobileAppMediaService): void;
    showFlashlightGuideHint(value: boolean): void;
    setTorch(value: boolean): void;
    turnFlashlight(turn: boolean): Promise<boolean>;
    toggleLightMobile(): Promise<boolean | undefined>;
}

export class TsFlashlightHelper {
    private chatApi: MobileChatApiService;
    private EventService: TsEventService;
    public isTorchOnHintVisible: boolean;
    public isTorchOn: boolean;
    private mobileAppMediaService!: IMobileAppMediaService;

    constructor() {
        this.chatApi = getRootStore().chatApi;
        this.EventService = getRootStore().eventService as TsEventService;

        this.isTorchOnHintVisible = false;
        this.isTorchOn = false;

        this._initHandlers();
    }

    initMobileAppMediaService(mobileAppMediaService: IMobileAppMediaService) {
        this.mobileAppMediaService = mobileAppMediaService;
    }

    _initHandlers() {
        const runTurnOnFlashlightGuideListener = (value: boolean) => this.showFlashlightGuideHint(value);

        this.chatApi.onReady(() => {
            this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.IS_TORCH_ON, this.isTorchOn);
            this.chatApi.on(
                socketEvents.CLIENT_IN_CHAT_API.RUN_TURN_ON_FLASHLIGHT_GUIDE_ACTION,
                runTurnOnFlashlightGuideListener
            );
        });
    }

    showFlashlightGuideHint(value: boolean): void {
        this.isTorchOnHintVisible = value;
        setTimeout(() => (this.isTorchOnHintVisible = false), 1000);
    }

    setTorch(value: boolean): void {
        this.isTorchOn = value;
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.IS_TORCH_ON, this.isTorchOn);
    }

    turnFlashlight(turn: boolean): Promise<boolean> {
        this.EventService.sendEventLog(
            'none',
            this.chatApi.currentRoomId || 'none',
            turn ? LOG_EVENTS.flashlightRequestTurnOn : LOG_EVENTS.flashlightRequestTurnOff,
            {}
        );

        return this.mobileAppMediaService.turnFlashlight(turn).then((success: boolean) => {
            if (success) {
                this.setTorch(turn);
            }

            this.EventService.sendEventLog(
                'none',
                this.chatApi.currentRoomId || 'none',
                success ? LOG_EVENTS.flashlightStatus : LOG_EVENTS.flashlightError.type,
                {
                    status: turn
                }
            );

            return success;
        });
    }

    toggleLightMobile(): Promise<boolean | undefined> {
        const flashlightTimeout = get(this.chatApi, 'accountSettings.flashlightTimeout', 180) * 1000;

        const turn = !this.isTorchOn;

        return this.turnFlashlight(turn).then((success: boolean) => {
            if (!success) {
                return;
            }

            if (this.isTorchOn) {
                return this.mobileAppMediaService.setAutoLightOffTimer(flashlightTimeout).then(() => {
                    this.setTorch(false);

                    return this.isTorchOn;
                });
            }

            return this.isTorchOn;
        });
    }
}
