'use strict';

import expertView from './expert.view.html';
import {ExpertController} from './expert.controller.js';
import './expert.style.scss';

function config($stateProvider) {
    $stateProvider.state('start.expert', {
        url: '/expert',
        template: expertView,
        controller: 'ExpertController',
        controllerAs: 'expert',
        resolve: {
            selectState: (accountData, tsStateHelper, currentUser, csiSettings) => {
                if (!currentUser && csiSettings) {
                    return;
                }

                const voiceCalls =
                        accountData.settings &&
                        accountData.settings.fsSettings &&
                        accountData.settings.fsSettings.supportVoiceCalls,
                    callGroup =
                        accountData.settings &&
                        accountData.settings.fsSettings &&
                        accountData.settings.fsSettings.voiceCallFromGroup,
                    callAddressBook =
                        accountData.settings &&
                        accountData.settings.fsSettings &&
                        accountData.settings.fsSettings.voiceCallAddressBook;

                if (!voiceCalls || (!callGroup && !callAddressBook)) {
                    tsStateHelper.safeGo('start.invite');
                }
            }
        }
    });
}

export default angular.module('states.expert', []).config(config).controller('ExpertController', ExpertController);
