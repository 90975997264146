/* eslint-disable @typescript-eslint/no-explicit-any */
'use strict';

// @ts-ignore
import desktopSharingView from './desktop-sharing.view.html';
import {DesktopSharingController} from './desktop-sharing.controller';
import {
    DesktopShareService,
    IDesktopShareService
} from '@techsee/techsee-client-infra/lib/services/DesktopShareService';
// @ts-ignore
import utils from '@techsee/techsee-common/lib/utils';

import './desktop-sharing.style.scss';

declare const angular: any;

config.$inject = ['$stateProvider'];

function config($stateProvider: any) {
    $stateProvider.state('meeting.desktopSharing', {
        url: 'desktopSharing',
        template: desktopSharingView,
        controller: 'DesktopSharingController',
        controllerAs: 'desktopSharing',
        resolve: {
            branding: () => {
                const hostname = window.location.hostname;

                // @ts-ignore
                const backendUrl = utils.getBackendUrl(API_URL, {hostname, ENV, AUTO_SUBDOMAIN, DEFAULT_SUBDOMAIN});
                const subdomain = hostname
                    .replace(/(^\w+:|^)\/\//, '')
                    .toLowerCase()
                    .split('.')[0];

                const desktopShareService: IDesktopShareService = new DesktopShareService(backendUrl);

                return desktopShareService.getBrandingBySubDomain(subdomain).then((data) => data.branding);
            }
        }
    });
}

export default angular
    .module('states.desktopSharing', [])
    .config(config)
    .controller('DesktopSharingController', DesktopSharingController);
