'use strict';

import './media-uploading.style.scss';

export class MediaUploadingController {
    constructor(newMode, title) {
        'ngInject';

        this.newMode = newMode;
        this.title = title;
    }

    modalInit() {
        if (this.newMode) {
            const modalContent = $('.modal-content');

            modalContent.css('box-shadow', 'none');
            modalContent.css('border', '0');
            modalContent.css('background-color', 'transparent');
        }
    }
}
