import {action, computed, observable} from 'mobx';
import {getRootStore} from '../../_react_/app.bootstrap';

export interface IErrorMessageCloseSessionController {
    readonly isVisible: boolean;
    okButton: string;
    errorMessage: string;

    onClickFinish(): void;
    show(): void;
}

export class ErrorMessageCloseSessionController implements IErrorMessageCloseSessionController {
    private onClickButton: (endParams: any) => void;

    private _endParams: any;

    okButton = '';

    errorMessage = '';

    @observable private _isVisible: boolean;

    constructor(onClickButton: (endParams: any) => void, endParams: any) {
        this._isVisible = false;
        this.onClickButton = onClickButton;
        this._endParams = endParams;

        this.okButton = getRootStore().localizationService.translate('REACT.ERROR_END_MEETING.VIEW.BUTTON');
        this.errorMessage = getRootStore().localizationService.translate('REACT.ERROR_END_MEETING.VIEW.MESSAGE');

        this.show = this.show.bind(this);
        this.onClickFinish = this.onClickFinish.bind(this);
    }

    @action
    show() {
        this._isVisible = true;
    }

    @computed
    get isVisible() {
        return this._isVisible;
    }

    onClickFinish() {
        this.onClickButton(this._endParams);
    }
}
