'use strict';

export default class EndMeetingConfirmationController {
    constructor($uibModalInstance, newStyle) {
        'ngInject';

        this.$uibModalInstance = $uibModalInstance;
        this.newStyle = newStyle;
    }

    ok() {
        this.$uibModalInstance.close();
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }
}
