'use strict';

export function tsLinkify() {
    'ngInject';

    /**
     * [return description]
     * @param  {String}  text   Some text that may contain links
     * @return {String}         The text, with all links converted to <a> tags
     */
    return (text) => TsUtils.linkify(text);
}
