import {inject, IReactComponent} from 'mobx-react';
import {EndMeetingComponent} from './EndMeetingComponent';

function mapStoresToProps(stores: any) {
    return {
        controller: stores.endMeetingController
    };
}

export const EndMeeting = inject(mapStoresToProps)(EndMeetingComponent) as IReactComponent;
