import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import './ts-iframe-overlay.style.scss';
import Translate from '../Translate';

interface TsIframeOverlayProps {
    iframeSrc?: string;
    onToggle: (visible: boolean) => void;
    children: React.ReactNode;
}

export const IframeOverlay: React.FC<TsIframeOverlayProps> = ({iframeSrc, onToggle, children}) => {
    const [visible, setVisible] = useState(false);
    const overlayRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!overlayRef.current) {
            overlayRef.current = document.createElement('div');
            overlayRef.current.classList.add('ts-iframe-overlay-container');
            document.body.appendChild(overlayRef.current);

            toggleOverlay(false);
        }

        return () => {
            if (overlayRef.current) {
                document.body.removeChild(overlayRef.current);
            }
        };
    }, []);

    const toggleOverlay = (show: boolean) => {
        setVisible(show);
        onToggle(show);

        if (overlayRef.current) {
            if (show) {
                overlayRef.current.style.opacity = '1';
                overlayRef.current.style.visibility = 'visible';
                overlayRef.current.style.pointerEvents = 'auto';
            } else {
                overlayRef.current.style.opacity = '0';
                overlayRef.current.style.visibility = 'hidden';
                overlayRef.current.style.pointerEvents = 'none';
            }
        }
    };

    return (
        <div className='ts-iframe-overlay'>
            <span className='ts-iframe-overlay-button' onClick={() => toggleOverlay(true)}>
                {children}
            </span>

            {visible &&
                overlayRef.current &&
                ReactDOM.createPortal(
                    <div className='ts-iframe-overlay ts-iframe-overlay-full-size'>
                        <div className='ts-iframe-scroll-container'>
                            <iframe src={iframeSrc} title='iframe-content' width={'100%'} height={'100%'}></iframe>
                        </div>
                        <hr />
                        <div className='ts-iframe-overlay-close text-center'>
                            <button
                                type='button'
                                className='btn btn-default btn-block'
                                onClick={() => toggleOverlay(false)}>
                                <Translate text={'IFRAME.COMPONENT.CLOSE'} />
                            </button>
                        </div>
                    </div>,
                    overlayRef.current
                )}
        </div>
    );
};
