import {action, computed, observable} from 'mobx';

export interface IEndMeetingController {
    showEndMeeting(): void;
}

export class EndMeetingController implements IEndMeetingController {
    @observable private _isVisible: boolean;

    private _translate: any;

    private _onConfirm: any;

    constructor(onConfirm: () => void, translate: any) {
        this._onConfirm = onConfirm;
        this._isVisible = false;
        this._translate = translate;
    }

    get translate() {
        return this._translate;
    }

    @computed
    get isEndMeetingVisible() {
        return this._isVisible;
    }

    @action
    showEndMeeting = () => {
        this._isVisible = true;
    };

    @action
    hideEndMeeting = () => {
        this._isVisible = false;
    };

    onEndMeeting = () => {
        this._onConfirm();
    };
}
