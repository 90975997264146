'use strict';

import tsVideoPlayerView from './ts-video-player.view.html';
import './ts-video-player.style.scss';

class tsVideoPlayerController {
    constructor($element, $scope, $document) {
        'ngInject';

        this.$element = $element;
        this.$scope = $scope;
        this.$document = $document;

        this.videoVisible = false;
    }

    init() {
        this.videoElement = this.$element[0].querySelector('video');

        this.videoElement.crossOrigin = 'Anonymous';

        this.videoElement.src = this.video;
    }

    toggleVideo() {
        if (this.videoVisible) {
            this.videoVisible = false;

            this.videoElement.pause();

            if (this.$document.mozCancelFullScreen) {
                this.$document.mozCancelFullScreen();
            } else if (this.videoElement.webkitExitFullScreen) {
                this.videoElement.webkitExitFullScreen();
            }
        } else {
            if (this.videoElement.webkitRequestFullScreen) {
                this.videoElement.webkitRequestFullScreen();
            }

            this.videoVisible = true;

            this.videoElement.play();
        }
    }
}

function linkFn(scope, element, attrs, ctrl) {
    ctrl.init();
}

export function tsVideoPlayerDirective() {
    'ngInject';

    return {
        template: tsVideoPlayerView,
        restrict: 'E',
        replace: true,
        scope: {},
        bindToController: {
            video: '=',
            thumbnail: '=',
            altText: '='
        },
        controller: tsVideoPlayerController,
        controllerAs: 'vm',
        link: linkFn
    };
}
