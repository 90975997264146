/* eslint-disable consistent-return */
'use strict';

import get from 'lodash/get';

import {SupportCheckResult} from '@techsee/techsee-common/lib/constants/room.constants';
import {CHROME_REDIRECTION_TIMEOUT, NOT_SUPPORTED_ERROR} from './ts-browser-detect.settings';
import {getBrowserInfo} from '../../../../../shared/browser-info';
import {getRootStore} from '../../app.bootstrap';
import {IDeviceService} from '@techsee/techsee-client-infra/lib/services/DeviceService';

export interface ITsBrowserDetect {
    run(
        callback: (...args: any) => void,
        beforeRedirectionCallback: (...args: any) => void,
        referrer: string | undefined,
        intentDisabled: boolean
    ): void;
}

/**
 * TsBrowserDetectService checks if the current browser is the
 * default Android one, in which case it tries to load Chrome
 */
export class TsBrowserDetectService {
    private window: any;
    private urlUtils: any;
    private deviceApiService: IDeviceService;
    private supportMode: SupportCheckResult | null = null;

    constructor(deviceApiService: IDeviceService) {
        this.window = window;
        this.deviceApiService = deviceApiService;
        this.urlUtils = getRootStore().urlUtils;
    }

    async _checkSupport(referrer: string | undefined) {
        if (this.supportMode) {
            return Promise.resolve(this.supportMode);
        }

        const browserInfo = (await getBrowserInfo()) as any;

        const userAgent = browserInfo.userAgent;
        const clientHints = browserInfo.clientHints;
        const base64ClientHints = clientHints ? btoa(JSON.stringify(clientHints)) : undefined;
        const shortId = this.urlUtils.getParamValue('g');

        const data: Record<string, string | undefined> = {ua: userAgent, shortId: shortId, referrer: referrer};

        if (clientHints) {
            data.clientHints = base64ClientHints;
        }

        return this.deviceApiService
            .checkSupport({...data})
            .then((response: any) => {
                this.supportMode = get(response, 'data.result');

                return this.supportMode;
            })
            .catch((err: any) => {
                console.error(err);

                // Fallback to normal
                this.supportMode = SupportCheckResult.normal;

                return this.supportMode;
            });
    }

    run(
        callback: (...args: any) => void,
        beforeRedirectionCallback: (...args: any) => void,
        referrer: string | undefined,
        intentDisabled: boolean
    ) {
        this._checkSupport(referrer).then(() => {
            if (!callback) {
                return;
            }

            switch (this.supportMode) {
                case SupportCheckResult.notSupported:
                    return callback(NOT_SUPPORTED_ERROR);

                case SupportCheckResult.chromeRedirection:
                case SupportCheckResult.unsupportedWhenChromeRedirectionFail:
                    if (intentDisabled) {
                        return callback(
                            this.supportMode === SupportCheckResult.chromeRedirection ? null : NOT_SUPPORTED_ERROR
                        );
                    }

                    return beforeRedirectionCallback(() => {
                        // if it's and old version of android browser, we try to load chrome
                        this.window.location.assign(this._getIntentUrl());

                        // Give chrome grace time to load and take the session. If redirection fails or user switch back to
                        // the current browser, the current browser will take the session. The other browser will be redirected
                        // to "End meeting" (due to "force browsing" feature)
                        setTimeout(() => {
                            // We're here as chrome is not installed or redirection doesn't work

                            if (this.supportMode === SupportCheckResult.unsupportedWhenChromeRedirectionFail) {
                                return callback(NOT_SUPPORTED_ERROR);
                            }

                            return callback(null, true);
                        }, CHROME_REDIRECTION_TIMEOUT);
                    });

                default:
                    return callback(null);
            }
        });
    }

    _getIntentUrl() {
        // The hashes must be removed since there is no
        // valid way to include them in the intent URI.
        const scheme = this.window.location.protocol.replace(/:.*$/, ''),
            url = this.window.location.href.replace(/https?:\/\//, '').replace('#/', ''),
            fallbackUrl = encodeURI(this.window.location.href.replace('#/', '') + '&intent=no');

        return `intent:\/\/${url}#Intent;scheme=${scheme};package=com.android.chrome;S.browser_fallback_url=${fallbackUrl};end`;
    }
}
