import get from 'lodash/get';
import includes from 'lodash/includes';

import {STATUS_MESSAGES} from '../../states/meeting/meeting.settings';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../_react_/app.bootstrap';

const preApproveEnvs = {
    iOS: 'iOS',
    Android: 'Android',
    Other: 'Other'
};

const preCameraFlags = {
    isOnPreCameraApprovalScreen: 'isOnPreCameraApprovalScreen',
    isPreCameraApprovedByUser: 'isPreCameraApprovedByUser'
};

export class PreCameraPermissionService {
    constructor() {
        'ngInject';

        this._tsWebRtcDetector = getRootStore().webRtcDetector;
        this._tsEnvironmentDetect = getRootStore().environmentDetect;
        this._chatApi = getRootStore().chatApi;
        this._events = getRootStore().eventService;

        this._approvalPromise = null;
        this._resolveApproval = null;
        this._waitingForUserApproval = false;

        this.cameraPreApprove = this.cameraPreApprove.bind(this);
        this.onUserApproval = this.onUserApproval.bind(this);
        this._chatApi.on(socketEvents.CLIENT_IN.CLIENT_MODE, this._abort.bind(this));
    }

    get waitingForUserApproval() {
        return this._waitingForUserApproval;
    }

    onUserApproval() {
        this._waitingForUserApproval = false;

        if (this._resolveApproval) {
            this._resolveApproval(true);
        }

        this._chatApi.setStatus(preCameraFlags.isOnPreCameraApprovalScreen, false);
        this._chatApi.setStatus(preCameraFlags.isPreCameraApprovedByUser, true);

        this._events.sendEventLog('none', this._chatApi.roomId || 'none', STATUS_MESSAGES.CAMERA_PRE_APPROVAL_ACCEPTED);

        this._chatApi.sendLog(STATUS_MESSAGES.CAMERA_PRE_APPROVAL_ACCEPTED);
    }

    cameraPreApprove() {
        if (!this._approvalPromise) {
            this._approvalPromise = new Promise((resolve) => {
                if (!this._isPreApprovalEnabledOnDevice()) {
                    resolve(true);

                    return;
                }

                this._isCameraApprovedAlready().then((isCameraApproved) => {
                    if (isCameraApproved) {
                        resolve(true);

                        return;
                    }

                    this._resolveApproval = resolve;
                    this._waitingForUserApproval = true;

                    this._chatApi.setStatus(preCameraFlags.isOnPreCameraApprovalScreen, true);

                    this._events.sendEventLog(
                        'none',
                        this._chatApi.roomId || 'none',
                        STATUS_MESSAGES.WAITING_FOR_CAMERA_PRE_APPROVAL
                    );

                    this._chatApi.sendLog(STATUS_MESSAGES.WAITING_FOR_CAMERA_PRE_APPROVAL);
                });
            });
        }

        return this._approvalPromise;
    }

    _abort() {
        this._approvalPromise = null;
        this._resolveApproval = null;
        this._waitingForUserApproval = false;
        this._chatApi.setStatus(preCameraFlags.isOnPreCameraApprovalScreen, false);
    }

    _isCameraApprovedAlready() {
        return new Promise((resolve) => {
            const wasApprovedInCurrentSession = !!get(this._chatApi.client, preCameraFlags.isPreCameraApprovedByUser);

            if (wasApprovedInCurrentSession) {
                resolve(true);

                return;
            }

            this._tsWebRtcDetector.getWebRtcInfo().then((rtcSupport) => {
                resolve(rtcSupport.isWebsiteHasWebcamPermissions);
            });
        });
    }

    _isPreApprovalEnabledOnDevice() {
        let isPreApprovalEnabledOnDevice = false;
        const isIOS = this._tsEnvironmentDetect.isIOS();
        const isAndroid = this._tsEnvironmentDetect.isAndroid();

        if (get(this._chatApi, 'accountSettings.isCameraPreApprovalEnabled') === true) {
            const enabledEnvs = get(this._chatApi, 'accountSettings.cameraPreApprovalEnvironments');

            if (isIOS && includes(enabledEnvs, preApproveEnvs.iOS)) {
                isPreApprovalEnabledOnDevice = true;
            } else if (isAndroid && includes(enabledEnvs, preApproveEnvs.Android)) {
                isPreApprovalEnabledOnDevice = true;
            } else if (!isIOS && !isAndroid && includes(enabledEnvs, preApproveEnvs.Other)) {
                isPreApprovalEnabledOnDevice = true;
            }
        }

        if (
            get(this._chatApi, 'client.mode') === MeetingMode.screen &&
            get(this._chatApi, 'client.clientType') === PlatformType.desktop_web
        ) {
            isPreApprovalEnabledOnDevice = false;
        }

        return isPreApprovalEnabledOnDevice;
    }
}
