'use strict';

require('./invite.style.scss');

const inviteView = require('./invite.view.html');

function config($stateProvider) {
    $stateProvider.state('start.invite', {
        url: '/invite',
        template: inviteView,
        controller: () => ({}),
        controllerAs: 'invite'
    });
}

export default angular.module('states.invite', []).config(config);
