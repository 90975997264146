'use strict';

export class appOpenController {
    constructor($uibModalInstance, $window, appData) {
        'ngInject';

        this.$uibModalInstance = $uibModalInstance;
        this.$window = $window;
        this.appData = appData;
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }

    ok() {
        switch (this.appData.type) {
            case 'sms':
                this.$window.location = this.appData.smsTrigger;
                break;
            case 'whatsapp':
                this.$window.location = this.appData.whatsappTrigger;
                break;
            case 'email':
                this.$window.location = this.appData.emailTrigger;
                break;
        }

        this.$uibModalInstance.close(this.doNotShowAgain);
    }
}
