'use strict';

import tsPopupView from './ts-popup.view.html';
import './ts-popup.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

const DEFAULT_WIDTH = 250;

class tsPopupController {
    constructor($rootScope) {
        'ngInject';

        this.rootScope = $rootScope;
        this.isFireFox = getRootStore().environmentDetect.isFF();

        this.okButtonAria = getRootStore().localizationService.translate('END.VIEW.OK_BUTTON_ARIA');
        this.cancelButtonAria = getRootStore().localizationService.translate('END.VIEW.CANCEL_BUTTON_ARIA');
    }

    get LOCALE_DIR() {
        return this.rootScope.LOCALE_DIR;
    }

    get rtl() {
        return this.LOCALE_DIR === 'rtl';
    }

    _isPercent(value) {
        return typeof value === 'string' && value.endsWith('%');
    }

    _width() {
        return this.popupWidth || DEFAULT_WIDTH;
    }

    popupContainerStyle() {
        if (!this.isModal) {
            return {};
        }

        const width = this._width();

        if (this._isPercent(width)) {
            return {height: '100%'};
        }

        return {
            position: 'absolute',
            top: '40%',
            left: `calc(50% - ${width / 2}px)`,
            'z-index': 1000,
            'max-width': `${width}px`
        };
    }

    popupStyle() {
        const width = this._width();
        const minWidth = this._isPercent(width) ? width : `${width}px`;

        return {
            'min-width': minWidth,
            padding: '10px 10px 10px 10px',
            'justify-content': 'center'
        };
    }

    clickedOk() {
        if (this.onClickedOk) {
            this.onClickedOk();
        }
    }

    clickedCancel() {
        if (this.onClickedCancel) {
            this.onClickedCancel();
        }
    }
}

export function tsPopupDirective() {
    'ngInject';

    return {
        template: tsPopupView,
        restrict: 'E',
        scope: {},
        bindToController: {
            popupWidth: '=',
            isModal: '=',
            imgSrc: '=',
            title: '=',
            subTitle: '=',
            sideLabel: '=',
            textType: '@',
            textSrc: '=',
            keepTextField: '=',
            textPlaceholder: '=',
            textPattern: '=',
            btnOk: '@',
            btnCancel: '@',
            btnClose: '=',
            onClickedOk: '&',
            onClickedCancel: '&'
        },
        controller: tsPopupController,
        controllerAs: 'vm'
    };
}
