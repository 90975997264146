import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import {STATUS_MESSAGES} from '../../../../../../states/meeting/meeting.settings';
import {action, computed, observable} from 'mobx';
import {ITsEventService} from '../../../../../services/ts-event-service/ts-event-service';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';
import {FlowType} from '@techsee/techsee-common/lib/constants/room.constants';
import {RolesConstant} from '@techsee/techsee-common/lib/constants/account.constants';
import {ITranslate} from '../../../../../services/LocalizationService';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

export interface ITermsAndConditionsController {
    init(mode: MeetingState, role: any): Promise<any>;
    ok(): void;
    cancel(): void;
    termsInfo: ITermsInfo | null;
    isVisible: boolean;
    translate: ITranslate;
    termsTranslationStrings: any;
}

export interface ITermsInfo {
    accountTermsUrl: boolean;
    allowTermsMessage: boolean;
    companyName: string;
    customCancelMessage: boolean;
    privacyURL: boolean;
    termsURL: string;
}

export class TermsAndConditionsController implements ITermsAndConditionsController {
    @observable private _isVisible: boolean;
    @observable private _termsInfo: ITermsInfo | null;
    @observable private _termsTranslationStrings: any;

    private _translate: ITranslate;
    private _tsChatApi: any;
    private _db: any;
    private _browserUtilsService: any;
    private _tsEventService: ITsEventService;
    private _useCustomTermsMessage: boolean;
    private _modalFirst: boolean;
    private _promiseInfo: any;

    constructor(translate: ITranslate, tsChatApi: any, db: any, tsEventService: any, browserUtilsService: any) {
        this._translate = translate;
        this._tsChatApi = tsChatApi;
        this._db = db;
        this._browserUtilsService = browserUtilsService;
        this._tsEventService = tsEventService;
        this._isVisible = false;
        this._useCustomTermsMessage = false;
        this._modalFirst = false;
        this._termsInfo = null;
        this._promiseInfo = {};
    }

    get translate() {
        return this._translate;
    }

    @action
    ok = () => {
        const {TERMS_ACCEPTED, TOS_ACCEPTED} = STATUS_MESSAGES;

        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.TOS_ACCEPTED, true);

        this._tsChatApi.sendLog(TOS_ACCEPTED);
        this._db.Rooms.setReportedField(this._tsChatApi.roomId, {data: {event: {key: 'tosAccepted', value: true}}});

        this._isVisible = false;
        this._browserUtilsService.saveToLocalStorage('tos_accepted', this._tsChatApi.roomId);
        this._tsEventService.sendEventLog('none', this._tsChatApi.roomId || 'none', TERMS_ACCEPTED, null);

        return this._promiseInfo.resolve(true);
    };

    @action
    cancel = () => {
        const {TERMS_REJECTED, TOS_REJECTED} = STATUS_MESSAGES;

        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.TOS_REJECTED, true);
        this._tsChatApi.sendLog(TOS_REJECTED);

        if (this._tsChatApi.dashboard.isTosRepromptSent) {
            this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, false);
            this._db.Rooms.setReportedField(this._tsChatApi.roomId, {
                data: {event: {key: 'tosAccepted', value: false}}
            });
        } else {
            this.waitingForTosReprompt();
        }

        this._tsEventService.sendEventLog('none', this._tsChatApi.roomId || 'none', TERMS_REJECTED, null);
        this._isVisible = false;

        return this._promiseInfo.resolve(false);
    };

    @action
    init = (mode: MeetingState, role: any): Promise<any> => {
        /*eslint complexity: ["error", 22]*/
        const tosAcceptedRoom = this._browserUtilsService.getFromLocalStorage('tos_accepted'),
            tosAccepted = tosAcceptedRoom === this._tsChatApi.roomId || this._tsChatApi.client.tosAccepted === true;
        const accountSettings = this._tsChatApi.accountSettings;
        const displayTerms = get(accountSettings, 'displayTerms');

        if (
            tosAccepted ||
            !displayTerms ||
            (role === RolesConstant.TECHNICIAN &&
                (get(this._tsChatApi, 'client.flowType') === FlowType.fs || this._tsChatApi.roomCode)) ||
            this._modalFirst
        ) {
            if (role === RolesConstant.TECHNICIAN) {
                this._tsEventService.sendEventLog(
                    'none',
                    this._tsChatApi.roomId || 'none',
                    STATUS_MESSAGES.FIELD_SERVICES_SKIP_TOS,
                    null
                );
            }

            // If terms is disabled, it's the same result as approving terms
            const isApproved = tosAccepted || displayTerms === false || role === RolesConstant.TECHNICIAN;

            this._isVisible = false;

            return Promise.resolve(isApproved);
        }

        this._modalFirst = true;

        const accountId = accountSettings.accountId;
        const {displayAccountTerms, displayTechseeTerms, displayPrivacyTerms} = accountSettings;
        const termsInfo: ITermsInfo = {
            companyName: accountSettings.companyName,
            termsURL: displayTechseeTerms && accountSettings.termsURL,
            accountTermsUrl: displayAccountTerms && accountSettings.accountTermsUrl,
            privacyURL: displayPrivacyTerms && accountSettings.privacyURL,
            allowTermsMessage: accountSettings.allowTermsMessage,
            customCancelMessage: accountSettings.customCancelMessage
        };

        this.setTermsInfo(termsInfo);

        if (get(this._tsChatApi, 'accountSettings.enableNewInvite')) {
            if (get(this._tsChatApi, 'dashboard.isTosRepromptSent')) {
                this.tosRepromptDone();
            } else if (get(this._tsChatApi, 'client.tosRejected') && get(this._tsChatApi, 'client.isReviewingTOS')) {
                this.waitingForTosReprompt();
            }
        }

        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.IS_REVIEWING_TOS, true);

        // if the account has custom terms string, set a promise to resolve that
        const termsTranslationPromise = this._tsChatApi.accountSettings.useCustomTermsMessage
            ? this._db.Account.termsTranslationStrings(accountId, {
                  params: {
                      language: accountSettings.clientLanguage
                  }
              })
            : Promise.resolve({data: {}});

        termsTranslationPromise.then(({data}: any) => {
            const {WAITING_FOR_TOS_ACCEPTANCE} = STATUS_MESSAGES;

            this._tsEventService.sendEventLog(
                'none',
                this._tsChatApi.roomId || 'none',
                WAITING_FOR_TOS_ACCEPTANCE,
                null
            );
            this._tsChatApi.sendLog(WAITING_FOR_TOS_ACCEPTANCE);
            this.setTermsTranslationStrings(data);

            this._useCustomTermsMessage = !isEmpty(this._termsTranslationStrings);

            if (this._useCustomTermsMessage) {
                forEach(data.termsMessageParts, (part) => {
                    switch (part.type) {
                        case 'TERMS':
                            part.url = termsInfo.termsURL;
                            break;
                        case 'PRIVACY':
                            part.url = termsInfo.privacyURL;
                            break;
                        case 'ACCOUNT':
                            part.url = termsInfo.accountTermsUrl;
                            break;
                    }
                });

                this.setTermsTranslationStrings(data);
            }
        });

        this._isVisible = true;

        return this.showModal();
    };

    @action
    showModal = (): Promise<boolean> =>
        new Promise((resolve, reject) => {
            this._promiseInfo = {
                resolve,
                reject
            };

            this._isVisible = true;
        });

    @action
    setTermsInfo = (termsInfo: ITermsInfo): void => {
        if (!termsInfo) {
            return;
        }

        this._termsInfo = termsInfo;
    };

    @action
    setTermsTranslationStrings = (termsTranslationStrings: any) => {
        if (!termsTranslationStrings) {
            return;
        }

        this._termsTranslationStrings = termsTranslationStrings;
    };

    tosRepromptDone = () => {
        this._db.Rooms.setReportedField(this._tsChatApi.roomId, {
            data: {event: {key: 'tosRepromptDone', value: true}}
        });
    };

    waitingForTosReprompt = () => {
        this._tsChatApi.once(
            socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_IS_TOS_REPROMPT_SENT,
            this.tosRepromptDone.bind(this)
        );
    };

    @computed
    get isVisible() {
        return this._isVisible;
    }

    @computed
    get termsInfo() {
        return this._termsInfo;
    }

    @computed
    get termsTranslationStrings() {
        return this._termsTranslationStrings;
    }
}
