'use strict';

import oneClickPhotoChatView from './one-click-photo-chat.view.html';
import {OneClickPhotoChatController} from './one-click-photo-chat.controller.js';
import messageCarouselView from './../video-chat/dialogs/message-carousel.view.html';
import './one-click-photo-chat.style.scss';

function config($stateProvider) {
    $stateProvider.state('meeting.oneClickPhotoChat', {
        url: 'one-click-photo-chat',
        template: oneClickPhotoChatView,
        controller: 'OneClickPhotoChatController',
        controllerAs: 'vm'
    });
}

export default angular
    .module('states.oneClickPhotoChat', [])
    .config(config)
    .controller('OneClickPhotoChatController', OneClickPhotoChatController)
    .run(($templateCache) => {
        $templateCache.put('custom-templates/message-carousel.view.html', messageCarouselView);
    });
