import React from 'react';

import './WaitingForAgentScreen.scss';

export interface Props {
    text?: string;
}

export const WaitingForAgentScreen: React.FC<Props> = ({text}) => (
    <div className='waiting-for-agent-container'>
        <div className='waiting-for-agent-box'>
            <img src='img/agent.png' className='waiting-for-agent-img' />
            {text && <span className='waiting-for-agent-toast-text'>{text}</span>}
        </div>
    </div>
);
