import {MeetingEvent, ModeHandShakeError, ModeHandShakeFailReasons} from './meeting.contracts';

import {getMeetingTracer} from './meeting.tracer';
import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';

const trace = getMeetingTracer('MeetingStateControllerBase');

export abstract class MeetingStateControllerBase {
    protected readonly $rootScope: any;
    protected readonly $scope: any;
    protected readonly db: any;
    protected readonly tsEventService: any;

    private _isHandshakeSuccess: boolean;
    private readonly _meetingState: MeetingState;

    protected abstract meetingModeHandshake(): Promise<void>;

    protected abstract syncMeetingState(): void;

    protected abstract initModeHandlers(): void;

    protected abstract get handshakeFailureStatusMessage(): string;

    protected constructor($rootScope: any, $scope: any, meetingState: MeetingState, db: any, tsEventService: any) {
        trace.info('Meeting state controller is constructing', meetingState);

        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this._meetingState = meetingState;
        this.db = db;
        this.tsEventService = tsEventService;

        this._isHandshakeSuccess = false;

        this.syncMeetingState.bind(this);
        this.onMeetingStateHandshake = this.onMeetingStateHandshake.bind(this);
        this.onMeetingStateSync = this.onMeetingStateSync.bind(this);

        this.initMeetingPipelineEvents();
    }

    public get isHandshakeSuccess(): boolean {
        return this._isHandshakeSuccess;
    }

    protected get currentMeetingMode(): MeetingState {
        return this._meetingState;
    }

    protected notifyConstructionIsReady() {
        this.$rootScope.$emit(MeetingEvent.StateConstructionComplete, this._meetingState);
    }

    protected setReportedField(roomId: string, options?: any): Promise<any> {
        return this.db.Rooms.setReportedField(roomId, options);
    }

    protected sendEventLog(type: string, options: {roomId?: string; userId?: string; meta?: any}) {
        this.tsEventService.sendEventLog(options.userId, options.roomId, type, options.meta);
    }

    private onMeetingStateHandshake() {
        this.initModeHandlers();
        this.meetingModeHandshake()
            .then(() => {
                this._isHandshakeSuccess = true;
                this.$rootScope.$emit(MeetingEvent.StateHandShakeResult);
            })
            .catch((error: any) => {
                this._isHandshakeSuccess = false;
                const modeHandShakeError: ModeHandShakeError = {
                    isHandshakeSuccess: false,
                    meetingState: this.currentMeetingMode,
                    statusMessage: this.handshakeFailureStatusMessage,
                    error: error,
                    reason:
                        error && error.rejectedTerms
                            ? ModeHandShakeFailReasons.TERMS_REJECTED
                            : ModeHandShakeFailReasons.MEDIA_STREAM_FAILURE
                };

                trace.error('onMeetingStateHandshake error', modeHandShakeError);
                this.$rootScope.$emit(MeetingEvent.StateHandShakeResult, modeHandShakeError);
            });
    }

    private onMeetingStateSync() {
        this.syncMeetingState();
    }

    private initMeetingPipelineEvents() {
        const offHandshakeRequest = this.$rootScope.$on(
            MeetingEvent.MeetingHandshakeRequest,
            this.onMeetingStateHandshake
        );
        const offSyncRequest = this.$rootScope.$on(MeetingEvent.MeetingSyncRequest, this.onMeetingStateSync);

        this.$scope.$on('$destroy', () => {
            offHandshakeRequest();
            offSyncRequest();
        });
    }
}
