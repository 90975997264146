'use strict';

import centerView from './center.view.html';
import {CenterController} from './center.controller.js';
import './center.style.scss';

function config($stateProvider) {
    $stateProvider.state('start.center', {
        url: '/center',
        template: centerView,
        controller: 'CenterController',
        controllerAs: 'center'
    });
}

export default angular.module('states.center', []).config(config).controller('CenterController', CenterController);
