import {ITsEnvironmentDetect, TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import {MeetingController} from './meeting.controller';
import {IVisibilityChange} from '../../services/ts-visibility-change/ts-visibility-change.service';
import {ITsEventService} from '../../services/ts-event-service/ts-event-service';
import {IRoomInfo, IUser} from './interfaces/AngularInterfaces';
import {IWebRtcDetector} from '@techsee/techsee-client-infra/lib/services/DetectWebRtcService';
import {ILocalizationService} from '../../services/LocalizationService';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
// @ts-ignore
import {getSubDomainFromUrl} from '@techsee/techsee-common/lib/utils';
import {getRootStore} from '../../app.bootstrap';
import {IBrandingService} from '../../services/ts-branding-service/ts-branding-service';
import {ITsNetworkInfo} from '../../services/ts-network-info/ts-network-info.service';

export interface IMeetingStores {
    [name: string]: any;
}

export class MeetingStores {
    public stores?: IMeetingStores;

    private _rootScope: any;
    private _stateParams: any;
    private _tsStateHelper: any;
    private _db: any;
    private $localStorage: any;
    private _tsChatApi: any;
    private _environmentService?: ITsEnvironmentDetect;
    private _visibilityChange: IVisibilityChange;
    private _roomInfo: IRoomInfo;
    private _currentUser: IUser;
    private _networkInfoService: ITsNetworkInfo;
    private _tsEventService: ITsEventService;
    private _brandingService: IBrandingService;
    private _tsUrlUtils: any;
    private _tsWebRtcDetector: IWebRtcDetector;
    private _localizationService: ILocalizationService;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = [
        '$scope',
        '$rootScope',
        '$stateParams',
        'tsStateHelper',
        'db',
        '$localStorage',
        'roomInfo',
        'currentUser'
    ];

    constructor(
        $scope: any,
        $rootScope: any,
        $stateParams: any,
        tsStateHelper: any,
        db: any,
        $localStorage: any,
        roomInfo: IRoomInfo,
        currentUser: IUser
    ) {
        this._rootScope = $rootScope;
        this._stateParams = $stateParams;
        this._tsStateHelper = tsStateHelper;
        this._db = db;
        this.$localStorage = $localStorage;
        this._localizationService = getRootStore().localizationService;
        this._tsChatApi = MeetingController;
        this._visibilityChange = getRootStore().visibilityChange;
        this._roomInfo = roomInfo;
        this._currentUser = currentUser;
        this._networkInfoService = getRootStore().networkInfo;
        this._tsEventService = getRootStore().eventService;
        this._brandingService = getRootStore().brandingService;

        this._tsUrlUtils = getRootStore().urlUtils;
        this._tsWebRtcDetector = getRootStore().webRtcDetector;

        this.initStores().then((stores) => {
            $scope.$apply(() => (this.stores = stores));
        });
    }

    private async initStores(): Promise<IMeetingStores> {
        this._environmentService = await TsEnvironmentDetect.create();

        return new Promise(async (resolve) => {
            const stores: IMeetingStores = {};
            const translationHelper = getRootStore().translationHelper;
            const browserUtilsService = getRootStore().browserUtilsService;
            const tsBrowserDetect = getRootStore().browserDetect;
            const endParams = this._stateParams.csi ? {csi: this._stateParams.csi} : {};
            const subdomain = getSubDomainFromUrl(window.location.hostname);

            await this._brandingService.setAccountBranding(subdomain, PlatformType.mobile_web);

            const meetingController = new MeetingController(
                this._localizationService,
                this._visibilityChange,
                this._db,
                this.$localStorage,
                this._roomInfo,
                this._currentUser,
                this._tsEventService,
                browserUtilsService,
                this._networkInfoService,
                translationHelper,
                this._tsStateHelper,
                tsBrowserDetect,
                this._tsWebRtcDetector,
                endParams,
                this._rootScope.THEME
            );

            meetingController.init().then(() => {
                defineStore('translate', this._localizationService.translate);
                defineStore('requireImage', this._rootScope.requireImage);
                defineStore('tsEnvironmentDetect', this._environmentService);
                defineStore('meetingController', meetingController);
                defineStore('endMeetingController', meetingController.endMeetingController);
                defineStore('videoController', meetingController.videoController);

                resolve(stores);

                /*
                 Because of technical issue with Angular digest loop,
                 We define stores as non enumerable properties. This helps to avoid infinite digest of angular.
                */
                function defineStore(storeName: string, value: any) {
                    Object.defineProperty(stores, storeName, {
                        value: value,
                        enumerable: false
                    });
                }
            });
        });
    }
}
