import {inject, IReactComponent} from 'mobx-react';
import * as view from './view';

const mapStoresToProps = (stores: any) =>
    ({
        tsEnvironmentDetect: stores.tsEnvironmentDetect,
        stateParams: stores.stateParams
    }) as view.IEndLayoutProps;

const EndView = inject(mapStoresToProps)(view.EndLayoutComponent) as IReactComponent;

export default EndView;
