import tsFullscreenGalleryCarouselTemplate from './ts-fullscreen-gallery-carousel.view.html';
import {tsFullscreenGalleryDirective} from './ts-fullscreen-gallery.directive';
import {tsFullscreenGalleryMessagesFilter} from './ts-fullscreen-gallery-messages-filter';
import {TsFullscreenGalleryService} from './ts-fullscreen-gallery.service';

angular
    .module('ts-fullscreen-gallery', [])
    .directive('tsFullscreenGallery', tsFullscreenGalleryDirective)
    .filter('fsGalleryMessagesFilter', tsFullscreenGalleryMessagesFilter)
    .service('tsFullscreenGalleryService', TsFullscreenGalleryService)
    .run(($templateCache) => {
        $templateCache.put(
            'custom-templates/ts-fullscreen-gallery-carousel.view.html',
            tsFullscreenGalleryCarouselTemplate
        );
    });
