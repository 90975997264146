import {IContainerPoint} from '@techsee/techsee-media-service-client/lib/components/MagicMarker/MagicMarkerComponent';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {MagicMarkerOptions} from '@techsee/techsee-media-service-client';
import {action, computed, observable} from 'mobx';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

export class MagicMarkerController {
    @observable public remoteDrawings: IContainerPoint[] = [];
    @observable private videoDimensions: {width: number; height: number} = {width: 640, height: 480};
    @observable private enabled = false;
    @observable private isMirrored = false;
    @observable private isFullScreen = false;

    constructor(
        private tsChatApi: any,
        public readonly isMobile: boolean,
        private readonly sendEventLog: (eventType: string, meta?: Record<string, unknown>) => Promise<void>
    ) {
        this.setupEvents();
    }

    private setupEvents = () => {
        this.tsChatApi.on(
            socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_DRAWING,
            (propName: string, value: IContainerPoint[]) => {
                if (value.length) {
                    this.sendEventLog(LOG_EVENTS.magicMarkerDrawingReceived);
                }

                this.updateRemoteDrawings(value);
            }
        );
    };

    @action
    private updateRemoteDrawings = (drawings: IContainerPoint[]) => {
        this.remoteDrawings = drawings;
    };

    @action
    public clearRemoteDrawings = () => {
        this.remoteDrawings = [];
    };

    public sendDrawings = (drawings: IContainerPoint[]) => {
        this.tsChatApi.setStatusFast(socketEvents.CLIENT_OUT_SET_STATUS.DRAWINGS, drawings, true);

        if (drawings.length) {
            this.sendEventLog(LOG_EVENTS.magicMarkerDrawingSent);
        }
    };

    @action
    public setVideoDimensions = (dimensions: {width: number; height: number}) => {
        if (this.videoDimensions.width === dimensions.width && this.videoDimensions.height === dimensions.height) {
            return;
        }

        this.videoDimensions = {...dimensions};
    };

    @action
    public setEnabled = (enabled: boolean) => {
        this.enabled = enabled;
    };

    @action
    public setMirrored = (isMirrored: boolean) => {
        this.isMirrored = isMirrored;
    };

    @action
    public setIsFullScreen = (isFullScreen: boolean) => {
        this.isFullScreen = isFullScreen;
    };

    @computed
    public get shouldDisplayComponent() {
        return (
            this.tsChatApi.accountSettings?.magicMarker?.enabledInFacemeet &&
            this.tsChatApi.accountSettings?.magicMarker?.dashboard.enable &&
            this.tsChatApi.accountSettings?.magicMarker?.client.enable &&
            this.enabled
        );
    }

    @computed
    public get options(): MagicMarkerOptions {
        return {
            drawingOptions: {
                lineWidth: this.tsChatApi.accountSettings?.magicMarker?.client.lineWidth,
                lineWidthRefResolution: this.videoDimensions,
                strokeColorRGB: this.tsChatApi.accountSettings?.magicMarker?.client.strokeColorRgb
            },
            timeUntilDrawingStartFading:
                this.tsChatApi.accountSettings?.magicMarker?.client.timeUntilDrawingStartFading,
            drawingOptionsRemote: {
                lineWidth: this.tsChatApi.accountSettings?.magicMarker?.dashboard.lineWidth,
                lineWidthRefResolution: this.videoDimensions,
                strokeColorRGB: this.tsChatApi.accountSettings?.magicMarker?.dashboard.strokeColorRgb
            },
            timeUntilDrawingStartFadingRemote:
                this.tsChatApi.accountSettings?.magicMarker?.dashboard.timeUntilDrawingStartFading,
            isMobile: this.isMobile,
            canDraw: this.tsChatApi.accountSettings?.magicMarker?.client.enable,
            isMirrored: this.isMirrored,
            isFullScreen: this.isFullScreen
        };
    }
}
