import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';

import './styles.scss';

export interface IEndTopViewProps {
    translate: any;
    requireImage: any;
    theme: string;
    themePrefix: string;
    mobileBranding: Record<string, string>;
}

@observer
export class EndTopView extends TechseeBaseComponent<IEndTopViewProps> {
    renderInternal() {
        const {translate, requireImage, theme, themePrefix, mobileBranding} = this.props;

        this.addCssClass('end-top-component');

        if (theme) {
            this.addCssClass(`theme-${theme}`);
            this.addCssClass(`${themePrefix}branded-end`);

            if (theme === 'in') {
                return (
                    <div className={this.renderCssClasses()}>
                        <img
                            src={mobileBranding.companyLogoImage || requireImage(`${themePrefix}logo-entry.png`)}
                            alt='done'
                        />
                        <h3
                            style={
                                mobileBranding.endScreenHeaderColor ? {color: mobileBranding.endScreenHeaderColor} : {}
                            }>
                            {translate('REACT.END.VIEW.SESSION_ENDED_TITLE_BRANDED_IN')}
                        </h3>
                        <p
                            style={
                                mobileBranding.endScreenSubheaderColor
                                    ? {color: mobileBranding.endScreenSubheaderColor}
                                    : {}
                            }>
                            {translate('REACT.END.VIEW.SESSION_ENDED_SUBTITLE_BRANDED_IN')}
                        </p>
                    </div>
                );
            }

            return (
                <div className={this.renderCssClasses()}>
                    <img src={mobileBranding.companyLogoImage || requireImage(`${theme}/logo.png`)} alt='done' />
                    <h3 style={mobileBranding.endScreenHeaderColor ? {color: mobileBranding.endScreenHeaderColor} : {}}>
                        {translate('REACT.END.VIEW.SESSION_ENDED_TITLE_BRANDED')}
                    </h3>
                    <p
                        style={
                            mobileBranding.endScreenSubheaderColor
                                ? {color: mobileBranding.endScreenSubheaderColor}
                                : {}
                        }
                        dangerouslySetInnerHTML={{
                            __html: translate('REACT.END.VIEW.SESSION_ENDED_SUBTITLE_BRANDED')
                        }}></p>
                </div>
            );
        }

        return (
            <div className={this.renderCssClasses()}>
                <img src={mobileBranding.companyLogoImage || requireImage('end-image.png')} alt='done' />
                <h3 style={mobileBranding.endScreenHeaderColor ? {color: mobileBranding.endScreenHeaderColor} : {}}>
                    {translate('REACT.END.VIEW.SESSION_ENDED_TITLE')}
                </h3>
                <p
                    style={
                        mobileBranding.endScreenSubheaderColor ? {color: mobileBranding.endScreenSubheaderColor} : {}
                    }>
                    {translate('REACT.END.VIEW.SESSION_ENDED_SUBTITLE')}
                </p>
            </div>
        );
    }
}
