'use strict';

import find from 'lodash/find';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
// @ts-ignore
import {getBackendUrl} from '@techsee/techsee-common/lib/utils';

export type Backend = {subDomain: string; port: string};

export type UrlEnvData = {
    API_URL: string;
    SOCKET_URL: string;
    STATS_API_URL?: string;
    MOBILE_CLIENT_URL?: string;
};

const BACKENDS: Backend[] = [
    {subDomain: 'api', port: '443'},
    {subDomain: 'rc-api', port: '443'},
    {subDomain: 'dev', port: '8000'},
    {subDomain: 'test', port: '8000'},
    {subDomain: 'demo-api', port: '443'}
];

const LS_APP_BACKEND_KEY = 'urlConfigService.appBackend';

export class TsUrlConfigService {
    private API_URL: string = '';
    private MOBILE_CLIENT_URL: string = '';
    private SOCKET_URL: string = '';
    private STATS_API_URL: string = '';

    constructor(
        private browserUtilsService: IBrowserUtilsService,
        private envData: UrlEnvData
    ) {}

    async init(): Promise<void> {
        this.API_URL = this._getBackendUrl(this.envData.API_URL);
        this.MOBILE_CLIENT_URL = this.envData.MOBILE_CLIENT_URL && this._getBackendUrl(this.envData.MOBILE_CLIENT_URL);
        this.SOCKET_URL = this._getBackendUrl(this.envData.SOCKET_URL);
        if (this.envData.STATS_API_URL) {
            this.STATS_API_URL = this._getBackendUrl(this.envData.STATS_API_URL, true);
        }

        if (!this.browserUtilsService.getFromLocalStorage(LS_APP_BACKEND_KEY)) {
            this.browserUtilsService.saveToLocalStorage(LS_APP_BACKEND_KEY, BACKENDS[0]);
        }
    }

    get(url: string): string {
        return (this as any)[url];
    }

    setBackend(subDomain: string): void {
        if (!subDomain) {
            this.browserUtilsService.saveToLocalStorage(LS_APP_BACKEND_KEY, BACKENDS[0]);
            return;
        }

        const backend = find(BACKENDS, {subDomain: subDomain}) || {subDomain: subDomain, port: '443'};
        this.browserUtilsService.saveToLocalStorage(LS_APP_BACKEND_KEY, backend);
    }

    getBackend(): Backend {
        let backend = this.browserUtilsService.getFromLocalStorage(LS_APP_BACKEND_KEY);
        backend = backend ? JSON.parse(backend) : BACKENDS[0];
        return backend;
    }

    getSubDomains(): Array<string> {
        return BACKENDS.map((backend) => backend.subDomain);
    }

    private _getBackendUrl(url: string, isStat = false) {
        return getBackendUrl(url, {
            hostname: this.browserUtilsService.getCurrentHostName(),
            isStat: isStat,
            ENV: {...this.envData}
        });
    }
}
