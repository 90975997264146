'use strict';

/**
 * Directive that monitors for clicks on all links inside an element and
 * replaces default handling with manual opening of the link (if it has an href).
 * Needed when ngTouch is in use. See:
 * https://github.com/angular/angular.js/issues/5307
 */
class tsFixTouchLinksController {
    constructor($window) {
        'ngInject';
        this.$window = $window;
    }

    openLink(href, target) {
        if (target === '_blank') {
            this._openLinkInTab(href);
        } else {
            this.$window.location.assign(href);
        }
    }

    _openLinkInTab(href) {
        this.$window.open(href);
    }
}

function linkFn(scope, element, attrs, ctrl) {
    element.on('click touchend', (ev) => {
        if (ev.target && ev.target.tagName === 'A') {
            const anchor = ev.target,
                href = anchor.getAttribute('href'),
                target = anchor.getAttribute('target');

            if (href) {
                ctrl.openLink(href, target);
                ev.preventDefault();
            }
        }
    });
}

export function tsFixTouchLinksDirective() {
    'ngInject';

    return {
        restrict: 'A',
        controller: tsFixTouchLinksController,
        controllerAs: 'vm',
        link: linkFn
    };
}
