'use strict';

import tsZoomableImageView from './ts-zoomable-image.view.html';
import './ts-zoomable-image.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

const ZOOM_MAX_MARGIN = 10;

class tsZoomableImageController {
    constructor($element, $attrs, $scope, $interval) {
        'ngInject';

        this.$scope = $scope;
        this.$interval = $interval;
        this.$element = $element;
        this.chatApi = getRootStore().chatApi;

        this._watchSize();
    }

    _watchSize() {
        const _sizeWatch = this.$interval(() => {
            this._getSize();
        }, 100);

        this.$scope.$on('$destroy', () => {
            this.$interval.cancel(_sizeWatch);
        });
    }

    _getSize() {
        const elem = this.$element[0];

        this.size = `${elem.clientWidth}x${elem.clientHeight}`;
    }

    calculateStyles(container, img) {
        this.findMinimizedImageSize(container, img);
        this.minimizedMarginTop = (container.clientHeight - this.minimizedHeight) / 2;

        this.zoomedHeight = img.naturalHeight;
        this.zoomedWidth = img.naturalWidth;

        // We want zooming to at least double the width of the image
        if (this.zoomedWidth < 2 * container.clientWidth) {
            this.zoomedWidth = 2 * container.clientWidth;
            this.zoomedHeight = (this.minimizedHeight / this.minimizedWidth) * this.zoomedWidth;
        }

        // default zoom margins are for the zoomed image to be centered
        this.defaultZoomedMarginTop = (container.clientHeight - this.zoomedHeight) / 2;
        this.defaultZoomedMarginLeft = (container.clientWidth - this.zoomedWidth) / 2;

        // zoomed margins are used to pan the image
        this.zoomedMarginTop = this.defaultZoomedMarginTop;
        this.zoomedMarginLeft = this.defaultZoomedMarginLeft;

        this.minMarginTop = 0 - ZOOM_MAX_MARGIN + 2 * this.defaultZoomedMarginTop;
        this.minMarginLeft = 0 - ZOOM_MAX_MARGIN + 2 * this.defaultZoomedMarginLeft;

        this.setMinimizedMargins();
        this.setImageSize(this.minimizedWidth, this.minimizedHeight);
    }

    findMinimizedImageSize(container, img) {
        const cRatio = container.clientWidth / container.clientHeight,
            iRatio = img.naturalWidth / img.naturalHeight;

        if (cRatio > iRatio) {
            this.minimizedHeight = container.clientHeight;
            this.minimizedWidth = this.minimizedHeight * iRatio;
        } else {
            this.minimizedWidth = container.clientWidth;
            this.minimizedHeight = this.minimizedWidth / iRatio;
        }
    }

    setMinimizedMargins() {
        this.marginTop = `${this.minimizedMarginTop}px`;
        this.marginLeft = 'auto';
    }

    setZoomedMargins() {
        this.marginTop = `${this.zoomedMarginTop}px`;
        this.marginLeft = `${this.zoomedMarginLeft}px`;
    }

    resetZoomedMargins() {
        this.zoomedMarginTop = this.defaultZoomedMarginTop;
        this.zoomedMarginLeft = this.defaultZoomedMarginLeft;
        this.setZoomedMargins();
    }

    toggleZoom() {
        if (this._isHeld) {
            return;
        }

        this.isZoomed = !this.isZoomed;
    }

    switchZoom() {
        if (this.isZoomed) {
            this.resetZoomedMargins();
            this.setImageSize(this.zoomedWidth, this.zoomedHeight);
        } else {
            this.setMinimizedMargins();
            this.setImageSize(this.minimizedWidth, this.minimizedHeight);
        }
    }

    setImageSize(width, height) {
        this.imageWidth = `${width}px`;
        this.imageHeight = `${height}px`;
    }

    enableImagePan(img) {
        img.on('mousedown touchstart', (e) => {
            if (!this.isZoomed) {
                return;
            }

            const isTouch = e.touches && e.touches[0],
                clientX = isTouch ? e.touches[0].clientX : e.clientX,
                clientY = isTouch ? e.touches[0].clientY : e.clientY;

            this._isHeld = true;
            this._position = {
                x: clientX,
                y: clientY
            };
        });

        img.on('mousemove touchmove', (e) => {
            if (!this.isZoomed) {
                return;
            }
            if (this._isHeld) {
                this._isMoving = true;
                const isTouch = e.touches && e.touches[0],
                    clientX = isTouch ? e.touches[0].clientX : e.clientX,
                    clientY = isTouch ? e.touches[0].clientY : e.clientY,
                    x = clientX - this._position.x,
                    y = clientY - this._position.y;

                this._position = {
                    x: clientX,
                    y: clientY
                };

                this.zoomedMarginTop += y;
                this.zoomedMarginLeft += x;

                // add bounds to block the image from leaving the edge for more than 10 pixels
                if (this.zoomedMarginTop > ZOOM_MAX_MARGIN) {
                    this.zoomedMarginTop = ZOOM_MAX_MARGIN;
                } else if (this.zoomedMarginTop < this.minMarginTop) {
                    this.zoomedMarginTop = this.minMarginTop;
                }
                if (this.zoomedMarginLeft > ZOOM_MAX_MARGIN) {
                    this.zoomedMarginLeft = ZOOM_MAX_MARGIN;
                } else if (this.zoomedMarginLeft < this.minMarginLeft) {
                    this.zoomedMarginLeft = this.minMarginLeft;
                }

                this.$scope.$applyAsync(() => {
                    this.setZoomedMargins();
                });

                e.stopImmediatePropagation();
                e.preventDefault();
            }
        });

        img.on('mouseleave touchcancel', () => {
            if (!this.isZoomed) {
                return;
            }
            this._isHeld = false;
            this._isMoving = false;
        });

        img.on('mouseup touchend', (e) => {
            if (!this.isZoomed) {
                return;
            }
            this._isHeld = false;

            e.stopImmediatePropagation();
        });
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const img = element.find('img');

    ctrl.chatApi.imageLoadStarted(ctrl.isNew, ctrl.type, ctrl.src);

    ctrl.enableImagePan(img);
    img.on('load', () => {
        ctrl.chatApi.imageLoaded(ctrl.isNew, ctrl.type, ctrl.src);

        scope.$applyAsync(() => ctrl.calculateStyles(element[0], img[0]));
    });

    img.on('error', (err) => {
        ctrl.chatApi.imageLoadFailed(ctrl.isNew, ctrl.type, ctrl.src, err);
    });

    scope.$watch(
        () => ctrl.isZoomed,
        () => {
            ctrl.switchZoom();
        }
    );

    scope.$watch(
        () => ctrl.size,
        (size) => {
            if (size === '0x0') {
                return;
            }

            scope.$applyAsync(() => ctrl.calculateStyles(element[0], img[0]));
        }
    );
}

export function tsZoomableImageDirective() {
    'ngInject';

    return {
        template: tsZoomableImageView,
        restrict: 'AE',
        replace: true,
        scope: {},
        bindToController: {
            isNew: '=imageIsNew',
            src: '=imageSrc',
            type: '=imageType',
            altText: '=imageAltText',
            isZoomed: '=imageZoomed'
        },
        controller: tsZoomableImageController,
        controllerAs: 'vm',
        link: linkFn
    };
}
