'use strict';

export class imageLightboxController {
    constructor($uibModalInstance, appData) {
        'ngInject';

        this.$uibModalInstance = $uibModalInstance;
        this.appData = appData;
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }
}
