'use strict';

import coBrowsingView from './co-browsing.view.html';
import {CoBrowsingController} from './co-browsing.controller';
import './co-browsing.style.scss';

function config($stateProvider) {
    $stateProvider.state('meeting.coBrowsing', {
        url: 'co-browsing',
        template: coBrowsingView,
        controller: 'CoBrowsingController',
        controllerAs: 'vm'
    });
}

export default angular
    .module('states.coBrowsing', [])
    .config(config)
    .controller('CoBrowsingController', CoBrowsingController);
