// eslint-disable-next-line no-use-before-define
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {PermissionModal} from '../../../../components/generic/permission-modal';
import {ITermsAndConditionsController} from './Controller';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import './styles.scss';

interface ITermsAndConditions {
    controller: ITermsAndConditionsController;
}

@observer
export class TermsAndConditions extends Component<ITermsAndConditions> {
    agreement = () => {
        const {
            controller: {translate, termsInfo, termsTranslationStrings: termsMessageParts}
        } = this.props;

        if (!isEmpty(termsMessageParts)) {
            return (
                <>
                    {map(termsMessageParts.termsMessageParts, (part, idx) => {
                        if (part.type === 'TEXT') {
                            return <span key={idx}>{part.label}</span>;
                        }

                        if (part.type === 'TERMS' && !part.label && part.url) {
                            return (
                                <a key={idx} href={part.url} target='_blank' rel='noreferrer'>
                                    TechSee {translate('TERMS.VIEW.ALLOW_TERMS')}
                                </a>
                            );
                        }

                        if (part.type !== 'TEXT') {
                            return (
                                <a key={idx} href={part.url} target='_blank' rel='noreferrer'>
                                    {part.label}
                                </a>
                            );
                        }

                        return <></>;
                    })}
                </>
            );
        }

        return (
            <p className={'terms-and-conditions'}>
                {translate('TERMS.VIEW.TERMS_SUBTEXT_1B')}{' '}
                <a href={termsInfo?.termsURL} target='_blank' rel='noreferrer'>
                    {' '}
                    {`TechSee ${translate('TERMS.VIEW.TERMS')}`}
                </a>
            </p>
        );
    };

    render() {
        const {
            controller: {translate, ok, cancel, isVisible, termsInfo, termsTranslationStrings: termsMessageParts}
        } = this.props;

        if (!isVisible) {
            return <React.Fragment></React.Fragment>;
        }

        let onConfirmButtonText, onCancelButtonText;

        if (!isEmpty(termsMessageParts)) {
            onConfirmButtonText =
                (termsInfo?.allowTermsMessage !== undefined &&
                    typeof termsInfo.allowTermsMessage === 'string' &&
                    termsInfo.allowTermsMessage) ||
                translate('TERMS.VIEW.ALLOW');
            onCancelButtonText =
                (termsInfo?.customCancelMessage !== undefined &&
                    typeof termsInfo.customCancelMessage === 'string' &&
                    termsInfo.customCancelMessage) ||
                translate('TERMS.VIEW.CANCEL');
        }

        return (
            <div className={'terms-notice-modal-container'}>
                <PermissionModal
                    img={'img/terms-and-conditions.png'}
                    onConfirm={ok}
                    title={translate('TERMS.VIEW.ALLOW_TERMS')}
                    subTitle={this.agreement}
                    onConfirmButtonText={onConfirmButtonText || translate('TERMS.VIEW.ALLOW')}
                    onCancel={cancel}
                    onCancelButtonText={onCancelButtonText || translate('TERMS.VIEW.CANCEL')}></PermissionModal>
            </div>
        );
    }
}
