'use strict';

import changeUrlView from './change-url.view.html';
import changeUrlController from './change-url.controller';

export class changeUrlModal {
    constructor($uibModal) {
        'ngInject';

        this.$uibModal = $uibModal;
        this.instance = null;
    }

    show(url) {
        if (this.instance) {
            return;
        }

        this.instance = this.$uibModal.open({
            animation: false,
            backdrop: 'static',
            template: changeUrlView,
            controller: changeUrlController,
            controllerAs: 'vm',
            windowClass: 'change-url-modal',
            backdropClass: 'change-url-backdrop',
            resolve: {
                initialUrl: () => url
            }
        });

        return this.instance.result
            .then((result) => result && result.newUrl)
            .catch(() => false)
            .finally(() => {
                this.instance = null;
            });
    }

    close() {
        if (this.instance) {
            this.instance.dismiss();

            this.instance = null;
        }
    }
}
