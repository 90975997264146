'use strict';

// CSS libraries
import 'normalize.css/normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'angular-spinkit/build/angular-spinkit.min.css';

// Load Rubik typeface
require('typeface-rubik');

// JS libraries
import 'angular/angular';
import 'lodash';
import 'zepto/zepto.min';
import 'hammerjs';
import 'expose-loader?exposes=LoginEncoder!@techsee/techsee-common/lib/login-info-encoder';
import 'expose-loader?exposes=TsUtils!@techsee/techsee-common/lib/utils';

// External Angular modules
import uiRouter from 'angular-ui-router';
import ngAnimate from 'angular-animate';
import ngMessages from 'angular-messages';
import ngSanitize from 'angular-sanitize';
import jsDataAngular from 'js-data-angular';
import ngStorage from 'ngstorage';
import ngTranslate from 'angular-translate';
import ngCookies from 'angular-cookies';
import angularStorage from 'angular-storage';
import ngTouch from 'angular-touch';
import ngAria from 'angular-aria';
import 'angular-ui-bootstrap';
import 'angular-hammer/angular.hammer';
import 'angular-spinkit/build/angular-spinkit';
import '@techsee/techsee-common/lib/fe/password-validator/password-validator';
import 'angular-elastic/elastic';
import 'ngreact';

// the url-config service needs to be configured for the environment
import tsUrlConfigServiceInit from '@techsee/techsee-common/lib/fe/ts-url-config/ts-url-config';

tsUrlConfigServiceInit({ENV, API_URL, SOCKET_URL, STATS_API_URL});

export default angular.module('app.vendor', [
    uiRouter,
    ngAnimate,
    ngMessages,
    ngSanitize,
    jsDataAngular,
    ngStorage.name,
    ngTouch,
    ngTranslate,
    ngCookies,
    angularStorage,
    ngAria,
    'hmTouchEvents',
    'angular-spinkit',
    'monospaced.elastic',
    'ui.bootstrap',
    'react'
]);
