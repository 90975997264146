'use strict';

import get from 'lodash/get';

import tsCobrowsingControlView from './ts-cobrowsing-control.view.html';
import './ts-cobrowsing-control.style.scss';
import {MeetingMode} from '@techsee/techsee-common/lib/constants/room.constants';
import {HTTPS} from '@techsee/techsee-common/lib/constants/utils.constant';
import {getRootStore} from '../../_react_/app.bootstrap';

class tsCobrowsingControlController {
    constructor($scope, $rootScope, tsChatHelper, changeUrlModal) {
        'ngInject';

        this.rootScope = $rootScope;

        // Outgoing image url
        this.photoSrc = null;
        this.chatHelper = tsChatHelper;
        this.messageHistory = tsChatHelper.messageHistory;
        this.chatHelper.setMessageHistory(this.messageHistory);
        this.imageFixer = getRootStore().imageFixer;
        this.chatApi = getRootStore().chatApi;
        this.drawMode = false;
        this.tsCobrowsing = getRootStore().cobrowsingService;
        this.changeUrlModal = changeUrlModal;

        this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.INIT_ERROR, (err) => console.error(err));
        this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.ERROR, (err) => console.error(err));
        this.tsCobrowsing.on(this.tsCobrowsing.EVENTS.CONTROL_SWITCH, () => $scope.$applyAsync());
    }

    get requireImage() {
        return this.rootScope.requireImage;
    }

    showMediaButtons() {
        return get(this.chatApi, 'accountSettings.allowUploadImagesFromVideoAndPhoto');
    }

    get currentUrl() {
        return get(this.chatApi, 'dashboard.coBrowsingSettings.url');
    }

    relocateUrl() {
        this._cancelDraw();

        const modal = this.changeUrlModal.show(HTTPS);

        if (!modal) {
            return;
        }

        return modal.then((newUrl) => newUrl && this._relocateUrl(newUrl)).catch(() => false);
    }

    _relocateUrl(url) {
        this._cancelDraw();
        this.onRelocate({url: url});
    }

    draw() {
        this.drawMode = !this.drawMode;

        this.onDrawRequest({enabled: this.drawMode});
    }

    switchControl() {
        this._cancelDraw();
        this.onSwitchControl();
    }

    _cancelDraw() {
        if (this.drawMode) {
            this.onDrawRequest({enabled: false});
            this.drawMode = false;
        }
    }

    loadFinished(objUrl) {
        this.sendPhoto(objUrl);
    }

    sendPhoto(photoSrc, retryIndex) {
        const sendPhotoOptions = {
            photoSrc: photoSrc,
            origin: MeetingMode.coBrowsing,
            withConfirm: true,
            withLoader: true,
            retryIndex: retryIndex
        };

        this.chatHelper.sendMedia(sendPhotoOptions).catch(() => null);
    }

    displayChatGalleryBtn() {
        return (
            this.chatApi.connected && (this.messageHistory.messages.length > 0 || this.chatHelper.chatControlFocused)
        );
    }

    isInControl() {
        return this.tsCobrowsing.isInControl;
    }

    isPaused() {
        return this.tsCobrowsing.isPaused;
    }

    getControlIcon() {
        return this.isInControl() ? 'cb-hand' : 'cb-eye';
    }
}

export function tsCobrowsingControlDirective() {
    'ngInject';

    return {
        template: tsCobrowsingControlView,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            onRelocate: '&',
            onSwitchControl: '&',
            onDrawRequest: '&',
            isInChatPage: '&',
            messageHistory: '=',
            mode: '='
        },
        controller: tsCobrowsingControlController,
        controllerAs: 'vm'
    };
}
