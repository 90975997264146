export const DEVICES_FOR_LOOPBACK_LOW_RESOLUTION = ['OnePlus5T', 'OnePlus4T', 'OnePlus3T', 'vivo'];

export const TORCH_DELAY = 1500;

export enum privateEvents {
    AUDIO_STREAM_FAILED = 'AUDIO_STREAM_FAILED',
    SWITCH_CAMERA_SUCCESS = 'SWITCH_CAMERA_SUCCESS',
    SWITCH_CAMERA_FAILED = 'SWITCH_CAMERA_FAILED',
    DESKTOP_SHARE_TYPE_SET = 'DESKTOP_SHARE_TYPE_SET',
    RESET_PUBLISHER = 'RESET_PUBLISHER',
    SYNC_VOIP = 'SYNC_VOIP',
    START_SWITCH_CAMERA = 'START_SWITCH_CAMERA'
}

export interface VideoSourceEventArgs {
    videoSourceType: boolean;
    audioCreated?: boolean;
}
