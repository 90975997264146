import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {AssetType} from '@techsee/techsee-common/lib/constants/branding.constants.js';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {IAccountBrandingService} from '@techsee/techsee-client-infra/lib/services/AccountBrandingService';

const brandingDataKey = 'brandingData';

export interface BrandingAsset {
    type: AssetType;
    name: string;
    url?: string;
    value?: string;
}

export interface BrandingAssets {
    primaryLogo: string;
    secondaryLogo: string;
    endScreenSubheaderColor: string;
    endScreenHeaderColor: string;
    logoWatermarkImage: string;
    companyLogoImage: string;
    loader: string;
}

export interface IBrandingService {
    setAccountBranding(subDomain: string, platformType: PlatformType): Promise<void>;
    getBrandingData(): BrandingAssets | {};
    clearBrandingData(): void;
}

export class BrandingService implements IBrandingService {
    private brandingData: BrandingAssets | {} = {};
    private accountBrandingDb: IAccountBrandingService;
    private browserUtilsService?: IBrowserUtilsService;

    constructor(accountBrandingDb: IAccountBrandingService, browserUtilsService: IBrowserUtilsService) {
        this.accountBrandingDb = accountBrandingDb;
        this.browserUtilsService = browserUtilsService;
        this.brandingData = this.browserUtilsService.getFromSessionStorage(brandingDataKey) || {};
    }

    public async setAccountBranding(subDomain: string, platformType: PlatformType): Promise<void> {
        try {
            const fetchedAssets = await this.fetchAccountBranding(subDomain, platformType);
            this.setBrandingData(fetchedAssets);
        } catch (error) {
            this.clearBrandingData();
        }
    }

    private async fetchAccountBranding(subDomain: string, platformType: PlatformType): Promise<BrandingAsset[]> {
        const response = await this.accountBrandingDb.getAccountBrandingBySubDomain(subDomain, platformType);

        return response.map((entry: any) => ({
            type: entry.type as AssetType,
            name: entry.name,
            url: entry.type === AssetType.URL ? entry.url : undefined,
            value: entry.type === AssetType.THEME ? entry.value : undefined
        }));
    }

    private setBrandingData(assets: BrandingAsset[]): void {
        const brandingData = {};
        for (const entry of assets) {
            // @ts-ignore
            brandingData[entry.name] = entry.type === AssetType.URL ? entry.url : entry.value;
        }
        this.brandingData = brandingData;
        this.browserUtilsService?.saveToSessionStorage(brandingDataKey, this.brandingData);
    }

    public clearBrandingData(): void {
        this.brandingData = {};
        this.browserUtilsService?.removeFromSessionStorage(brandingDataKey);
    }

    public getBrandingData(): BrandingAssets | {} {
        return this.brandingData;
    }
}
