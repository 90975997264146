import React, {useMemo} from 'react';
import {getRootStore} from '../app.bootstrap';

interface TranslateProps {
    text: string;
    values?: Record<string, any>;
}

const Translate: React.FC<TranslateProps> = ({text, values}) => {
    const translate = useMemo(() => {
        const localizationService = getRootStore().localizationService;

        return localizationService.translate(text, values);
    }, [text, values]);

    // Function to detect if the string contains HTML-like tags
    const containsHtml = (str: string) => /<\/?[a-z][\s\S]*>/i.test(str);

    if (containsHtml(translate)) {
        return <span dangerouslySetInnerHTML={{__html: translate}} />;
    }

    return <>{translate}</>;
};

export default Translate;
