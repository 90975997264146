import React from 'react';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ButtonsToolbar} from '@techsee/techsee-ui-common/lib/forms/buttons/toolbar';
import {PrimaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/primary';
import {IDesignable, IShowable} from '@techsee/techsee-ui-common/lib/_shared/reusable-props';

import './style.scss';

export interface IConfirmationModal extends IShowable, IDesignable {
    isCloseVisible?: boolean;
    onNegativeClick(): void;
    onPositiveClick(): void;
    contentText: string;
    positiveText: string;
    negativeText: string;
}

class ConfirmationModal extends TechseeBaseComponent<IConfirmationModal> {
    renderInternal() {
        const {show, isCloseVisible, onNegativeClick, onPositiveClick, contentText, positiveText, negativeText} =
            this.props;

        this.addCssClass(this.props.className);
        this.addCssClass('confirmation-modal');

        const content = () => <h1 className={'confirmation-modal-title'}>{contentText}</h1>;

        const toolbar = () => (
            <ButtonsToolbar className={'confirmation-modal-toolbar'}>
                <PrimaryButton className={'confirmation-modal-toolbar-positive'} onClick={onPositiveClick}>
                    {positiveText}
                </PrimaryButton>
                <button className={'confirmation-modal-toolbar-negative'} onClick={onNegativeClick}>
                    {negativeText}
                </button>
            </ButtonsToolbar>
        );

        return (
            <GenericModal
                className={this.renderCssClasses()}
                header={() => <React.Fragment />}
                content={content}
                toolbar={toolbar}
                isCloseVisible={isCloseVisible}
                onHideHandler={onNegativeClick}
                show={show}></GenericModal>
        );
    }
}

export default ConfirmationModal;
