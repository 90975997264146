import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';
import Survey from '../survey';
import {WebRtcNotSupported} from '../WebRtcNotSupported';

import './styles.scss';

export interface IEndLayoutProps {
    tsEnvironmentDetect: ITsEnvironmentDetect;
    stateParams: any;
}

@observer
export class EndLayoutComponent extends TechseeBaseComponent<IEndLayoutProps> {
    renderInternal() {
        const {tsEnvironmentDetect, stateParams} = this.props;

        this.addCssClass('end-root-layout use-common-styles');

        if (tsEnvironmentDetect.isChrome() && tsEnvironmentDetect.isAndroid()) {
            this.addCssClass('chrome-android');
        }

        return (
            <div className={this.renderCssClasses()}>
                {stateParams.webRtcSupported !== 'false' ? <Survey /> : <WebRtcNotSupported />}
            </div>
        );
    }
}

export default EndLayoutComponent;
