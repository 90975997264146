import React from 'react';
import {observer} from 'mobx-react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IErrorMessageCloseSessionController} from './controller';
import UserMessageModal from '@techsee/techsee-ui-common/lib/modals/user-message-modal';
import {BorderRoundedIcon, IconColors} from '@techsee/techsee-ui-common/lib/icons/large-rounded-icon';
import {DefaultModalHeader} from '@techsee/techsee-ui-common/lib/modals/generic/default-modal-header';

import './styles.scss';

export interface IErrorMessageCloseSession {
    controller: IErrorMessageCloseSessionController;
}

@observer
export class ErrorMessageCloseSession extends TechseeBaseComponent<IErrorMessageCloseSession> {
    renderInternal() {
        const {controller} = this.props;
        const Header = () => (
            <DefaultModalHeader className={'modal-confirmation-header'}>End Session</DefaultModalHeader>
        );

        const Content = () => (
            <div className={'message-container use-common-style'}>
                <Header />
                <BorderRoundedIcon className={'alert-rounded-icon'} iconName={'alert'} colorName={IconColors.Error} />

                <div className={'message'} dangerouslySetInnerHTML={{__html: controller.errorMessage}}></div>
            </div>
        );

        return (
            <UserMessageModal
                className={'use-common-styles error-message-close-session'}
                content={Content}
                onOkButtonClick={controller.onClickFinish}
                show={controller.isVisible}
                okButtonText={controller.okButton}></UserMessageModal>
        );
    }
}
