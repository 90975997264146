'use strict';

import assign from 'lodash/assign';

import {TEST_UPLOAD_STATUS, TEST_UPLOAD_RETRY_DELAY} from './ts-test-upload.settings';
import {getRootStore} from '../../app.bootstrap';
import {STATUS_MESSAGES} from '../../../states/meeting/meeting.settings';

export class TsTestUpload {
    constructor(restApiService) {
        this.restApiService = restApiService;
        this.eventService = getRootStore().eventService;
        this.deviceDetails = {
            deviceInfo: getRootStore().environmentDetect.getDeviceDetails(),
            networkInfo: getRootStore().networkInfo.toJSON()
        };
    }

    _setTestUploadStatus(roomId, status) {
        getRootStore().browserUtilsService.saveToLocalStorage('testUploadDict', {[roomId]: status});
    }

    _getTestUploadStatus(roomId) {
        const testUploadDict = getRootStore().browserUtilsService.getFromLocalStorage('testUploadDict');

        return testUploadDict && testUploadDict[roomId];
    }

    _createUploadImage(useLargeFileSize = false) {
        this.testFileSize = (useLargeFileSize ? TEST_UPLOAD_HD_FILE_SIZE_IN_KB : TEST_UPLOAD_FILE_SIZE_IN_KB) * 1024;
        const uploadData = new ArrayBuffer(this.testFileSize);

        for (let i = 0; i < uploadData.length; i++) {
            uploadData[i] = Math.floor(Math.random() * 256);
        }

        return uploadData;
    }

    _calculateSpeed(startTime) {
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000;
        const bits = this.testFileSize * 8;
        const speedBps = (bits / duration).toFixed(2);

        return {speedBps, duration};
    }

    exec(roomId, useLargeFileSize = false) {
        const currentStatus = this._getTestUploadStatus(roomId);
        const uploadStartTime = new Date().getTime();

        if (!this._canRunUploadTest(roomId, currentStatus)) {
            return;
        }

        this._setTestUploadStatus(
            roomId,
            currentStatus === TEST_UPLOAD_STATUS.WAITING_TO_RETRY
                ? TEST_UPLOAD_STATUS.RETRY_STARTED
                : TEST_UPLOAD_STATUS.STARTED
        );
        this._sendEventLog(roomId, STATUS_MESSAGES.CLIENT_UPLOAD_TEST_START);
        this.restApiService
            .put(
                `${TEST_UPLOAD_URL}${uploadStartTime}.jpg`,
                new Blob([this._createUploadImage(useLargeFileSize)], {type: 'image/jpg'}),
                {headers: {'Content-type': 'image/jpg', 'x-amz-acl': 'bucket-owner-full-control'}}
            )
            .then((uploadResp) => {
                if (uploadResp.status !== 200) {
                    this._retryExec(roomId, useLargeFileSize);
                    this._sendEventLog(roomId, STATUS_MESSAGES.CLIENT_UPLOAD_TEST_ERROR, {status: uploadResp.status});

                    return;
                }
                const {speedBps, duration} = this._calculateSpeed(uploadStartTime);

                this._setTestUploadStatus(roomId, TEST_UPLOAD_STATUS.FINISHED);
                this._sendEventLog(roomId, STATUS_MESSAGES.CLIENT_UPLOAD_TEST_END, {
                    uploadSpeedKbps: (speedBps / 1024).toFixed(2),
                    uploadTime: duration
                });
            })
            .catch((err) => {
                this._retryExec(roomId, useLargeFileSize);
                this._sendEventLog(roomId, STATUS_MESSAGES.CLIENT_UPLOAD_TEST_EX, {exception: err});
            });
    }

    _retryExec(roomId, useLargeFileSize) {
        this._setTestUploadStatus(
            roomId,
            this._getTestUploadStatus(roomId) === TEST_UPLOAD_STATUS.STARTED
                ? TEST_UPLOAD_STATUS.WAITING_TO_RETRY
                : TEST_UPLOAD_STATUS.ERROR
        );
        setTimeout(() => this.exec(roomId, useLargeFileSize), TEST_UPLOAD_RETRY_DELAY);
    }

    _canRunUploadTest(roomId, currentStatus) {
        return (!currentStatus || currentStatus === TEST_UPLOAD_STATUS.WAITING_TO_RETRY) && TEST_UPLOAD_URL;
    }

    _sendEventLog(roomId, type, meta = {}) {
        this.eventService.sendEventLog(
            'none',
            roomId || 'none',
            type,
            assign(meta, {deviceDetails: this.deviceDetails, status: this._getTestUploadStatus(roomId)})
        );
    }
}
