import {LIVE_POINTER_LINKS} from '../../../states/meeting/desktop-sharing/desktop-sharing.controller';

export class RemoteController {
    private _tsChatApi: any;
    private _setReportedField: any;
    private _tsEnvironmentDetect: any;
    private _guid: string = '';
    private isUnderControl = false;

    constructor(tsChatApi: any, tsEnvironmentDetect: any, setReportedField: any, guid: string) {
        this._tsChatApi = tsChatApi;
        this._tsEnvironmentDetect = tsEnvironmentDetect;
        this._setReportedField = setReportedField;
        this.downloadLivePointer = this.downloadLivePointer.bind(this);
        this._guid = guid;
    }

    downloadLivePointer(): void {
        this._setReportedField(this._tsChatApi.roomId, {
            data: {
                event: {
                    key: 'livePointerDownloadsCount',
                    value: 1,
                    type: 'inc'
                }
            }
        });

        this._setReportedField(this._tsChatApi.roomId, {
            data: {
                event: {
                    key: 'desktopSharingStatus',
                    value: 'downloadLivePointerClicked',
                    type: 'push'
                }
            }
        });

        let downloadLink = '';

        if (this._tsEnvironmentDetect.isLinux()) {
            downloadLink = LIVE_POINTER_LINKS.LINUX;
        }

        if (this._tsEnvironmentDetect.isMac()) {
            downloadLink = LIVE_POINTER_LINKS.MAC;
        }

        if (this._tsEnvironmentDetect.isWindows()) {
            downloadLink = LIVE_POINTER_LINKS.WINDOWS;
        }

        if (downloadLink) {
            const link = document.createElement('a');

            link.download = 'live-pointer';
            link.href = downloadLink;
            link.click();
        }
    }

    public get guid(): string {
        return this._guid;
    }
}
