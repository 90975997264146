'use strict';

import {getRootStore} from '../../_react_/app.bootstrap';

export class ChangePasswordController {
    constructor(tsStateHelper, db, currentUser, accountData) {
        'ngInject';

        this.stateHelper = tsStateHelper;
        this.db = db;
        this.waiting = false;
        this.userId = currentUser._id;
        this.passwordSettings = accountData.settings.passwordSettings;
        this.allowCustom = accountData.protectedSettings.allowCustomPasswordPolicy;
        this.enterOldPassword = getRootStore().localizationService.translate('CHANGE_PASSWORD.VIEW.ENTER_OLD_PASSWORD');
        this.enterNewPassword = getRootStore().localizationService.translate('CHANGE_PASSWORD.VIEW.ENTER_NEW_PASSWORD');
        this.confirmPassword = getRootStore().localizationService.translate('CHANGE_PASSWORD.VIEW.CONFIRM_PASSWORD');
        this.passwordSubmitButton = getRootStore().localizationService.translate(
            'CHANGE_PASSWORD.VIEW.PASSWORD_SUBMIT_BUTTON'
        );
    }

    submit(form) {
        this.waiting = true;

        if (!form.oldPassword) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate(
                'CHANGE_PASSWORD.CONTROLLER.ENTER_OLD_PASSWORD'
            );

            return;
        }

        if (!form.newPassword) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate(
                'CHANGE_PASSWORD.CONTROLLER.ENTER_NEW_PASSWORD'
            );

            return;
        }

        if (!form.confirmPassword) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.CONFIRM_PASSWORD');

            return;
        }

        if (form.newPassword !== form.confirmPassword) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.PASSWORD_MISMATCH');

            return;
        }

        if (form.$error.passwordLengthValidator) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.MINIMUM_LENGTH');

            return;
        }

        if (form.$error.passwordUppercaseValidator) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.CONTAIN_UPPERCASE');

            return;
        }

        if (form.$error.passwordLowercaseValidator) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.CONTAIN_LOWERCASE');

            return;
        }

        if (form.$error.passwordDigitValidator) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.CONTAIN_DIGIT');

            return;
        }

        if (form.$error.passwordSpecialValidator) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.CONTAIN_SPECIAL');

            return;
        }

        if (form.$error.passwordDefaultValidator) {
            this.waiting = false;

            this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.DEFAULT_PATTERN');

            return;
        }

        this.db.User.update(this.userId, {
            oldPassword: form.oldPassword,
            newPassword: form.newPassword
        })
            .then(() => {
                this.stateHelper.safeGo('start.main', {}, {reload: true});
            })
            .catch(() => {
                this.waiting = false;
                this.message = getRootStore().localizationService.translate('CHANGE_PASSWORD.CONTROLLER.ERROR');
            });
    }
}
