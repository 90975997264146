'use strict';
import tsChatControlView from './ts-chat-control.view.html';
import './ts-chat-control.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

class tsChatControlController {
    constructor($rootScope) {
        'ngInject';

        this.tsBrowserUtilsService = getRootStore().browserUtilsService;
        this.chatApi = getRootStore().chatApi;
        this.rootScope = $rootScope;
        this.enterTextMessage = getRootStore().localizationService.translate('CHAT_CONTROL.VIEW.ENTER_TEXT_MESSAGE');
    }

    get requireImage() {
        return this.rootScope.requireImage;
    }

    sendTextMessage(form) {
        if (!form.textMessage) {
            return;
        }

        this.chatApi.sendText(form.textMessage);
        this.tsBrowserUtilsService.saveToLocalStorage('tos_accepted', this.chatApi.roomId);

        form.textMessage = '';
    }

    chatInputFocusChange(isFocused) {
        if (this.onChatFocused) {
            this.onChatFocused({isFocused});
        }
    }

    isButtonActive() {
        return this.sendTextForm.textMessage && (this.chatApi.areBothSidesConnected || this.chatApi.offlineRoom);
    }

    onEnterPress(event) {
        if (!this.isDesktop) {
            return;
        }

        if (!(event.shiftKey || event.ctrlKey) && event.keyCode === 13) {
            this.sendTextMessage(this.sendTextForm);
        } else if ((event.shiftKey || event.ctrlKey) && event.keyCode === 13) {
            this.sendTextForm.form.textMessage += '\n';
        }
    }
}

export function tsChatControlDirective() {
    'ngInject';

    return {
        template: tsChatControlView,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            onChatFocused: '&',
            isDesktop: '='
        },
        controller: tsChatControlController,
        controllerAs: 'vm'
    };
}
