'use strict';

import uploadSessionAssetsView from './upload-session-assets.view.html';
import uploadSessionAssetsController from './upload-session-assets.controller';

export class uploadSessionAssetsModal {
    constructor($uibModal) {
        'ngInject';

        this.$uibModal = $uibModal;
        this.instance = null;
    }

    show() {
        if (this.instance) {
            return;
        }

        this.instance = this.$uibModal.open({
            // fixes backdrop not disappearing https://github.com/angular-ui/bootstrap/issues/3633
            animation: false,
            backdrop: 'static',
            template: uploadSessionAssetsView,
            controller: uploadSessionAssetsController,
            controllerAs: 'vm',
            windowClass: 'upload-session-assets-modal',
            backdropClass: 'upload-session-assets-backdrop'
        });

        return this.instance.result
            .then(() => true)
            .catch(() => false)
            .finally(() => {
                this.instance = null;
            });
    }

    close() {
        if (this.instance) {
            this.instance.dismiss();

            this.instance = null;
        }
    }
}
