'use strict';

// deletes modal backdrop https://github.com/angular-ui/bootstrap/issues/3633

class hideModalBackdropController {
    constructor($document) {
        'ngInject';
        this.$document = $document;
    }
}

function linkFn(scope, element, attrs, ctrl) {
    element.bind('click', () => {
        angular.element(ctrl.$document[0].getElementsByClassName('end-meeting-backdrop')).remove();
    });
}

export function hideModalBackdrop() {
    'ngInject';

    return {
        restrict: 'A',
        controller: hideModalBackdropController,
        link: linkFn
    };
}
