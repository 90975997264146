'use strict';

import meetingModule from './meeting/meeting.module.js';
import newMeetingModule from '../_react_/states/meeting/meeting.module';
import endModuleNew from '../_react_/states/end/end.module.tsx';
import unsupportedModule from './unsupported/unsupported.module.js';
import startModule from './start/start.module.js';
import fsModule from './fs/fs.module.js';
import changePassword from './change-password/change-password.module.js';
import magicLink from './magic-link/magic-link.module.js';

export default angular.module('app.states', [
    meetingModule.name,
    newMeetingModule.name,
    unsupportedModule.name,
    startModule.name,
    fsModule.name,
    changePassword.name,
    magicLink.name,
    endModuleNew.name
]);
