'use strict';

/**
 * Shim for when the 'autofocus' html attribute doesn' work as expected
 *
 * Attributes:
 *
 *  focus-delay: {Number} msecs to delay focus action
 */
class tsAutoFocusController {
    constructor($element, $attrs, $scope, $timeout) {
        'ngInject';
        // the delay is used only once, so we just read
        // it and don't need to bind it to the controller
        if ($attrs.focusDelay) {
            this.delay = Number($attrs.focusDelay);
        }
        this.$timeout = $timeout;
    }

    asyncRun(callback) {
        // push the callback to the next digest cycle
        this.$timeout(() => {
            callback();
        }, this.delay || 0);
    }
}

function linkFn(scope, element, attrs, ctrl) {
    ctrl.asyncRun(() => {
        $(element).focus();
    });
}

export function tsAutoFocusDirective() {
    'ngInject';

    return {
        restrict: 'A',
        scope: false,
        controller: tsAutoFocusController,
        link: linkFn
    };
}
