import {AppRootStore, IAppRootStore} from './app.root.store';
import {getBrowserInfo} from '../../../shared/browser-info';

let rootStore: IAppRootStore;

export async function reactAppBootstrap(): Promise<void> {
    await initRootStore();
}

export async function initRootStore() {
    rootStore = new AppRootStore();
    const browserInfo = await getBrowserInfo();

    await rootStore.initRootServices({
        env: {SOCKET_URL, API_URL, STATS_API_URL},
        browserInfo
    });

    return rootStore;
}

export function getRootStore(): IAppRootStore {
    if (!rootStore) {
        throw 'root store was not initialized, insure you have bootstrapped the application';
    }

    return rootStore;
}
