'use strict';

import './message.style.scss';
import forEach from 'lodash/forEach';
import {getRootStore} from '../../../../_react_/app.bootstrap';

export class MessageController {
    constructor($scope, $uibModalInstance, messages, photoIndex) {
        'ngInject';

        this.chatApi = getRootStore().chatApi;
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.isZoomed = false;
        this.tsAppstoreUrlUtils = getRootStore().appstoreUrlUtils;
        this.BASE_PATH = BASE_PATH;
        this.activeSlideIndex = 0;

        this._initTranslations();

        this.messages = this.parseMessages(messages);
        this.activeSlideIndex = photoIndex;
    }

    onSlideChange(nextSlide, direction, nextIndex) {
        this.activeSlideIndex = nextIndex;
    }

    _initTranslations() {
        this.operatorImageText = getRootStore().localizationService.translate('MESSAGE.VIEW.OPERATOR_IMAGE');
        this.customerImageText = getRootStore().localizationService.translate('MESSAGE.VIEW.CUSTOMER_IMAGE');
        this.operatorVideoText = getRootStore().localizationService.translate('MESSAGE.VIEW.OPERATOR_VIDEO');
        this.customerVideoText = getRootStore().localizationService.translate('MESSAGE.VIEW.CUSTOMER_VIDEO');
    }

    parseMessages(messages) {
        const parsedMessages = [];

        forEach(messages, (msg) => {
            const message = {
                isNew: msg.isNew,
                data: msg.data,
                video: msg.video,
                isVideo: msg.isVideo,
                type: msg.type,
                appStoreLink: msg.appStoreLink
            };

            switch (message.type) {
                case 'IMG-DB':
                    message.alt = this.operatorImageText;
                    break;
                case 'IMG-CL':
                    message.alt = this.customerImageText;
                    break;
                case 'VID-DB':
                    message.alt = this.operatorVideoText;
                    break;
                case 'VID-CL':
                    message.alt = this.customerVideoText;
                    break;
            }

            parsedMessages.push(message);
        });

        return parsedMessages;
    }

    isCurrentType(type) {
        return (
            this.messages &&
            this.messages.length > this.activeSlideIndex &&
            this.messages[this.activeSlideIndex].type === type
        );
    }

    toggleZoom() {
        this.isZoomed = !this.isZoomed;
    }

    pinchZoomIn() {
        this.isZoomed = false;
    }

    pinchZoomOut() {
        if (this.isZoomed) {
            return;
        }

        this.isZoomed = true;
    }

    ok() {
        // Modal returns no value, so it is always closed by dismissing
        this.$uibModalInstance.dismiss();
    }

    messageClicked(message) {
        if (!message.appStoreLink) {
            return;
        }

        this.tsAppstoreUrlUtils.prepareAppstoreRedirect();
    }
}
