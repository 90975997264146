import buttonTemplate from './ts-rounded-button.view.html';

import './ts-rounded-button.style.scss';

class tsRoundedButtonController {
    isIconVisible() {
        return getStringValue(this.icon, '').length > 0;
    }

    buttonColor() {
        return getStringValue(this.color, 'blue');
    }

    buttonSize() {
        return getStringValue(this.size, 'normal');
    }
}

function getStringValue(value, defaultValue = '') {
    return typeof value === 'string' && value.length > 0 ? value : defaultValue;
}

export function tsRoundedButtonDirective() {
    return {
        template: buttonTemplate,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            icon: '@?',
            color: '@?',
            size: '@?',
            isWideIcon: '=?'
        },
        controller: tsRoundedButtonController,
        controllerAs: 'vm'
    };
}
