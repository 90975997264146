import {ChangeEvent, KeyboardEvent, ReactText} from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import {TechseeBaseComponent} from '../../_shared/techsee-base-component';
import {IDisableable, IDesignable, IUnique} from '../../_shared/reusable-props';
import {IFieldModel} from './simple-field-model';
import {ValidationRules} from './ValidationRules';

export interface IFormFieldProps extends IUnique, IDesignable, IDisableable {
    model: IFieldModel;
    validationOnChange?: boolean;
    onChange?(e: ChangeEvent<FormControl>): void;
    onEnterKey?(e: KeyboardEvent<FormControl>): void;
}

export abstract class TechseeBaseControlComponent<
    PropTypes extends IFormFieldProps,
    InitialState
> extends TechseeBaseComponent<PropTypes, InitialState> {
    constructor(props: IFormFieldProps) {
        super(props as any);

        this.handleBlurEvent = this.handleBlurEvent.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    protected extractValue(target: FormControl): ReactText {
        if (typeof (target as any).value !== 'undefined') {
            return (target as any).value;
        }

        return '';
    }

    protected handleBlurEvent(): void {
        this.props.model.setWasBlured(true);
    }

    protected handleChangeEvent(event: ChangeEvent<FormControl>): void {
        this.setFieldValue(this.extractValue(event.target));

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    protected handleKeyDown(event: KeyboardEvent<FormControl>): void {
        if (event.key === 'Enter' && this.props.onEnterKey) {
            this.props.onEnterKey(event);
        }
    }

    protected setFieldValue(value: ReactText): void {
        const {model} = this.props;

        model.setValue(value);
    }

    protected isRequired(): boolean {
        const requiredRule = ValidationRules.required();

        return this.props.model.rules.filter((rule) => rule.rule == requiredRule).length > 0;
    }

    protected hasErrors(): boolean {
        const {model} = this.props;

        return model.errors && model.errors.length > 0;
    }

    protected shouldDisplayError(): boolean {
        let wasTouched = false;

        if (this.props.validationOnChange === true && this.props.model.wasChanged) {
            wasTouched = true;
        }

        if (this.props.validationOnChange !== true && this.props.model.wasBlured) {
            wasTouched = true;
        }

        return this.hasErrors() && wasTouched;
    }

    render(): JSX.Element {
        this.resetCssClasses();

        this.addCssClass(this.props.className);
        this.addCssClassIf('required', this.isRequired());

        return this.renderInternal();
    }
}
