'use strict';

import tsIframeOverlayView from './ts-iframe-overlay.view.html';
import './ts-iframe-overlay.style.scss';

class tsIframeOverlayController {
    constructor($element, $attrs, $scope, $sce) {
        'ngInject';

        this.$scope = $scope;
        this.$sce = $sce;
        this.visible = false;
        this.staticSrc = $attrs.iframeStaticSrc;
    }

    toggle(visible) {
        this.visible = visible;

        if (this.toggleCallback) {
            this.toggleCallback({visible});
        }
    }

    getTrustedUrl() {
        const src = this.src || this.staticSrc;

        return this.$sce.trustAsResourceUrl(src);
    }
}

function linkFn(scope, element) {
    const iframe = element[0].querySelector('.ts-iframe-overlay-container'),
        body = $('body');

    // The frame needs to be a parent of the body element, otherwise
    // if the parent is absolute, it wouldn't overlay the whole screen
    $(body).append(iframe);
}

export function tsIframeOverlayDirective() {
    'ngInject';

    return {
        template: tsIframeOverlayView,
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            src: '=iframeSrc',
            toggleCallback: '&iframeToggle'
        },
        controller: tsIframeOverlayController,
        controllerAs: 'vm',
        link: linkFn
    };
}
