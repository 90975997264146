'use strict';

import {DbService} from './db/db.service';
import {TsStateHelperService} from './ts-state-helper/ts-state-helper.service';
import {TokenInterceptor} from './token-interceptor/token-interceptor.service';
import {TsChatHelper} from './ts-chat-helper/ts-chat-helper.service';
import {TsEndMeetingModalService} from './ts-end-meeting/ts-end-meeting-modal.service';

export default angular
    .module('app.services', ['tsUrlConfig.service'])
    .service('db', DbService)
    .service('tsStateHelper', TsStateHelperService)
    .service('tokenInterceptor', TokenInterceptor)
    .service('tsChatHelper', TsChatHelper)
    .service('tsEndMeetingModalService', TsEndMeetingModalService);
