'use strict';

import tsChatConnectView from './ts-chat-connect.view.html';
import './ts-chat-connect.style.scss';

class tsChatConnectController {
    centerClick() {
        if (this.onCenterClick) {
            this.onCenterClick();
        }
    }

    expertClick() {
        if (this.onExpertClick) {
            this.onExpertClick();
        }
    }
}

export function tsChatConnectDirective() {
    'ngInject';

    return {
        template: tsChatConnectView,
        restrict: 'E',
        scope: {},
        bindToController: {
            visible: '=',
            onCenterClick: '&',
            onExpertClick: '&'
        },
        controller: tsChatConnectController,
        controllerAs: 'vm'
    };
}
