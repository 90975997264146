import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {CoBrowserControlsEnum} from '@techsee/techsee-common/lib/constants/account.constants';
import {TIMEOUT, COBROWSERS} from './ts-cobrowsing-service.settings';
import {CoBrowsingServiceBase} from './CoBrowsingServiceBase';
import {COBROWSING_EVENTS} from '@techsee/techsee-common/lib/constants/room.constants';
import {getMeetingTracer} from '../../../states/meeting/meeting.tracer';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../app.bootstrap';

const trace = getMeetingTracer('CoBrowsing');

interface SurflyWindow extends Window {
    Surfly: any;
}

export class TsCobrowsingService extends CoBrowsingServiceBase {
    public isPaused: boolean = false;
    private otherSideLoading: boolean = false;
    private showLoader: boolean = false;
    private _currentTimer: any;
    private inProgress: boolean = false;
    private roomId: string | null = null;
    private accountId: string | null = null;
    private agentInControlLastState: boolean = false;
    private url: string | null = null;
    private safeApply: any;

    constructor() {
        super(false, getRootStore().chatApi, getRootStore().eventService);

        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.COBROWING_PAUSE_ACTION, (pause: boolean) => {
            this.isPaused = pause;
            this._updateCobrowsingControls(pause, CoBrowserControlsEnum.pause);
        });

        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.CHANGE_COBROWSING_URL_ACTION, (url: string) => {
            this._updateCobrowsingControls(url, CoBrowserControlsEnum.url);
        });

        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.SHOW_LOADER_ACTION, (value: boolean) => {
            this.otherSideLoading = value;
            if (!value && !this.showLoader) {
                this.safeApply();
            }

            value ? this._loaderTimeout() : clearTimeout(this._currentTimer);
        });

        this.on(COBROWSING_EVENTS.SESSION_STARTED, () => {
            this._showHideLoader(false);

            if (this.agentInControlLastState) {
                setTimeout(() => this.giveControlToFollower(), 0);
            }
        });

        this.on(COBROWSING_EVENTS.VIEWER_JOINED, (session: any) => {
            if (this._isCurrentSession(session)) {
                trace.info('Co Browsing follower joined');
            }
        });

        this.on(COBROWSING_EVENTS.VIEWER_LEFT, (session: any) => {
            if (this._isCurrentSession(session)) {
                trace.info('Co Browsing follower left');
            }
        });

        this.on(COBROWSING_EVENTS.CONTROL_SWITCH, (agentInControl: boolean) => {
            trace.info(`Co Browsing switch control, agentInControl: ${agentInControl}`);
        });

        this.on(COBROWSING_EVENTS.ERROR, (error: any) => {
            trace.info(`Co Browsing error: ${error}`);
        });
    }

    private _isCurrentSession(session: any): boolean {
        return get(session, '_sessionId') === get(this.chatApi, 'dashboard.coBrowsingSettings.sessionId');
    }

    public start(accountId: string, roomId: string, widgetKey: string): void {
        if (this.inProgress) {
            return;
        }
        this.inProgress = true;
        this.roomId = roomId;
        this.accountId = accountId;
        this.isPaused = get(this.chatApi, 'dashboard.coBrowsingSettings.isPaused');

        this.getSurflyObject(window as unknown as SurflyWindow, widgetKey)
            .then(() => this._initSession())
            .catch((err: any) => this.emit(COBROWSING_EVENTS.INIT_ERROR, err))
            .finally(() => (this.inProgress = false));
    }

    private _showHideLoader(value: boolean): void {
        this.showLoader = value;
        this.safeApply();
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.SHOW_LOADER, value);

        value ? this._loaderTimeout() : clearTimeout(this._currentTimer);
    }

    private _loaderTimeout() {
        if (this._currentTimer) {
            clearTimeout(this._currentTimer);
        }

        this._currentTimer = setTimeout(() => {
            if (this.showLoader) {
                const iframeId = '#coBrowsingTechSeeLeaderFrame';

                const iframe = document.querySelector(iframeId);

                if (iframe) {
                    (iframe as HTMLIFrameElement).src += '';
                }

                this._currentTimer = null;
            }
        }, TIMEOUT.LOADER_TIMEOUT);
    }

    private _initSession() {
        const url = get(this.chatApi.dashboard, 'coBrowsingSettings.leaderLink');
        // eslint-disable-next-line no-unused-vars
        const agentInControl = get(this.chatApi, 'dashboard.coBrowsingSettings.agentInControl');

        if (!url || this.url === url) {
            return;
        }

        this.url = url;

        this.agentInControlLastState = get(this.chatApi, 'dashboard.coBrowsingSettings.agentInControl');

        super.initSession(url);
        this._showHideLoader(true);
    }

    private _updateCobrowsingControls(value: any, controlState: CoBrowserControlsEnum) {
        const settings = get(this.chatApi, 'dashboard.coBrowsingSettings');

        if (settings) {
            const updatedSettings = cloneDeep(settings);

            switch (controlState) {
                case CoBrowserControlsEnum.pause:
                    updatedSettings.isPaused = value;
                    break;
                case CoBrowserControlsEnum.control:
                    updatedSettings.agentInControl = value;
                    break;
                case CoBrowserControlsEnum.url:
                    updatedSettings.url = value;
                    break;
                default:
                    break;
            }

            this.chatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.COBROWSING_SETTINGS, updatedSettings);
        }
    }

    private giveControlToFollower() {
        return this.sessionObject && this.sessionObject.giveControl(COBROWSERS.FOLLOWER);
    }

    paused(): void {
        this.isPaused = !this.sessionObject.paused;
        this._updateCobrowsingControls(this.isPaused, CoBrowserControlsEnum.pause);
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.COBROWING_PAUSE, this.isPaused);

        if (this.sessionObject && this.isInControl) {
            this.sessionObject.pause();

            this.tsEventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                LOG_EVENTS.coBrowsingSurflySwitchPaused,
                {
                    isPaused: true
                }
            );
        }
    }

    resume(): void {
        this.isPaused = !this.sessionObject.paused;
        this._updateCobrowsingControls(this.isPaused, CoBrowserControlsEnum.pause);
        this.chatApi.requestAction(socketEvents.CLIENT_OUT_REQUEST_ACTION.COBROWING_PAUSE, this.isPaused);

        if (this.sessionObject && this.isInControl) {
            this.sessionObject.resume();

            this.tsEventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                LOG_EVENTS.coBrowsingSurflySwitchResume,
                {
                    isPaused: false
                }
            );
        }
    }

    get isInSession() {
        return !!this.sessionObject;
    }

    get EVENTS() {
        return COBROWSING_EVENTS;
    }

    setSafeApply(safeApply: any) {
        this.safeApply = safeApply;
    }
}
