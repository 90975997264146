'use strict';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import * as LoginEncoder from '@techsee/techsee-common/lib/login-info-encoder';
import {
    IAuthService as IAuthApiService,
    ConfirmLoginResponse
} from '@techsee/techsee-client-infra/lib/services/AuthService';

interface LoginResponse {
    token: string;
    keepLogged?: boolean;
}

interface isRedeemed {
    isRedeemed: boolean;
}
export interface IAuthService {
    login(userName: string, password: string, keepLogged: boolean): Promise<string>;
    loginWithOneTimeToken(ott: string, keepLogged: boolean): Promise<isRedeemed>;
    loginWithOneTimeTokenAsRegularUser(ott: string, keepLogged: boolean): Promise<isRedeemed>;
    createOneTimeToken(): Promise<string>;
    createMagicLink(email: string, keepLogged: boolean): Promise<null | ConfirmLoginResponse>;
    useMagicLinkToken(ott: string): Promise<string>;
    getAuthToken(): string;
    setAuthToken(token: string, keepLogged: boolean): void;
    logout(accountId: string, userId: string): Promise<void>;
}

export class AuthService {
    private browserUtilsService: IBrowserUtilsService;
    private _authApiService: IAuthApiService;

    constructor(browserUtilsService: IBrowserUtilsService, authApiService: IAuthApiService) {
        this.browserUtilsService = browserUtilsService;

        this._authApiService = authApiService;

        this.loginWithOneTimeToken = this.loginWithOneTimeToken.bind(this);
        this.loginWithOneTimeTokenAsRegularUser = this.loginWithOneTimeTokenAsRegularUser.bind(this);
        this.getAuthToken = this.getAuthToken.bind(this);
        this.setAuthToken = this.setAuthToken.bind(this);
    }

    /**
     * Make a login request
     *
     * @param  {string} userName     Username
     * @param  {string} password     User password
     * @return {Object}              The request Promise
     */
    login(userName: string, password: string, keepLogged: boolean) {
        const loginData = LoginEncoder.encode(userName, password);
        const longTermToken = true;

        return this._authApiService.login(loginData, longTermToken).then((res: LoginResponse) => {
            this.setAuthToken(res.token, keepLogged);

            return res.token;
        });
    }

    loginWithOneTimeToken(ott: string, keepLogged = true) {
        if (!ott) {
            return Promise.resolve({isRedeemed: false});
        }

        return this._authApiService.redeemOneTimeToken(ott).then((token: string) => {
            if (token) {
                // Login successful, save the Token
                this.setAuthToken(token, keepLogged);

                return {isRedeemed: true};
            }

            return {isRedeemed: false};
        });
    }

    loginWithOneTimeTokenAsRegularUser(ott: string, keepLogged = true) {
        if (!ott) {
            return Promise.resolve({isRedeemed: false});
        }

        return this._authApiService.redeemOneTimeToken(ott).then((token: string) => {
            if (token) {
                // Login successful, save the Token
                this.setAuthToken(token, keepLogged);
                const simpletoken = token;

                this.setAuthToken(simpletoken, keepLogged);

                return {isRedeemed: true};
            }

            return {isRedeemed: false};
        });
    }

    createOneTimeToken() {
        return this._authApiService.createOneTimeToken(this.getAuthToken());
    }

    /**
     * Request to send email with link that will log in user
     *
     * @param {String} email user email
     * @param {Boolean} keepLogged keep user logged in
     * @param {Boolean} loginConfirmed login was confirmed
     * @returns {Promise} The request Promise
     */
    createMagicLink(email: string, keepLogged: boolean, loginConfirmed = false) {
        return this._authApiService.magicLink(email, loginConfirmed, keepLogged);
    }

    useMagicLinkToken(ott: string, keepLogged = true) {
        return this._authApiService.redeemOneTimeToken(ott).then((token: string) => {
            this.setAuthToken(token, keepLogged);

            return token;
        });
    }

    getAuthToken() {
        const auth =
            this.browserUtilsService.getFromSessionStorage('auth') ||
            this.browserUtilsService.getFromLocalStorage('auth');

        return auth?.token || null;
    }

    setAuthToken(token: string, keepLogged = true) {
        if (keepLogged) {
            this.browserUtilsService.saveToLocalStorage('auth', {token});
        } else {
            this.browserUtilsService.saveToSessionStorage('auth', {token});
        }
    }

    /**
     * Sign the use out of the system and cleanup the saved Token
     *
     * @param {String} accountId accountId of current user
     * @param {String} userId userId of current user
     * @return {Object} The request Promise
     */
    logout(accountId: string, userId: string) {
        return this._authApiService.logout(accountId, userId).then((res) => {
            // Logout successful, clear the saved Token
            this.browserUtilsService.saveToLocalStorage('auth', null);
            this.browserUtilsService.saveToSessionStorage('auth', null);

            return res;
        });
    }
}
