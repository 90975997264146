/* eslint-disable @typescript-eslint/no-explicit-any */
import {action, computed, observable} from 'mobx';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {getMeetingTracer} from '../../../../../states/meeting/meeting.tracer';
import {SurveyForm} from './model';
import {Nullable} from '@techsee/techsee-common';
import {isCustomParamsExists, getRedirectURLCustomParameters} from '@techsee/techsee-common/lib/utils/room-utils';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';

const trace = getMeetingTracer('EndSurveyController');

const TOP_RATE_DELAY = 1000;

export interface CustomerSurveyQuestions {
    lowRatingFeedback: string;
    askRating: string;
}

export interface ISurveyController {
    readonly questions: any;
    readonly rating: number;
    readonly showFeedbackForm: boolean;
    readonly form: SurveyForm;
    readonly feedbackConfirmation: boolean;
    readonly theme: string;
    readonly themePrefix: string;

    setRating(rating: number): void;
    setFeedbackForm(value: boolean): void;
    submitFeedback(event: React.FormEvent<HTMLFormElement>): void;
    redirect(skip: boolean): void;
}

export class SurveyController implements ISurveyController {
    readonly form: SurveyForm;

    private _rootScope: any;
    private _stateParams: any;
    private _tsStateHelper: any;
    private _translate: any;
    private _db: any;
    private _tsChatApi: any;
    private _tsTermsAndConditions: any;

    @observable
    private _rating = 0;

    @observable
    private _showFeedbackForm = false;

    @observable
    private _feedbackConfirmation = false;

    @observable
    private _questions?: CustomerSurveyQuestions;

    @observable
    private _theme = '';

    @observable
    private _themePrefix = '';

    private _confirmTimeout: Nullable<ReturnType<typeof setTimeout>>;
    private _ratingPromise: Promise<any> | null;
    private _allowedCustomParams: any[];
    private _redirectionDelay: number;

    constructor(
        rootScope: any,
        stateParams: any,
        tsStateHelper: any,
        translate: any,
        db: any,
        tsChatApi: any,
        tsTermsAndConditions: any
    ) {
        this._confirmTimeout = null;
        this._ratingPromise = null;

        this.form = new SurveyForm(translate);
        this._rootScope = rootScope;
        this._stateParams = stateParams;
        this._tsStateHelper = tsStateHelper;
        this._translate = translate;
        this._db = db;
        this._tsChatApi = tsChatApi;
        this._tsTermsAndConditions = tsTermsAndConditions;
        const keepCustomerMobile =
            this._tsChatApi.keepCustomerMobile || get(this._tsChatApi, 'accountSettings.keepCustomerMobile');

        this.setBranding();

        this._allowedCustomParams = getRedirectURLCustomParameters(keepCustomerMobile);

        const redirectType = this._tsChatApi.redirectType || get(this._tsChatApi, 'accountSettings.redirectType');

        this._redirectionDelay =
            redirectType === 'URL' && !isUndefined(this._tsChatApi.redirectDelayTime)
                ? this._tsChatApi.redirectDelayTime
                : 3000;

        const roomId = this._tsChatApi.lastUsedRoomId;

        if (stateParams.webRtcSupported === 'false') {
            return;
        }

        if (roomId && get(this._tsChatApi, 'customerSurveyEnabled')) {
            this._db.Rooms.isSuccessfulSession(roomId)
                .then((response: any) => {
                    if (!get(response, 'data.isSuccessful')) {
                        throw new Error('Failed room, no survey should be shown');
                    }

                    const accountId = get(response, 'data.accountId');

                    if (!accountId) {
                        throw new Error('No account id returned');
                    }

                    return this._db.CustomerSurveys.questions(accountId);
                })
                .then((res: any) => {
                    const questionsObj = res.data.questions.reduce((acc: any, question: any) => {
                        acc[question.type] = question.question;

                        return acc;
                    }, {});

                    this.setQuestions(questionsObj);
                })
                .then(() => this._db.CustomerSurveys.rating({data: {roomId, rating: 0}}))
                .catch(() => this.redirect(false));
        } else {
            this.redirect(false);
        }
    }

    @computed
    get questions(): CustomerSurveyQuestions | undefined {
        return this._questions;
    }

    @computed
    get theme(): string {
        return this._theme;
    }

    @computed
    get themePrefix(): string {
        return this._themePrefix;
    }

    @computed
    get showFeedbackForm(): boolean {
        return this._showFeedbackForm;
    }

    @action
    setFeedbackForm(value: boolean): void {
        this._showFeedbackForm = value;
    }

    @computed
    get rating(): number {
        return this._rating;
    }

    @action
    setRating(rating: number): void {
        this._rating = rating;
        this.setFeedbackForm(rating <= get(this._tsChatApi, 'customerSurveyMaxFeedbackRating'));

        if (this._confirmTimeout) {
            clearTimeout(this._confirmTimeout);
            this._confirmTimeout = null;
        }

        const roomId = this._tsChatApi.lastUsedRoomId;

        if (this._rating > get(this._tsChatApi, 'customerSurveyMaxFeedbackRating')) {
            this._confirmTimeout = setTimeout(() => {
                this._ratingPromise = this._db.CustomerSurveys.rating({data: {roomId, rating: this._rating}})
                    .catch(console.error)
                    .then(() => {
                        this._ratingPromise = null;
                        this.showFeedbackConfirmation();
                        this.redirect(false);
                    });
            }, TOP_RATE_DELAY);
        } else {
            this._ratingPromise = this._db.CustomerSurveys.rating({data: {roomId, rating: this._rating}})
                .catch(console.error)
                .then(() => {
                    this._ratingPromise = null;
                });
        }
    }

    @action
    showFeedbackConfirmation(): void {
        if (this._redirectionDelay) {
            this._feedbackConfirmation = true;
        }
    }

    @computed
    get feedbackConfirmation(): boolean {
        return this._feedbackConfirmation;
    }

    @action
    submitFeedback(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();

        this.showFeedbackConfirmation();

        const roomId = this._tsChatApi.lastUsedRoomId;

        const ratingPromise = this._ratingPromise ? this._ratingPromise : Promise.resolve();
        const answerPromise = this.form.fields.answer.value
            ? this._db.CustomerSurveys.answers({
                  data: {
                      roomId,
                      answers: [
                          {
                              question: this._questions && this._questions.lowRatingFeedback,
                              answer: this.form.fields.answer.value
                          }
                      ]
                  }
              })
            : Promise.resolve();

        ratingPromise
            .then(() => answerPromise)
            .catch(console.error)
            .then(() => this.redirect(false));
    }

    redirect(skip: boolean): void {
        this._tsTermsAndConditions.abort(this._tsChatApi);

        let redirectType = this._tsChatApi.redirectType || get(this._tsChatApi, 'accountSettings.redirectType');
        let redirectUrl = this._tsChatApi.redirectUrl || get(this._tsChatApi, 'accountSettings.redirectUrl');

        const {csi, expiredRoom} = this._stateParams;

        let brandingPromise = null;

        if (!isEmpty(this.themePrefix) && expiredRoom) {
            brandingPromise = this._db.Account.getBrandingRedirectSettings({
                params: {
                    theme: this.theme
                }
            })
                .then((res: any) => {
                    const settings = get(res, 'data.settings');

                    if (!settings || get(res, 'data.matches') !== 1) {
                        redirectType = 'UNBRANDED_DEFAULT';

                        return;
                    }

                    redirectType = get(settings, 'redirectType');
                    redirectUrl = get(settings, 'redirectUrl');
                })
                .catch(() => {
                    redirectType = 'UNBRANDED_DEFAULT';
                });
        } else {
            brandingPromise = Promise.resolve();
        }

        brandingPromise.then(() => {
            const delay = skip ? 0 : this._redirectionDelay;

            // If 'redirectUrl' is set, redirect there.
            // Otherwise, if the account is using field services, redirect back to start page and if not to TechSee homepage
            setTimeout((): any => {
                if (expiredRoom && isEmpty(this.themePrefix)) {
                    /**
                     * commenting out the redirect so client remains on 'Session Expired' page
                     * that is still temporary fix because the relevant account does not have branding set
                     * meaning is that if account will set new branding, problem can presist
                     * see ticket LV-1719 for further details
                     */
                    // window.location.href = DEFAULT_REDIRECT_URL;
                    return;
                }

                if (redirectType === 'URL' && redirectUrl) {
                    this._replaceCustomParams(redirectUrl)
                        .then((url: any) => {
                            const tracePromise = () => trace.info('Reached redirect url: ' + url);

                            return PromiseUtilsService.startPromiseWithTimeout(tracePromise, 3000).then(
                                () => (window.location.href = url)
                            );
                        })
                        .catch(() => {
                            const tracePromise = () =>
                                trace.info('Reached redirect url catch redirectUrl' + redirectUrl);

                            return PromiseUtilsService.startPromiseWithTimeout(tracePromise, 3000).then(
                                () => (window.location.href = redirectUrl)
                            );
                        });

                    return;
                }

                if (redirectType === 'UNBRANDED_DEFAULT') {
                    window.location.href = UNBRANDED_DEFAULT_REDIRECT_URL;

                    return;
                }

                if (redirectType === 'DEFAULT') {
                    window.location.href = DEFAULT_REDIRECT_URL;

                    return;
                }

                if (this._tsChatApi.fsEnabled || csi) {
                    Promise.all([this._tsChatApi.disconnect()]).then(() => {
                        const params: any = csi ? {csi} : {};

                        params.postMeeting = true;

                        this._tsChatApi.temporaryRoomCode = null;

                        return this._tsStateHelper.safeGo('start.main', params, {reload: true});
                    });
                }
            }, delay);
        });
    }

    private async _replaceCustomParams(url: string) {
        const roomId = this._tsChatApi.lastUsedRoomId;

        await this.traceUtil(
            'Start _replaceCustomParams url=' + url + ' lastUsedRoomId=' + this._tsChatApi.lastUsedRoomId
        );

        if (!roomId || !isCustomParamsExists(this._allowedCustomParams, url)) {
            await this.traceUtil('isCustomParamsExists url: ' + url + ' roomId: ' + roomId);

            return Promise.resolve(url);
        }

        const response = await this._db.Rooms.endSessionReturnUrl({
            params: {
                roomId,
                url
            }
        });

        const returnUrl = response.data;

        await this.traceUtil('End _replaceCustomParams returnUrl - ' + returnUrl);

        return returnUrl;
    }

    @action
    private setQuestions(questions: CustomerSurveyQuestions): void {
        this._questions = {...questions};
    }

    private setBranding() {
        const theme = this._rootScope.THEME || '';

        let themePrefix = this._rootScope.THEME ? this._rootScope.THEME + '-' : '';

        if (theme && theme !== 'in') {
            themePrefix = 'branding-';
        }

        this._theme = theme;
        this._themePrefix = themePrefix;
    }

    private async traceUtil(message: string) {
        const tracePromise = () => trace.info(message);

        await PromiseUtilsService.startPromiseWithTimeout(tracePromise, 3000);
    }
}

export default SurveyController;
