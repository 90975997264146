'use strict';

import {getBrowserInfo} from 'shared/browser-info.js';
import moment from 'moment';
import get from 'lodash/get';
import map from 'lodash/map';
import historySearchView from './history-search/history-search-view.html';
import imageLightboxView from './dialogs/image-lightbox-view.html';
import {imageLightboxController} from './dialogs/image-lightbox.controller.js';
import appOpenView from './dialogs/app-open.view.html';
import {appOpenController} from './dialogs/app-open.controller.js';
import phoneOptionsView from './dialogs/phone-options.view.html';
import {phoneOptionsController} from './dialogs/phone-options.controller.js';
import {MeetingMode, FlowType} from '@techsee/techsee-common/lib/constants/room.constants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

// common styles for modals
import './dialogs/start-dialogs.style.scss';
import {getRootStore} from '../../_react_/app.bootstrap';

export class StartController {
    constructor($uibModal, $localStorage, $scope, $window, tsStateHelper, currentUser, accountData, csiSettings, db) {
        'ngInject';

        this.showStartPage = true;

        if (getRootStore().urlUtils.getParamValue('postMeeting') === 'true') {
            getRootStore().urlUtils.setParamValue('postMeeting', 'false');

            $window.location.href = getRootStore().urlUtils.getAbsoluteUrl();
            $window.location.reload();

            return;
        }

        this.tsEnvironmentDetect = getRootStore().environmentDetect;
        this.tsChatApi = getRootStore().chatApi;
        this.$scope = $scope;
        this.$window = $window;
        this.tsUrlUtils = getRootStore().urlUtils;
        this.stateHelper = tsStateHelper;
        this.auth = getRootStore().authService;
        this.currentUser = currentUser;
        this.accountData = accountData;
        this.csiSettings = csiSettings;
        this.isCSI = !currentUser && csiSettings;
        this.$uibModal = $uibModal;
        this.$localStorage = $localStorage;
        this.db = db;
        this.historySearchView = historySearchView;
        this.waitForExpert = this.tsUrlUtils.getParamValue('waitForExpert');
        this.searchWatermarkTranslate = getRootStore().localizationService.translate(
            'START.MAIN.VIEW.SEARCH.WATERMARK'
        );

        if (this.waitForExpert) {
            tsStateHelper.go('start.center');

            return;
        }

        this.centerIcon = BASE_PATH + 'img/icon-service-center.png';
        this.expertIcon = BASE_PATH + 'img/icon-expert.png';
        this.displayCustomerReferenceError = false;
        this.enableCustomerReference = get(this.accountData, 'settings.customerId.display');
        this.enableNoInternetMode =
            get(this.accountData, 'protectedSettings.enableFieldServices') &&
            get(this.accountData, 'protectedSettings.enableNoInternetMode');
        this.displaySearchError = false;
        this.keepCustomerMobile = get(this.accountData, 'protectedSettings.keepCustomerMobile');
        this.enableHistorySearch =
            get(this.accountData, 'protectedSettings.enableHistory') &&
            get(this.accountData, 'protectedSettings.enableHistorySearch');
        this.historySearchResults = {};
        this.HISTORY_STATUS = {
            HIDE_HISTORY: 'hideHistory',
            SHOW_HISTORY: 'showHistory',
            SEARCH_RESULTS: 'searchResults',
            SESSION_RECORD: 'sessionRecord'
        };
        this.onlineSession = true;

        this.showHistory = {
            show: this.HISTORY_STATUS.HIDE_HISTORY,
            status: this.HISTORY_STATUS.SEARCH_RESULTS
        };

        this.enableSMS =
            this.accountData.settings &&
            this.accountData.settings.fsSettings &&
            this.accountData.settings.fsSettings.fsInviteBySMS;
        this.enableWhatsApp =
            this.accountData.settings &&
            this.accountData.settings.fsSettings &&
            this.accountData.settings.fsSettings.fsInviteByWhatsApp;
        this.enableEmail =
            this.accountData.settings &&
            this.accountData.settings.fsSettings &&
            this.accountData.settings.fsSettings.fsInviteByEmail;
        this.supportVoiceCalls =
            this.isCSI ||
            (this.accountData.settings &&
                this.accountData.settings.fsSettings &&
                this.accountData.settings.fsSettings.supportVoiceCalls);
        this.defaultPhoneNumber =
            (this.isCSI && this.csiSettings.defaultPhoneNumber) ||
            (this.accountData.settings &&
                this.accountData.settings.fsSettings &&
                this.accountData.settings.fsSettings.defaultPhoneNumber);

        this.roomCode = this.tsChatApi.temporaryRoomCode;

        this._setAvailableModes();

        this._initHandlers();

        getRootStore().browserDetect.run(() => {
            if (!this.roomCode) {
                this._prepareInviteLinks();
            } else {
                return this._createOrUpdateCode(this.roomCode);
            }
        });
    }

    _setAvailableModes() {
        this.enableOfflineSessions = get(this.accountData, 'settings.accountOfflineSessions');

        const {supportVoiceCalls, voiceCallFixedNumber, voiceCallFromGroup, voiceCallAddressBook} = get(
            this.accountData,
            'settings.fsSettings'
        );

        this.enableCenter =
            !supportVoiceCalls || (voiceCallFixedNumber && !voiceCallFromGroup && !voiceCallAddressBook);
        this.enableExpert =
            !supportVoiceCalls || (!voiceCallFixedNumber && (voiceCallFromGroup || voiceCallAddressBook));
    }

    _historyRecordsFixTimestamp(records) {
        map(records, (record) => {
            const recordMoment = moment(record.timestamp);
            const month = recordMoment.format('MMMM').toString();
            const day = recordMoment.format('D').toString();
            const year = recordMoment.format('YYYY').toString();
            const time = recordMoment.format('HH:mm').toString();

            record.timestamp = `${month} ${day}, ${year} ${time}`;
        });
    }

    _prepareInviteLinks() {
        if (this.roomCode) {
            const dashboardJoinUrl = TsUtils.replaceUrlDomain(DASHBOARD_URL, this.$window.location.hostname);
            const url = dashboardJoinUrl + '?roomCode=' + this.roomCode;
            const encodedUrl = encodeURIComponent(
                getRootStore().localizationService.translate('START.CONTROLLER.MESSAGE_TEXT', {url: url})
            );

            if (this.tsEnvironmentDetect.isIOS()) {
                this.smsTrigger = `sms:&body=${encodedUrl}`;
            } else {
                this.smsTrigger = `sms:?body=${encodedUrl}`;
            }

            this.whatsappTrigger = `whatsapp://send?text=${encodedUrl}`;
            this.emailTrigger = `mailto:?body=${encodedUrl}`;
        }

        this.emailTrigger += `&subject=${encodeURIComponent(getRootStore().localizationService.translate('START.CONTROLLER.MESSAGE_SUBJECT', {}))}`;
    }

    _initHandlers() {
        const joinRoomHandler = (roomId) => {
            const options = {},
                // FIXME
                // TS-2486 - When in csi mode, there's no accountData
                // So the showRoomId will be true and the roomId will always be shown, no matter what is the setting
                // In order to fix it - the shortId should be
                // emitted in the joinRoom event together with roomId
                showRoomId = this.accountData.protectedSettings
                    ? this.accountData.protectedSettings.showRoomIdInUrl
                    : true,
                mediaServiceTypes = get(this.accountData, 'protectedSettings.mediaServiceType');

            if (this.isCSI) {
                options.csi = this.tsUrlUtils.getParamValue('csi');
            }

            let customerRefIdpromise = Promise.resolve();

            if (this.customerReferenceId) {
                customerRefIdpromise = this.db.Rooms.createInstance({_id: roomId}).setCustomerReference(
                    this.customerReferenceId
                );
            }

            const setDevicePromise = (async () => {
                const browserInfo = await getBrowserInfo();

                return this.db.Rooms.createInstance({_id: roomId}).setDeviceInfo(browserInfo, false, true);
            })();

            return Promise.all([customerRefIdpromise, setDevicePromise]).finally(() => {
                this.tsUrlUtils.setParamValue('media-service', mediaServiceTypes);
                options['media-service'] = mediaServiceTypes;

                if (showRoomId) {
                    options.room = roomId;
                    this.tsUrlUtils.setParamValue('room', roomId);
                    this.stateHelper.safeGo('meeting', options);
                } else {
                    return this.db.Rooms.sentUrl(roomId).then((reply) => {
                        options.g = TsUtils.findGuidInUrl(reply.data.url);
                        this.tsUrlUtils.setParamValue('g', options.g);
                        this.stateHelper.safeGo('meeting', options);
                    });
                }
            });
        };

        this.tsChatApi.on(socketEvents.CLIENT_IN.JOIN_ROOM, joinRoomHandler);

        this.$scope.$on('$destroy', () => {
            this.tsChatApi.off(socketEvents.CLIENT_IN.JOIN_ROOM, joinRoomHandler);
        });
    }

    _createOrUpdateCode(existingCode) {
        return this.tsChatApi.requestRoomCode(existingCode, this.currentUser).then(({roomCode}) => {
            this.roomCode = roomCode;
            this.tsChatApi.temporaryRoomCode = roomCode;

            this._prepareInviteLinks();
        });
    }

    historyRecordClicked(value) {
        if (value.images.length > 0) {
            this.showHistory.status = this.HISTORY_STATUS.SESSION_RECORD;
            this.historySearchResults.selectedRecord = value;
        }
    }

    submitHistoryForm() {
        this.historySearchInputValue = this.searchHistoryForm.idOrNumber;
        if (this.searchHistoryForm.$valid) {
            return this.searchHistory(this.historySearchInputValue).catch((err) => console.error(err));
        }
    }

    searchHistory(idOrNumber) {
        this.displaySearchError = false;

        return this.db.History.byCustomerIdOrNumber(
            {
                params: {
                    customerId: idOrNumber,
                    customerNumber: idOrNumber,
                    accountId: this.accountData._id
                }
            },
            {bypassCache: true}
        ).then((result) => {
            this.historySearchResults = result && result.data;

            if (!this.historySearchResults.records.length) {
                this.displaySearchError = true;

                return;
            }

            this._historyRecordsFixTimestamp(this.historySearchResults.records);
            this.showHistory.show = this.HISTORY_STATUS.SHOW_HISTORY;
            this.showHistory.status = this.HISTORY_STATUS.SEARCH_RESULTS;
        });
    }

    closeHistoryOrGoBack() {
        this.showHistory.status === this.HISTORY_STATUS.SEARCH_RESULTS
            ? (this.showHistory.show = this.HISTORY_STATUS.HIDE_HISTORY)
            : (this.showHistory.status = this.HISTORY_STATUS.SEARCH_RESULTS);
    }

    enlargeSessionImage(value) {
        const modalInstance = this.$uibModal.open({
            backdrop: 'static',
            template: imageLightboxView,
            controller: imageLightboxController,
            controllerAs: 'vm',
            windowClass: 'open-image',
            backdropClass: 'image-background-opacity',
            resolve: {
                appData: () => ({
                    imageURL: value
                })
            }
        });

        modalInstance.result.catch(() => false);
    }

    launchInviteApp(type) {
        const doNotShowAgain = this.$localStorage.appDnsa === 'true';

        if (doNotShowAgain) {
            switch (type) {
                case 'sms':
                    this.$window.location = this.smsTrigger;
                    break;
                case 'whatsapp':
                    this.$window.location = this.whatsappTrigger;
                    break;
                case 'email':
                    this.$window.location = this.emailTrigger;
                    break;
            }

            return;
        }

        const appOpenModal = this.$uibModal.open({
            backdrop: 'static',
            template: appOpenView,
            controller: appOpenController,
            controllerAs: 'vm',
            windowClass: 'start-dialog',
            resolve: {
                appData: () => ({
                    type: type,
                    whatsappTrigger: this.whatsappTrigger,
                    emailTrigger: this.emailTrigger,
                    smsTrigger: this.smsTrigger
                })
            }
        });

        appOpenModal.result
            .then((doNotShowAgain) => {
                this.$localStorage.appDnsa = doNotShowAgain;
                // TODO go to next state
            })
            .catch(() => false);
    }

    launchPhoneOptions(number = '', nextState = undefined) {
        const doNotShowAgain = this.$localStorage.phoneOptionsDnsa === 'true';

        if (doNotShowAgain) {
            this.$window.location = `tel:${number}`;

            return;
        }

        const phoneOptionsModal = this.$uibModal.open({
            backdrop: 'static',
            template: phoneOptionsView,
            controller: phoneOptionsController,
            controllerAs: 'vm',
            windowClass: 'start-dialog',
            resolve: {
                appData: () => ({
                    defaultPhoneNumber: number
                })
            }
        });

        phoneOptionsModal.result
            .then((doNotShowAgain) => {
                this.$localStorage.phoneOptionsDnsa = doNotShowAgain;
                if (nextState) {
                    this.stateHelper.safeGo(nextState);
                }
            })
            .catch(() => false);
    }

    logout() {
        const accountId = this.currentUser.accountId;
        const userId = this.currentUser._id;

        this.auth.logout(accountId, userId).then(() => {
            this.tsChatApi.disconnect();
            this.stateHelper.safeGo('fs');
        });
    }

    isCurrentState(state) {
        return this.stateHelper && this.stateHelper.$state.current.name === state;
    }

    goToState(state) {
        this.stateHelper.safeGo(state);
    }

    startOnlineSession(whereToGo) {
        if (this.enableCustomerReference && !this.customerReferenceId) {
            this.displayCustomerReferenceError = true;

            return;
        }

        this.displayCustomerReferenceError = false;
        this._createOrUpdateCode().then(() => this.goToState(whereToGo));
    }

    startOfflineSession() {
        const data = {
            offline: true,
            flowType: FlowType.fs,
            startWithAgentType: MeetingMode.images
        };

        if (this.customerReferenceId) {
            data.customerId = this.customerReferenceId;
        }

        return Promise.all([this.db.Rooms.create(data), this._createOrUpdateCode()]).then((result) => {
            const roomId = result[0]._id;

            return this.db.Shorturl.create({roomId, offline: true, flowType: data.flowType}).then(
                (shortCreationResult) => {
                    const options = {
                        roomId,
                        roomCode: this.roomCode,
                        g: TsUtils.findGuidInUrl(shortCreationResult.url)
                    };

                    this.tsUrlUtils.setParamValue('g', options.g);
                    this.tsUrlUtils.setParamValue('roomCode', this.roomCode);
                    this.tsUrlUtils.setParamValue('flow-type', data.flowType);
                    this.stateHelper.safeGo('meeting', options);
                }
            );
        });
    }
}
