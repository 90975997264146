import {IEventLogService} from '@techsee/techsee-client-infra/lib/services/EventLogService';
import {EVENT_LEVELS, EVENT_SOURCES} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {PlatformType} from '@techsee/techsee-common/lib/constants/utils.constant';
import {TraceOutputData} from '@techsee/techsee-common/lib/core/Tracer';
import {TsExtraLoggingService} from '@techsee/tracer';
import {IVerboseLogging} from '@techsee/techsee-common/lib/constants/account.constants';

export interface ITsEventService {
    setRoomDetails(roomId: string, roomCode: string): void;
    setVerboseSettings(verboseSettings: IVerboseLogging): void;
    traceToEventLogOutputFunction(traceData: TraceOutputData): Promise<void>;
    sendEventLog(userId: string | null, roomId: string | null, type: string, meta?: any): void;
}

export class TsEventService {
    private _eventsLogService: IEventLogService;
    private _extraLoggingService: TsExtraLoggingService;

    constructor(eventsLogService: IEventLogService) {
        this._eventsLogService = eventsLogService;

        this._extraLoggingService = new TsExtraLoggingService(PlatformType.mobile_web, CLIENT_VERSION);
        this.traceToEventLogOutputFunction = this.traceToEventLogOutputFunction.bind(this);
    }

    setRoomDetails(roomId: string, roomCode: string) {
        this._extraLoggingService.setRoomDetails(roomId, roomCode);
    }

    setVerboseSettings(
        verboseSettings: IVerboseLogging,
        userId?: string,
        accountId?: string,
        roomId?: string,
        roomCode?: string
    ) {
        this._extraLoggingService.setVerboseSettings(verboseSettings, userId, accountId, roomId, roomCode);
    }

    async traceToEventLogOutputFunction(traceData: TraceOutputData) {
        await this._extraLoggingService.traceToEventLogOutputFunction(traceData);
    }

    isEmpty(value: string | null) {
        if (!value || value === 'none' || value === null) {
            return false;
        }

        return true;
    }

    sendEventLog(userId: string | null, roomId: string | null, type: string, meta?: any, level?: EVENT_LEVELS) {
        const accountId = this._extraLoggingService.accountId ?? 'none';
        const finalUserId = userId ? userId : (this._extraLoggingService.userId ?? 'none');
        const finalRoomId = roomId ? roomId : (this._extraLoggingService.roomId ?? 'none');
        const sentBy = EVENT_SOURCES.client;

        return this._eventsLogService.addEventLog(type, finalRoomId, finalUserId, accountId, level, sentBy, meta);
    }
}
