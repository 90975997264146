/* eslint-disable dot-notation */
import {EventEmitter} from 'events';
import get from 'lodash/get';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {COBROWSING_EVENTS} from '@techsee/techsee-common/lib/constants/room.constants';

const timeOutWaitForIframeInit = 500;
const timeOutWaitForIframe = 200;

const COBROWSERS = {
    LEADER: 0,
    FOLLOWER: 1
};

interface SurflyWindow extends Window {
    Surfly: any;
}

interface SurflyHTMLElement extends HTMLElement {
    async: number;
    src: string;
}

export class CoBrowsingServiceBase extends EventEmitter {
    private isFollower: boolean;
    protected surflyObject: any;
    protected sessionObject: any;
    private waitForIframeTimeout: any;
    protected tsEventService: any;
    protected chatApi: any;
    protected isInControl: boolean;

    public isPaused: boolean;

    constructor(isFollower: boolean, chatApi: any, tsEventService: any) {
        super();

        this.isFollower = isFollower;
        this.isPaused = false;
        this.chatApi = chatApi;
        this.isInControl = false;
        this.tsEventService = tsEventService;
    }

    protected getSurflyObject(window: SurflyWindow, widgetKey: any) {
        return new Promise((resolve, reject) => {
            if (window.Surfly) {
                return resolve(window.Surfly);
            }

            // eslint-disable-next-line wrap-iife
            (function (s, u, r, f, l: undefined | SurflyHTMLElement, y: undefined | Element) {
                s.Surfly = s.Surfly || {
                    init: function () {
                        // eslint-disable-next-line prefer-rest-params
                        s.Surfly.q = arguments;
                    }
                };
                // eslint-disable-next-line no-param-reassign
                l = u.createElement(r) as SurflyHTMLElement;
                // eslint-disable-next-line no-param-reassign
                y = u.getElementsByTagName(r)[0];
                l ? (l.async = 1) : undefined;
                l ? (l.src = 'https://surfly.com/surfly.js') : undefined;
                l && y.parentNode && y.parentNode.insertBefore(l, y);
            })(window, document, 'script', 'Surfly', undefined, undefined);

            const settings = {
                // Surfly session options can be set here, or at the Company/Plan levels.
                // eslint-disable-next-line camelcase
                widget_key: widgetKey
            };

            if (!window.Surfly) {
                return reject('Surfly object was unable to initialize properly');
            }

            window.Surfly.init(settings, (initResult: any) => {
                if (initResult && initResult.success && !window.Surfly.isInsideSession) {
                    return resolve(window.Surfly);
                }

                return reject('Surfly was unable to initialize properly');
            });
        }).then((surfly) => {
            this.surflyObject = surfly;

            return surfly;
        });
    }

    protected isCurrentSession(session: any) {
        return get(session, '_sessionId') === get(this.chatApi, 'dashboard.coBrowsingSettings.sessionId');
    }

    protected initSession(url: string) {
        if (!url) {
            throw new Error('url is not exists');
        }

        if (!this.surflyObject) {
            throw new Error('surflyObject is not define');
        }

        const surflySession = this.surflyObject.session({}, url);

        if (surflySession) {
            surflySession
                .on(COBROWSING_EVENTS.SESSION_STARTED, (session: any) => {
                    this.sessionObject = session;
                    this.isPaused = this.sessionObject.paused;
                    this.emit(COBROWSING_EVENTS.SESSION_STARTED);
                })
                .on(COBROWSING_EVENTS.VIEWER_JOINED, (session: any) => {
                    this.emit(COBROWSING_EVENTS.VIEWER_JOINED, session);
                })
                .on(COBROWSING_EVENTS.VIEWER_LEFT, (session: any) => {
                    this.emit(COBROWSING_EVENTS.VIEWER_LEFT, session);
                })
                .on(COBROWSING_EVENTS.SESSION_ENDED, (session: any) => {
                    if (this.isCurrentSession(session)) {
                        this.sessionObject = null;
                        this.emit(COBROWSING_EVENTS.SESSION_ENDED);
                    }
                })
                .on(COBROWSING_EVENTS.CONTROL, (session: any, control: any) => {
                    if (this.isCurrentSession(session)) {
                        const me = this.isFollower ? COBROWSERS.FOLLOWER : COBROWSERS.LEADER;

                        this.isInControl = control.to === me;

                        const agentInControl = !this.isInControl;

                        this.emit(COBROWSING_EVENTS.CONTROL_SWITCH, agentInControl);
                    }
                })
                .on(COBROWSING_EVENTS.ERROR, (err: any) => {
                    this.emit(COBROWSING_EVENTS.ERROR, err);
                });

            this.setIframe(surflySession);
        }
    }

    setIframe(surflySession: any) {
        if (this.waitForIframeTimeout) {
            clearTimeout(this.waitForIframeTimeout);
        }

        const iframeId = this.isFollower ? '#coBrowsingFollowerFrame' : '#coBrowsingSurflyLeaderFrame';

        const waitForIframe = () => {
            const iframe = document.querySelector(iframeId);

            if (iframe) {
                if (this.isFollower) {
                    return surflySession.startFollower(iframeId, {});
                }

                return surflySession.startLeader(iframeId, {});
            }

            this.waitForIframeTimeout = setTimeout(waitForIframe, timeOutWaitForIframe);
        };

        this.waitForIframeTimeout = setTimeout(waitForIframe, timeOutWaitForIframeInit);
    }

    switchControl() {
        const agentInControl = get(this.chatApi, 'dashboard.coBrowsingSettings.agentInControl');
        const to = agentInControl ? COBROWSERS.LEADER : COBROWSERS.FOLLOWER;

        return this.sessionObject && this.sessionObject.giveControl(to);
    }

    relocate(url: string) {
        let _url = url;

        if (!/^https?:\/\//i.test(_url)) {
            _url = 'https://' + _url;
        }

        this.tsEventService.sendEventLog('none', this.chatApi.roomId || 'none', LOG_EVENTS.coBrowsingSurflyChangeUrl, {
            url
        });

        return this.sessionObject && this.isInControl && this.sessionObject.relocate(url);
    }

    draw(enabled: boolean) {
        return (
            this.sessionObject &&
            this.isInControl &&
            this.sessionObject.setDrawingSettings({
                userIndex: this.isFollower ? COBROWSERS.FOLLOWER : COBROWSERS.LEADER,
                enabled: !!enabled,
                mode: enabled ? 'permanent' : 'disabled'
            })
        );
    }
}
