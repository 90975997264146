'use strict';

import {getRootStore} from '../../../_react_/app.bootstrap';

export class phoneOptionsController {
    constructor($uibModalInstance, $window, appData) {
        'ngInject';

        this.$uibModalInstance = $uibModalInstance;
        this.$window = $window;
        this.appData = appData;
        this.isIOS = getRootStore().environmentDetect.isIOS();
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }

    ok() {
        this.$window.location = `tel:${this.appData.defaultPhoneNumber}`;
        this.$uibModalInstance.close(this.doNotShowAgain);
    }
}
