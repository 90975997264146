import get from 'lodash/get';
import assign from 'lodash/assign';
import throttle from 'lodash/throttle';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {getMeetingTracer} from '../../states/meeting/meeting.tracer';
import {CanvasDrawer} from '@techsee/techsee-client-services/lib/services/CanvasDrawerService';
import {CanvasEvents} from '@techsee/techsee-client-infra/lib/helpers/CanvasEvents';
import {MediaServiceType} from '@techsee/techsee-media-service/lib/MediaConstants';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';
import {getRootStore} from '../../_react_/app.bootstrap';

const trace = getMeetingTracer('VideoPublisherDirective');

export const VIDEO_SIZES = {
    NORMAL: 'normal',
    THUMBNAIL: 'thumbnail'
};

export function getVideoStyle(videoSize) {
    if (videoSize === VIDEO_SIZES.THUMBNAIL) {
        return {
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: '100px',
            height: '100px',
            'border-radius': '50%',
            border: '5px solid #1C90EF',
            overflow: 'hidden',
            cursor: 'pointer',
            'z-index': 2000
        };
    }

    return {
        width: '100%',
        height: '100%'
    };
}

class TsVideoPublisherController {
    constructor($window, $scope) {
        'ngInject';

        this.chatApi = getRootStore().chatApi;
        this.$window = $window;
        this._eventService = getRootStore().eventService;
        this.settings = null;
        this.isMobile = getRootStore().environmentDetect.isMobile();
        const iosVersion = getRootStore().environmentDetect.isIOS() && getRootStore().environmentDetect.os();

        this.isIos17AndResizeFix =
            iosVersion?.version && parseInt(iosVersion.version, 10) >= 17 && parseFloat(iosVersion.version) < 17.4;

        this.remoteDrawingCanvas = null;
        this.localDrawingCanvas = null;
        this._element = null;
        this._pointer = null;
        this._lastCursorData = null;
        this._container = null;
        this._containerWidth = 1;
        this._containerHeight = 1;
        this._videoWidth = 1;
        this._videoHeight = 1;
        this._frame = {
            left: 0,
            top: 0,
            width: 1,
            height: 1
        };

        this.videoSubscriber = null;

        this.updateFrame = throttle(this.updateFrame.bind(this), 200);

        this._onCursor = (param, cursor) => this._updateCursor(cursor);

        this._onDraw = (param, data) => {
            this._updateCanvas(param, data);

            if (data.length) {
                this._eventService.sendEventLog(
                    'none',
                    this.chatApi.roomId || 'none',
                    LOG_EVENTS.magicMarkerDrawingReceived,
                    null
                );
            }
        };

        $scope.$watch(
            () => this.isMinimized,
            () => {
                this.updateFrame();
            }
        );

        $scope.$watchGroup([() => this.streamType, () => this._container], () => {
            this.initSubscriber();
        });
    }

    get annotationCanvasElement() {
        return (
            this._annotationCanvasElement ||
            (this._annotationCanvasElement = $(this._element[0]).find('.video-annotations'))
        );
    }

    get localDrawingCanvasElement() {
        return (
            this._localDrawingCanvasElement ||
            (this._localDrawingCanvasElement = $(this._element[0]).find('.drawing-canvas'))
        );
    }

    init(element) {
        this.isMagicMarkerEnabled =
            get(this.chatApi, 'accountSettings.magicMarker.dashboard.enable') &&
            (!this.isMobile || get(this.chatApi, 'accountSettings.magicMarker.dashboard.enable'));
        this.isCustomerMagicMarkerEnabled =
            this.isMagicMarkerEnabled && get(this.chatApi, 'accountSettings.magicMarker.client.enable');
        this._element = element;
        this._container = $('<div>').addClass('video-container').hide();
        this._pointer = $('<div>').addClass('live-pointer').hide();
        // dashboard = remote, client = local
        const remoteDrawingsCanvasElement = $('<canvas>').addClass('video-annotations');
        const localDrawingCanvasElement = $('<canvas>').addClass('drawing-canvas');

        this._container.append(this._pointer);
        this._element.append(this._container);

        if (get(this.chatApi, 'accountSettings.enableNewLivePointerDesign')) {
            this._pointer.addClass('live-pointer-new');
        }

        this._container.append(remoteDrawingsCanvasElement);
        this._container.append(localDrawingCanvasElement);

        const drawingOptions = {
            lineWidth: get(this.chatApi, 'accountSettings.magicMarker.dashboard.lineWidth'),
            lineWidthRefResolution: {width: 640, height: 480},
            strokeColorRGB: get(this.chatApi, 'accountSettings.magicMarker.dashboard.strokeColorRgb')
        };

        const timeUntilDrawingStartFading = get(
            this.chatApi,
            'accountSettings.magicMarker.dashboard.timeUntilDrawingStartFading'
        );

        this.remoteDrawingCanvas = new CanvasDrawer(
            this.annotationCanvasElement.get(0),
            drawingOptions,
            undefined,
            timeUntilDrawingStartFading
        );
        this.$window.addEventListener('resize', () => {
            if (this.isIos17AndResizeFix) {
                setTimeout(() => this.updateFrame(), 200);
            } else {
                this.updateFrame();
            }
        });
        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_CURSOR, this._onCursor);
        this.chatApi.on(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_DRAWING, this._onDraw);

        this._resetPublisher = this._resetPublisher.bind(this);
        this.mediaService.onResetPublisher(this._resetPublisher);

        if (this.isCustomerMagicMarkerEnabled) {
            this.initDrawingCanvas(this.localDrawingCanvasElement.get(0));
        }
    }

    _resetPublisher() {
        if (this.mediaService.mediaServiceType !== MediaServiceType.TURNSERVER) {
            if (this.videoSubscriber) {
                this.onVideoSubscriberChanged && this.onVideoSubscriberChanged(null);
                this.mediaService.destroySubscriber(this.videoSubscriber.container);
                this.videoSubscriber = null;
            }

            this.initSubscriber();
        }
    }

    onDrawingCanvasStartDraw(e) {
        const {
            detail: {data}
        } = e;
        const {x, y} = this._getCursorPos(data);

        this.chatApi.setStatusFast(socketEvents.CLIENT_OUT_SET_STATUS.DRAWINGS, [], true);
        this.localDrawingCanvas.startDrawing(x, y);
    }

    onDrawingCanvasDraw(e) {
        if (this.localDrawingCanvas && this.localDrawingCanvas.isPainting) {
            const {
                detail: {data}
            } = e;
            const {x, y} = this._getCursorPos(data);

            this.localDrawingCanvas.addToDrawing(x, y, {
                width: this.localDrawingCanvasElement.get(0).width,
                height: this.localDrawingCanvasElement.get(0).height
            });
        }
    }

    onDrawingCanvasEndDraw() {
        if (!this.localDrawingCanvas.isPainting) {
            return;
        }

        const getDrawings = this.localDrawingCanvas.getDrawings();

        this.localDrawingCanvas.endDrawing();
        this.chatApi.setStatusFast(socketEvents.CLIENT_OUT_SET_STATUS.DRAWINGS, getDrawings, true);

        if (getDrawings.length) {
            this._eventService.sendEventLog(
                'none',
                this.chatApi.roomId || 'none',
                LOG_EVENTS.magicMarkerDrawingSent,
                null
            );
        }
    }

    /**
     * gets 'drawing-canvas' and init the listeners using client-infra helper
     * for local drawing - 3 processes - drawingCanvasStartDraw, drawingCanvasDraw, drawingCanvasEndDraw
     * @param {*} element = 'drawing-canvas'
     */
    initDrawingCanvas(element) {
        const canvasEventsHelper = new CanvasEvents();

        const controlButtons = document.querySelector('.video-chat-controls');
        const livePointer = document.querySelector('.live-pointer');
        const videoTitle = document.querySelector('.ts-state-title');
        const floatingElements = [controlButtons, livePointer, videoTitle];

        canvasEventsHelper.initListeners(element, this.isMobile, floatingElements);

        const drawingOptions = {
            lineWidth: get(this.chatApi, 'accountSettings.magicMarker.client.lineWidth'),
            lineWidthRefResolution: {width: 640, height: 480},
            strokeColorRGB: get(this.chatApi, 'accountSettings.magicMarker.client.strokeColorRgb')
        };
        const timeUntilDrawingStartFading = get(
            this.chatApi,
            'accountSettings.magicMarker.client.timeUntilDrawingStartFading'
        );

        this.localDrawingCanvas = new CanvasDrawer(element, drawingOptions, undefined, timeUntilDrawingStartFading);

        element.addEventListener('drawingCanvasStartDraw', (e) => this.onDrawingCanvasStartDraw(e));

        element.addEventListener('drawingCanvasDraw', (e) => this.onDrawingCanvasDraw(e));

        element.addEventListener('drawingCanvasEndDraw', () => this.onDrawingCanvasEndDraw());
    }

    _getCursorPos(e) {
        const ev = e.originalEvent;

        const pageX = get(e, 'touches[0].pageX') || get(ev, 'changedTouches[0].pageX') || e.pageX;
        const pageY = get(e, 'touches[0].pageY') || get(ev, 'changedTouches[0].pageY') || e.pageY;

        const x = (this._videoWidth * (pageX - this._frame.left)) / this._frame.width,
            y = (this._videoHeight * (pageY - this._frame.top)) / this._frame.height;

        return {
            x: Math.max(0, Math.min(x, this._videoWidth)),
            y: Math.max(0, Math.min(y, this._videoHeight)),
            originalX: pageX,
            originalY: pageY
        };
    }

    initSubscriber() {
        if (!this.videoSubscriber && this._container && this.streamType) {
            trace.info('Publisher Directive - creating subscriber');
            const subscriberParams = {
                container: this._container[0],
                streamType: this.streamType
            };

            this.mediaService.createSubscriber(subscriberParams).then((subscriber) => {
                this.videoSubscriber = subscriber;
                this.onVideoSubscriberChanged && this.onVideoSubscriberChanged(subscriber);
                subscriber.onStateChanged(this.updateFrame);
                this.updateFrame();
            });
        }
    }

    destroy() {
        this.localDrawingCanvasElement[0].removeEventListener('drawingCanvasStartDraw', this.onDrawingCanvasStartDraw);
        this.localDrawingCanvasElement[0].removeEventListener('drawingCanvasDraw', this.onDrawingCanvasDraw);
        this.localDrawingCanvasElement[0].removeEventListener('drawingCanvasEndDraw', this.onDrawingCanvasEndDraw);
        this.$window.removeEventListener('resize', this.updateFrame);
        this.chatApi.off(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_CURSOR, this._onCursor);
        this.chatApi.off(socketEvents.CLIENT_IN_CHAT_API.DASHBOARD_DRAWING, this._onDraw);
        this.onVideoSubscriberChanged && this.onVideoSubscriberChanged(null);
        this.mediaService.destroySubscriber(this.videoSubscriber.container);
        this.mediaService.removeResetPublisherListener(this._resetPublisher);
        this.videoSubscriber = null;
        this._container.hide();
        this._pointer.hide();
    }

    toggleCursor() {
        this._updateCursor(this._lastCursorData);
    }

    _updateCursor(cursor) {
        this._lastCursorData = cursor;

        if (!cursor || !this.displayCursor) {
            return this._pointer.hide();
        }

        const horisontMultiplier = cursor.image.width / this._frame.width;
        const vertMultiplier = cursor.image.height / this._frame.height;

        const updatedCursor = {
            x: cursor.x / horisontMultiplier,
            y: cursor.y / vertMultiplier
        };

        this._pointer
            .css({
                left: Math.round(updatedCursor.x) + 'px',
                top: Math.round(updatedCursor.y) + 'px'
            })
            .show();
    }

    _updateCanvas(_, data) {
        if (data.length) {
            this.remoteDrawingCanvas.drawPoints(data, {
                width: this.localDrawingCanvasElement.get(0).width,
                height: this.localDrawingCanvasElement.get(0).height
            });
        }
    }

    //TODO - Alex: make optimization to this functions. We have functions in dashboard that can be reused for calculations.
    updateFrame() {
        if (!this.videoSubscriber || !this.videoSubscriber.isPlaying) {
            this._container.css('display', 'none');

            return;
        }

        this._container.css('display', '');

        const videoPublisherElement = this._element[0];

        this._containerWidth = (videoPublisherElement && videoPublisherElement.clientWidth) || this.$window.innerWidth;
        this._containerHeight =
            (videoPublisherElement && this.isMinimized && videoPublisherElement.clientHeight) ||
            this.$window.innerHeight;

        if (this._container) {
            const rootEl = $(this._container.find('.OT_root')[0] || this._element.find('video')[0]);

            rootEl.width(this._containerWidth);
            rootEl.height(this._containerHeight);

            this._container.width(this._containerWidth).height(this._containerHeight);
        }

        const rootEl = $(this._container.find('.OT_root')[0] || this._element.find('video')[0]);

        if (!rootEl.length) {
            return;
        }

        this.fitMode = 'contain';

        rootEl[0].style.objectFit = this.isMinimized ? 'cover' : this.fitMode;

        this._videoHeight = this.videoSubscriber.mediaElement.videoHeight;
        this._videoWidth = this.videoSubscriber.mediaElement.videoWidth;

        this._frame = assign({}, rootEl[0].getBoundingClientRect());

        if (this.isMinimized || this.fitMode !== 'contain') {
            assign(this._frame, {
                top: 0,
                left: 0,
                width: this._containerWidth,
                height: this._containerHeight
            });
        } else {
            if (this._containerWidth / this._containerHeight > this._videoWidth / this._videoHeight) {
                this._frame.width = (this._containerHeight * this._videoWidth) / this._videoHeight;
                this._frame.height = this._containerHeight;
            } else {
                this._frame.width = this._containerWidth;
                this._frame.height = (this._containerWidth * this._videoHeight) / this._videoWidth;
            }

            this._frame.top = (this._containerHeight - this._frame.height) / 2;
            this._frame.left = (this._containerWidth - this._frame.width) / 2;
            this._container.width(this._frame.width).height(this._frame.height);
        }

        const setStyle = (el, zIndex = -1) => {
            assign(el.style, {
                width: this._frame.width + 'px',
                height: this._frame.height + 'px',

                top: this._frame.top + 'px',
                left: this._frame.left + 'px'
            });

            if (zIndex > 0) {
                el.style.position = 'absolute';
                el.style.zIndex = zIndex;
            }
        };

        setStyle(rootEl[0]);
        setStyle(this._container[0], 400);
        setStyle(this.annotationCanvasElement.get(0), 500);
        setStyle(this.localDrawingCanvasElement.get(0), 501);

        this.annotationCanvasElement.get(0).width = this._videoWidth;
        this.annotationCanvasElement.get(0).height = this._videoHeight;
        this.localDrawingCanvasElement.get(0).width = this._videoWidth;
        this.localDrawingCanvasElement.get(0).height = this._videoHeight;
    }
}

export function tsVideoPublisherDirective() {
    'ngInject';

    return {
        restrict: 'A',
        scope: {},
        bindToController: {
            mediaService: '=',
            isMinimized: '=',
            displayCursor: '=',
            streamType: '=',
            onVideoSubscriberChanged: '='
        },
        controller: TsVideoPublisherController,
        controllerAs: 'vm',
        link: linkFn
    };

    function linkFn(scope, element, attrs, ctrl) {
        ctrl.init(element);
        scope.$watch(
            () => ctrl.displayCursor,
            () => {
                ctrl.toggleCursor();
            }
        );

        scope.$on('$destroy', () => ctrl.destroy());
    }
}
