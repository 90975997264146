'use strict';

// Scrolling needs to be manually animated
const FRAME_RATE = 30;

/**
 * A simple directive that watches a collection and scrolls an element
 * to its bottom, whenever the number of items changes.
 * Used on the container of the collection.
 *
 * Attributes:
 *
 *  ts-scroll-down: Model to watch for new values
 */
class tsScrollDownController {
    constructor($element, $attrs, $scope, $interval) {
        'ngInject';
        this.$scope = $scope;
        this.$interval = $interval;
        // we maintain a reference to any started interval,
        // so that we can cancel it and avoid conflicts in
        // animation.
        this.currentInterval = null;
    }

    /*
     * Animates a value, from start to end, over a given duration and
     * feeds the current value to a callback. Used for setting scrollTop
     * to the element of the directive.
     *
     * @param startValue {Number} initial value
     * @param endValue {Number} final value
     * @param duration {Number} duration of the animation, in msec
     * @param callback {Function} function to call with `stepValue` parameter,
     *                            in every iteration.
     */
    iterativelyIncrease(startValue, endValue, duration, callback) {
        const distance = endValue - startValue;
        let repeats = (duration * FRAME_RATE) / 1000,
            stepValue = startValue;
        const step = distance / repeats;

        // We need to stop any running scroll, before adding the new one
        this.cancel();

        const interval = this.$interval(() => {
            stepValue += step;

            const correction = callback(stepValue);

            if (correction > 0) {
                repeats += Math.ceil(correction / stepValue);
            }

            repeats--;

            if (repeats <= 0) {
                this.$interval.cancel(interval);
            }
        }, FRAME_RATE);
    }

    cancel() {
        if (this.currentInterval) {
            this.$interval.cancel(this.currentInterval);
        }
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const container = element[0];

    scope.$watchCollection(
        () => ctrl.model,
        () => {
            const startValue = container.scrollTop;
            let endValue = container.scrollHeight;

            ctrl.iterativelyIncrease(startValue, endValue, 400, (stepValue) => {
                // there is a chance that the container will grow while scrolling
                // in which case we update the values and add the extra height
                // to the animation
                const correction = container.scrollHeight - endValue;

                endValue = container.scrollHeight;

                container.scrollTop = stepValue + correction;

                return correction;
            });
        }
    );

    scope.$on('$destroy', () => {
        ctrl.cancel();
    });
}

export function tsScrollDownDirective() {
    'ngInject';

    return {
        restrict: 'A',
        scope: {},
        bindToController: {
            model: '=tsScrollDown'
        },
        controller: tsScrollDownController,
        controllerAs: 'vm',
        link: linkFn
    };
}
