'use strict';

import uploadConfirmationView from './upload-confirmation.view.html';
import uploadConfirmationController from './upload-confirmation.controller';

export class uploadConfirmationModal {
    constructor($uibModal) {
        'ngInject';

        this.$uibModal = $uibModal;
        this.instance = null;
    }

    show(url) {
        if (this.instance) {
            return this._result();
        }

        this.instance = this.$uibModal.open({
            animation: false,
            backdrop: 'static',
            template: uploadConfirmationView,
            controller: uploadConfirmationController,
            controllerAs: 'vm',
            windowClass: 'upload-confirmation-modal',
            backdropClass: 'upload-confirmation-backdrop',
            resolve: {
                urlToConfirm: () => url
            }
        });

        return this._result();
    }

    close() {
        if (this.instance) {
            this.instance.dismiss();

            this.instance = null;
        }
    }

    _result() {
        return this.instance.result
            .then(() => true)
            .catch(() => false)
            .finally(() => {
                this.instance = null;
            });
    }
}
