import {EventEmitter} from 'events';
import includes from 'lodash/includes';

export class TsFullscreenGalleryService extends EventEmitter {
    openGallery(context, data) {
        this.emit(this.$events.OPEN_GALLERY, {context, data});
    }

    closeGallery(context, data) {
        this.emit(this.$events.CLOSE_GALLERY, {context, data});
    }

    isSupportedMsg(msg) {
        if (msg.type) {
            return includes(['IMG-DB', 'IMG-CL'], msg.type);
        }

        if (msg.data) {
            return msg.data.type === 'image';
        }

        return false;
    }

    get $events() {
        return {
            OPEN_GALLERY: 'OPEN_GALLERY',
            CLOSE_GALLERY: 'CLOSE_GALLERY'
        };
    }

    get $context() {
        return {
            NEW_MESSAGE_RECEIVED: 'NEW_MESSAGE_RECEIVED',
            MESSAGE_CLICKED: 'MESSAGE_CLICKED',
            CLOSE_TOGGLE_CLICK: 'CLOSE_TOGGLE_CLICK',
            VIDEO_TOGGLE_CLICK: 'VIDEO_TOGGLE_CLICK',
            SET_COMPLETED: 'SET_COMPLETED'
        };
    }
}
