import preCameraPermissionView from './pre-camera-permission.view.html';
import {getRootStore} from '../../_react_/app.bootstrap';

class PreCameraPermissionController {
    constructor(preCameraPermissionService, $rootScope) {
        'ngInject';

        this._rootScope = $rootScope;
        this.service = preCameraPermissionService;
        this.img = 'img/camera_checked_icon.png';

        this.cameraPreApprovalTitle = getRootStore().localizationService.translate('CAMERA_PREAPPROVAL.TITLE-1');
        this.cameraPreApprovalSubTitle = getRootStore().localizationService.translate(
            'CAMERA_PREAPPROVAL.PERMISSION-ASK'
        );
        this.cameraPreApprovalButtonText = getRootStore().localizationService.translate(
            'CAMERA_PREAPPROVAL.ENABLE_CAMERA'
        );
    }

    get _theme() {
        return this._rootScope.THEME || null;
    }

    get requireImage() {
        return this._rootScope.requireImage;
    }
}

export function PreCameraPermissionDirective() {
    'ngInject';

    return {
        template: preCameraPermissionView,
        replace: true,
        restrict: 'E',
        scope: {},
        bindToController: {},
        controller: PreCameraPermissionController,
        controllerAs: 'preCamera'
    };
}
