/* eslint-disable consistent-return */
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import assign from 'lodash/assign';
import get from 'lodash/get';
import includes from 'lodash/includes';
import {TechseeMediaServiceBase} from '@techsee/techsee-media-service/lib/MediaServiceBase';
import {
    ClientWebRtcInfo,
    LocalMediaConstraints,
    LocalVideoStreamConstraints,
    MediaRequestSuccessResult,
    MediaSessionParams,
    StreamCreatedEventArgs,
    VideoStreamResolution
} from '@techsee/techsee-media-service/lib/MediaContracts';
import {TechseeMediaPublisher} from '@techsee/techsee-media-service/lib/MediaPublisher';
import {Nullable} from '@techsee/techsee-common';
import {
    CameraTypes,
    DEFAULT_VIDEO_CONSTRAINTS,
    KnownMediaStream,
    LocalVideoSourceType,
    MediaServiceType,
    POSSIBLE_LOOPBACK_RESOLUTIONS,
    SessionClientRole
} from '@techsee/techsee-media-service/lib/MediaConstants';
import {ITsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';

import {getMediaTracer} from '@techsee/techsee-media-service/lib/MediaUtils/MediaTracer';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {
    DEVICES_FOR_LOOPBACK_LOW_RESOLUTION,
    privateEvents,
    TORCH_DELAY,
    VideoSourceEventArgs
} from './MediaService.settings';

const trace = getMediaTracer('MobileAppMediaService');

export interface IMobileAppMediaService {
    connectToSession(sessionParams?: MediaSessionParams): Promise<void>;
    pauseCameraStream(isPaused: boolean): void;
    pauseAudioStream(isPaused: boolean): void;
    setDesktopStreamSource(): void;
    setCameraStreamSource(resolution?: string, sourceType?: CameraTypes): void;
    setLoopbackCameraSource(device: string): void;
    currentVideoSourceType: Nullable<LocalVideoSourceType>;
    currentCameraType: Nullable<CameraTypes>;
    clearService(): Promise<void>;
    onAudioStreamFailed(callback: () => void): void;
    onSwitchCameraFailed(callback: (eventArgs: VideoSourceEventArgs) => void): void;
    onSwitchCameraSuccess(callback: (eventArgs: VideoSourceEventArgs) => void): void;
    onResetPublisher(callback: () => void): void;
    removeResetPublisherListener(callback: () => void): void;
    onSyncVoip(callback: () => void): void;
    onStartSwitchCamera(callback: () => void): void;
    onDesktopSharingTypeSet(callback: (eventArgs: {streamScreenType: string}) => void): void;
    isFrontCamera: boolean;
    turnFlashlight(isTorchOn?: boolean): Promise<boolean>;
    setAutoLightOffTimer(flashlightTimeout: Nullable<number>): Promise<void>;
    switchSourceCamera(): void;
}
export class MobileAppMediaService extends TechseeMediaServiceBase implements IMobileAppMediaService {
    private _currentVideoSource: Nullable<LocalMediaConstraints> = null;
    private _torchDelayTimeOut: Nullable<ReturnType<typeof setTimeout>> = null;
    private _torchOffTimeOut: Nullable<ReturnType<typeof setTimeout>> = null;
    private _tsBrowserUtilsService: IBrowserUtilsService;
    private _refreshWhenVideoStreamCreatedHack: boolean;
    private _sessionParamToConnect: Nullable<MediaSessionParams> = null;
    private _audioStreamCreatedSuccessfully = false;

    constructor(
        environment: ITsEnvironmentDetect,
        webRtcSupportInfo: ClientWebRtcInfo,
        tsBrowserUtilsService: IBrowserUtilsService
    ) {
        super(environment, webRtcSupportInfo);

        this.initMediaServerHandlers = this.initMediaServerHandlers.bind(this);
        this.removeMediaServerHandlers = this.removeMediaServerHandlers.bind(this);

        this._tsBrowserUtilsService = tsBrowserUtilsService;

        const osVersion = environment.isIOS() && environment.os();

        // eslint-disable-next-line radix
        this._refreshWhenVideoStreamCreatedHack = !!(
            osVersion &&
            osVersion.version &&
            parseInt(osVersion.version, 10) >= 14
        );

        this.onStreamCreated((eventArgs: StreamCreatedEventArgs) => {
            if (eventArgs.streamType === KnownMediaStream.USER_AUDIO_STREAM) {
                this.pauseAudioStream(true);
            }
        });
    }

    get mediaServiceType() {
        return this._serviceOptions && this._serviceOptions.mediaServiceType;
    }

    connectToSession(sessionParams?: MediaSessionParams): Promise<void> {
        const sessionParamToSend = cloneDeep(sessionParams) || this._sessionParamToConnect;

        if (this._serviceOptions && this._serviceOptions.mediaServiceType === MediaServiceType.MEDIASERVER) {
            assign(sessionParamToSend, {
                initHandlers: this.initMediaServerHandlers,
                removeHandlers: this.removeMediaServerHandlers
            });
        }

        this._sessionParamToConnect = sessionParamToSend;

        if (!sessionParamToSend) {
            throw new Error('sessionParamToSend should not be empty');
        }

        return super.connectToSession(sessionParamToSend);
    }

    pauseCameraStream(isPaused: boolean) {
        this.changeEnableForKnownStream(KnownMediaStream.USER_VIDEO_STREAM, isPaused);
    }

    pauseAudioStream(isPaused: boolean) {
        this.changeEnableForKnownStream(KnownMediaStream.USER_AUDIO_STREAM, isPaused);
    }

    setDesktopStreamSource() {
        const screenShareResolution: VideoStreamResolution = DEFAULT_VIDEO_CONSTRAINTS;

        this.setVideoPublishingSource({
            videoResolution: [screenShareResolution],
            videoSourceType: LocalVideoSourceType.DESKTOP_SHARE
        });
    }

    setCameraStreamSource(resolution?: string, sourceType?: CameraTypes) {
        trace.info('set camera stream source. resolution: ', resolution);
        const cameraResolution: VideoStreamResolution = DEFAULT_VIDEO_CONSTRAINTS;

        cameraResolution.resolution = resolution ? resolution : cameraResolution.resolution;
        trace.info('set camera stream source. resolution: ', cameraResolution.resolution);

        const videoSourceType =
            this.currentVideoSourceType ||
            (sourceType === CameraTypes.FRONT ? LocalVideoSourceType.CAMERA_FRONT : LocalVideoSourceType.CAMERA);

        this.setVideoPublishingSource({videoResolution: [cameraResolution], videoSourceType});
    }

    setLoopbackCameraSource(device: string) {
        let cameraResolution: VideoStreamResolution[] = POSSIBLE_LOOPBACK_RESOLUTIONS;

        if (includes(DEVICES_FOR_LOOPBACK_LOW_RESOLUTION, device)) {
            cameraResolution = [DEFAULT_VIDEO_CONSTRAINTS];
        }

        this.setVideoPublishingSource({
            videoResolution: cameraResolution,
            videoSourceType: LocalVideoSourceType.CAMERA
        });
    }

    get currentVideoSourceType() {
        return (
            this._tsBrowserUtilsService.getFromSessionStorage('videoSourceType') ||
            get(this._currentVideoSource, 'video.videoSourceType')
        );
    }

    get currentCameraType() {
        return this.currentVideoSourceType === LocalVideoSourceType.CAMERA_FRONT ? CameraTypes.FRONT : CameraTypes.BACK;
    }

    clearService(): Promise<void> {
        return super.clearService().then(() => {
            this._currentVideoSource = null;

            if (this._torchOffTimeOut) {
                clearTimeout(this._torchOffTimeOut);
                this._torchOffTimeOut = null;
            }

            if (this._torchDelayTimeOut) {
                clearTimeout(this._torchDelayTimeOut);
                this._torchDelayTimeOut = null;
            }
        });
    }

    private setVideoPublishingSource(video: LocalVideoStreamConstraints) {
        trace.info('setVideoPublishingSource');
        if (isEqual({video}, this._currentVideoSource)) {
            return;
        }

        if (this.isLocalStreamInitialized) {
            const error = 'Publishing stream cannot be changed after local streams were initialized.';

            trace.error(error);

            throw new Error(error);
        }

        if (this._currentVideoSource) {
            const error = 'Publishing stream cannot be changed after it was set.';

            trace.error(error);

            throw new Error(error);
        }

        this._currentVideoSource = {video};
    }

    protected isAudioStreamCreated() {
        return !!this.getRegisteredStreamByType(KnownMediaStream.USER_AUDIO_STREAM);
    }

    protected createMediaPublisher(destinationRole: SessionClientRole): Promise<Nullable<TechseeMediaPublisher>> {
        if (!this._currentVideoSource) {
            trace.warn('getLocalMediaImplementation: unexpected use case _currentVideoSource is null.');

            return Promise.resolve(null);
        }

        const videoType =
            this.currentVideoSourceType === LocalVideoSourceType.DESKTOP_SHARE
                ? KnownMediaStream.USER_SCREEN_SHARE_STREAM
                : KnownMediaStream.USER_VIDEO_STREAM;

        return Promise.all([
            this.getRegisteredStreamByType(videoType),
            this.getRegisteredStreamByType(KnownMediaStream.USER_AUDIO_STREAM)
        ]).then(([videoStream, audioStream]) => {
            const streamTypes: KnownMediaStream[] = [];
            const tracks: MediaStreamTrack[] = [];

            if (videoStream) {
                tracks.push(videoStream.mediaTrack);
                streamTypes.push(videoType);
            }

            if (audioStream) {
                tracks.push(audioStream.mediaTrack);
                streamTypes.push(KnownMediaStream.USER_AUDIO_STREAM);
            }

            if (tracks.length > 0) {
                return new TechseeMediaPublisher({destinationRole: destinationRole, streamTypes: streamTypes}, tracks);
            }

            return null;
        });
    }

    onAudioStreamFailed(callback: () => void) {
        this.registerEventCallback(privateEvents.AUDIO_STREAM_FAILED, callback);
    }

    onSwitchCameraFailed(callback: (eventArgs: VideoSourceEventArgs) => void) {
        this.registerEventCallback(privateEvents.SWITCH_CAMERA_FAILED, callback);
    }

    onSwitchCameraSuccess(callback: (eventArgs: VideoSourceEventArgs) => void) {
        this.registerEventCallback(privateEvents.SWITCH_CAMERA_SUCCESS, callback);
    }

    onResetPublisher(callback: () => void) {
        this.registerEventCallback(privateEvents.RESET_PUBLISHER, callback);
    }

    removeResetPublisherListener(callback: () => void) {
        this.unregisterEventCallback(privateEvents.RESET_PUBLISHER, callback);
    }

    onSyncVoip(callback: () => void) {
        this.registerEventCallback(privateEvents.SYNC_VOIP, callback);
    }

    onStartSwitchCamera(callback: () => void) {
        this.registerEventCallback(privateEvents.START_SWITCH_CAMERA, callback);
    }

    onDesktopSharingTypeSet(callback: (eventArgs: {streamScreenType: string}) => void) {
        this.registerEventCallback(privateEvents.DESKTOP_SHARE_TYPE_SET, callback);
    }

    get isFrontCamera() {
        return this.currentCameraType === CameraTypes.FRONT;
    }

    private emitSwitchCameraSuccessEvents(revertCameraWhenFailed: boolean) {
        this.emitEvent(
            revertCameraWhenFailed ? privateEvents.SWITCH_CAMERA_FAILED : privateEvents.SWITCH_CAMERA_SUCCESS,
            {videoSourceType: this.currentCameraType, audioCreated: this._audioStreamCreatedSuccessfully}
        );
        this.emitEvent(privateEvents.RESET_PUBLISHER);

        if (this._audioStreamCreatedSuccessfully) {
            this.emitEvent(privateEvents.SYNC_VOIP);
        }
    }

    private emitSwitchCameraFailedEvents() {
        this.emitEvent(privateEvents.SWITCH_CAMERA_FAILED, {
            videoSourceType: this.currentCameraType,
            audioCreated: this._audioStreamCreatedSuccessfully
        });
        this.emitEvent(privateEvents.RESET_PUBLISHER);
    }

    mediaServerSwitchCamera(revertCameraWhenFailed?: boolean) {
        let constraints;

        this.emitEvent(privateEvents.START_SWITCH_CAMERA);

        if (!revertCameraWhenFailed) {
            constraints = this.getSwitchCameraConstraints();
        }

        const videoSourceType = get(constraints, 'video.videoSourceType') || this.currentVideoSourceType;
        const isVoipEnabled = this.isVoipEnabled;

        return this.clearService()
            .then(() => {
                if (isVoipEnabled) {
                    this.enableVoipDuringSession();
                }

                this._tsBrowserUtilsService.saveToSessionStorage('videoSourceType', videoSourceType);
                this.setCameraStreamSource();

                return this.initLocalMediaStreams()
                    .then(() => this.connectToSession())
                    .then(() => this.emitSwitchCameraSuccessEvents(!!revertCameraWhenFailed));
            })
            .catch((err: any) => {
                if (revertCameraWhenFailed) {
                    trace.info('switchSourceCamera: Second switch: ', err);
                    this.emitSwitchCameraFailedEvents();

                    return;
                }

                this._tsBrowserUtilsService.saveToSessionStorage(
                    'videoSourceType',
                    videoSourceType === LocalVideoSourceType.CAMERA_FRONT
                        ? LocalVideoSourceType.CAMERA
                        : LocalVideoSourceType.CAMERA_FRONT
                );
                this.mediaServerSwitchCamera(true);
            });
    }

    switchSourceCamera() {
        if (this.mediaServiceType !== MediaServiceType.TURNSERVER) {
            return this.mediaServerSwitchCamera();
        }

        return this.switchCamera()
            .then((res: any) => {
                if (this._torchDelayTimeOut) {
                    clearTimeout(this._torchDelayTimeOut);
                    this._torchDelayTimeOut = null;
                }

                if (res.constraints && res.constraints.audio && !res.streamResult.constraint.audio) {
                    this.emitEvent(privateEvents.AUDIO_STREAM_FAILED);
                }

                const audioCreated = res.constraints && res.constraints.audio && res.streamResult.constraint.audio;

                this.saveCameraSource(get(res, 'constraints.video.videoSourceType'));
                trace.info(`switchSourceCamera: SUCCESS to switch to: ${this.currentCameraType}`);

                this.emitEvent(
                    res.revertCameraWhenFailed
                        ? privateEvents.SWITCH_CAMERA_FAILED
                        : privateEvents.SWITCH_CAMERA_SUCCESS,
                    {videoSourceType: this.currentCameraType, audioCreated}
                );
            })
            .catch((err: any) => {
                trace.info('switchSourceCamera: Second switch: ', err);
                this.emitEvent(privateEvents.SWITCH_CAMERA_FAILED, {videoSourceType: this.currentCameraType});
            });
    }

    private saveCameraSource(cameraSourceType?: LocalVideoSourceType) {
        if (cameraSourceType && this._currentVideoSource) {
            (this._currentVideoSource.video as LocalVideoStreamConstraints).videoSourceType = cameraSourceType;
            this._tsBrowserUtilsService.saveToSessionStorage('videoSourceType', cameraSourceType);
        }
    }

    protected getLocalMediaImplementation(): Promise<void> {
        if (!this._currentVideoSource) {
            return Promise.reject('getLocalMediaImplementation: unexpected use case _currentVideoSource is null.');
        }

        if (
            this.currentVideoSourceType === LocalVideoSourceType.CAMERA ||
            this.currentVideoSourceType === LocalVideoSourceType.CAMERA_FRONT
        ) {
            trace.info(
                `get local media implementation. local video source type: ${this.currentVideoSourceType}. isVoipEnabled: ${this.isVoipEnabled}`
            );
            const constraints = {video: cloneDeep(this._currentVideoSource.video), audio: this.isVoipEnabled};

            return this._localStreamsManager
                .getUserMediaStream(constraints)
                .then((streamResult: MediaRequestSuccessResult) => {
                    trace.info('stream result from getUserMediaStream:', streamResult);
                    if (constraints && constraints.audio && !streamResult.constraint.audio) {
                        this.emitEvent(privateEvents.AUDIO_STREAM_FAILED);
                    }

                    if (constraints && constraints.audio && streamResult.constraint.audio) {
                        this._audioStreamCreatedSuccessfully = true;
                    } else {
                        this._audioStreamCreatedSuccessfully = false;
                    }

                    return this.registerStreamResult(constraints, streamResult).then(() => undefined);
                });
        } else if (this.currentVideoSourceType === LocalVideoSourceType.DESKTOP_SHARE) {
            trace.info(
                `get local media implementation. local video source type: ${LocalVideoSourceType.DESKTOP_SHARE}.`
            );
            let constraints: boolean | LocalVideoStreamConstraints = cloneDeep(this._currentVideoSource.video);

            if (typeof constraints === 'boolean') {
                constraints = cloneDeep({videoResolution: [DEFAULT_VIDEO_CONSTRAINTS]});
            }

            return this._localStreamsManager
                .getDesktopMediaStream(constraints)
                .then((streamResult: MediaRequestSuccessResult) => {
                    let streamScreenType = 'Unknown';

                    try {
                        const screenSharingType = streamResult.mediaStream.getVideoTracks();

                        streamScreenType = screenSharingType[0].label;
                    } catch (err) {
                        trace.error('screenSharingType: failed getting streamResult videoTracks', err);
                    }

                    this.emitEvent(privateEvents.DESKTOP_SHARE_TYPE_SET, {streamScreenType});
                    trace.info('stream result from getDesktopMediaStream:', streamResult);

                    // eslint-disable-next-line max-len
                    return this.registerStreamResult(
                        {video: constraints, audio: false},
                        streamResult,
                        false,
                        KnownMediaStream.USER_SCREEN_SHARE_STREAM
                    ).then(() => undefined);
                });
        }

        return Promise.resolve();
    }

    turnFlashlight(isTorchOn = true): Promise<boolean> {
        return new Promise((resolve) => {
            if (this._torchDelayTimeOut) {
                return resolve(true);
            }

            const userStream = this.getRegisteredStreamByType(KnownMediaStream.USER_VIDEO_STREAM);
            let track: Nullable<MediaStreamTrack> = null;

            if (userStream) {
                track = userStream.mediaTrack;
            }

            if (!track) {
                trace.info('flashlight: stream has no video track');

                return resolve(false);
            }

            if (this._torchOffTimeOut) {
                clearTimeout(this._torchOffTimeOut);
                this._torchOffTimeOut = null;
            }

            this._torchDelayTimeOut = setTimeout(() => {
                let capatibilitiesAfter: any = {};

                try {
                    capatibilitiesAfter = track!.getCapabilities();
                } catch (e) {
                    trace.warn('flashlight: failed getting capabilities');
                    this._torchDelayTimeOut = null;

                    return resolve(false);
                }

                if (!capatibilitiesAfter.torch) {
                    trace.info('flashlight: no torch in capability');
                    this._torchDelayTimeOut = null;

                    return resolve(false);
                }

                return track!
                    .applyConstraints({
                        advanced: [
                            {
                                torch: isTorchOn
                            } as any
                        ]
                    })
                    .then(() => {
                        trace.info('flashlight: apply flashlight successfully');
                        this._torchDelayTimeOut = null;
                        resolve(true);
                    })
                    .catch((e: any) => {
                        this._torchDelayTimeOut = null;
                        trace.warn('flashlight: failed enabled flashlight: ', e);
                        resolve(false);
                    });
            }, TORCH_DELAY);
        });
    }

    setAutoLightOffTimer(flashlightTimeout: Nullable<number>): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!flashlightTimeout) {
                return reject('No timeout');
            }

            const userStream = this.getRegisteredStreamByType(KnownMediaStream.USER_VIDEO_STREAM);
            let track: Nullable<MediaStreamTrack> = null;

            if (userStream) {
                track = userStream.mediaTrack;
            }

            if (!track) {
                return reject('No track');
            }

            this._torchOffTimeOut = setTimeout(() => {
                if (!track) {
                    return reject('No track');
                }

                track
                    .applyConstraints({
                        advanced: [
                            {
                                torch: false
                            } as any
                        ]
                    })
                    .then(() => {
                        this._torchOffTimeOut = null;
                        resolve();
                    })
                    .catch((err) => {
                        this._torchOffTimeOut = null;
                        reject(err);
                    });
            }, flashlightTimeout);
        });
    }

    private initMediaServerHandlers() {
        // unload event is deprecated in IOS, using pagehide instead:
        // eslint-disable-next-line max-len
        // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html#//apple_ref/doc/uid/TP40006511-SW5
        window.addEventListener('pagehide', this.disconnectFromMediaSession);
    }

    private removeMediaServerHandlers() {
        window.removeEventListener('pagehide', this.disconnectFromMediaSession);
    }
}
