'use strict';

// The `unsupported` state is basically the same as the `end` state, with a different message.
export class UnsupportedController {
    constructor($stateParams) {
        'ngInject';

        this.showUnsupportedBrowser = $stateParams.isUnsupportedBrowser;
    }
}
