import {MeetingState} from '@techsee/techsee-common/lib/constants/meeting.states.definition';

export enum MeetingEvent {
    MeetingHandshakeRequest = 'MeetingHandshakeRequest',
    MeetingSyncRequest = 'MeetingSyncRequest',
    StateConstructionComplete = 'StateConstructionComplete',
    StateHandShakeResult = 'StateHandShakeSuccess'
}

export enum ModeHandShakeFailReasons {
    MEDIA_STREAM_FAILURE = 'MEDIA_STREAM_FAILURE',
    TERMS_REJECTED = 'TERMS_REJECTED'
}

export enum ModeHandShakeErrorCodes {
    PERMISSION_DENIED = 'PermissionDenied'
}

export interface ModeHandShakeError {
    isHandshakeSuccess: boolean;
    meetingState: MeetingState;
    reason: ModeHandShakeFailReasons;
    statusMessage: string;
    error: any;
}
