import {inject, IReactComponent} from 'mobx-react';
import * as view from './view';

const mapStoresToProps = (stores: any) =>
    ({
        translate: stores.translate,
        requireImage: stores.requireImage,
        mobileBranding: stores.brandingData
    }) as view.IEndTopViewProps;

const EndTopComponent = inject(mapStoresToProps)(view.EndTopView) as IReactComponent;

export default EndTopComponent;
