import {CAMERA_ACCESS, ROOM_MODES, STATUS_MESSAGES} from '../../../states/meeting/meeting.settings';
import get from 'lodash/get';
import {IUser} from './interfaces/AngularInterfaces';
import * as socketEvents from '@techsee/techsee-common/lib/socket/client';

export class FacemeetController {
    private _tsChatApi: any;

    private _db: any;

    private roomId: string;

    private _tsEventService: any;

    private _currentUser: IUser;

    constructor(tsChatApi: any, db: any, roomId: string, currentUser: IUser, tsEventService: any) {
        this._tsChatApi = tsChatApi;
        this._db = db;
        this.roomId = roomId;
        this._tsEventService = tsEventService;
        this._currentUser = currentUser;
    }

    faceMeetMediaPermissionFailed = () => {
        this._db.Rooms.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'cameraAccess',
                    value: CAMERA_ACCESS.CAMERA_REJECTED
                }
            }
        });
        this._tsEventService.sendEventLog(
            get(this._currentUser, '_id'),
            this._tsChatApi.roomId,
            'MEDIA_PERMISSION_REJECTED',
            {description: 'Customer rejected permission access', origin: ROOM_MODES.faceMeet}
        );

        this._tsChatApi.sendLog(STATUS_MESSAGES.MEDIA_PERMISSION_REJECTED);
        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.MEDIA_PERMISSIONS_ACCEPTED, false);
        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.MEDIA_PERMISSIONS_REJECTED, true);
    };

    faceMeetMediaPermissionSuccess = () => {
        this._tsChatApi.cameraApprovalDialogStateChange(false);
        this._tsChatApi.sendLog(STATUS_MESSAGES.MEDIA_PERMISSION_ALLOW);

        this._tsEventService.sendEventLog(
            get(this._currentUser, '_id'),
            this._tsChatApi.roomId,
            STATUS_MESSAGES.MEDIA_PERMISSION_ALLOW,
            {description: 'Customer approved permission access', origin: ROOM_MODES.faceMeet}
        );

        this._db.Rooms.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'cameraAccess',
                    value: CAMERA_ACCESS.CAMERA_APPROVED
                }
            }
        });

        this._db.Rooms.setReportedField(this.roomId, {
            data: {
                event: {
                    key: 'streamingIntent',
                    value: ROOM_MODES.faceMeet,
                    type: 'push'
                }
            }
        });

        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.MEDIA_PERMISSIONS_ACCEPTED, true);
        this._tsChatApi.setStatus(socketEvents.CLIENT_OUT_SET_STATUS.MEDIA_PERMISSIONS_REJECTED, false);
    };

    faceMeetMediaPermission = (data: any): void => {
        if (data?.mediaDeviceAccessDenied) {
            this.faceMeetMediaPermissionFailed();

            return;
        }

        this.faceMeetMediaPermissionSuccess();
    };
}
