import React from 'react';
import {observer} from 'mobx-react';

import {SURVEY_ANSWER_MAX_LENGTH} from '@techsee/techsee-common/lib/constants/room.constants';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {
    ButtonTypeAttributes,
    GenericButton,
    ButtonTypes,
    ButtonSizes
} from '@techsee/techsee-ui-common/lib/forms/buttons/generic';
import {FormInput} from '@techsee/techsee-ui-common/lib/forms/input';
import {Rating} from '@techsee/techsee-ui-common/lib/rating';

import {ISurveyController} from './controller';
import EndTopComponent from '../top';
import './styles.scss';

export interface ISurveyViewProps {
    ctrl: ISurveyController;
    translate: any;
    requireImage: any;
}

@observer
export class SurveyView extends TechseeBaseComponent<ISurveyViewProps> {
    renderInternal() {
        const {ctrl, translate, requireImage} = this.props;

        if (!ctrl.questions) {
            return (
                <div className='survey survey-disabled'>
                    <EndTopComponent theme={ctrl.theme} themePrefix={ctrl.themePrefix} />
                    <div className='survey-feedback'></div>
                </div>
            );
        }

        const skip = (
            <div className='survey-skip' onClick={() => ctrl.redirect(true)}>
                {translate('REACT.END.VIEW.SURVEY_SKIP')}
            </div>
        );

        if (ctrl.feedbackConfirmation) {
            if (ctrl.rating === 5) {
                return (
                    <div className='survey'>
                        <EndTopComponent theme={ctrl.theme} themePrefix={ctrl.themePrefix} />
                        <div className='survey-feedback survey-feedback-confirm'>
                            <img src={requireImage('survey-done.png')} alt='done' />
                            <h3>{translate('REACT.END.VIEW.SURVEY_DONE_GREAT')}</h3>
                            <p>{translate('REACT.END.VIEW.SURVEY_SENT_GREAT')}</p>
                            {skip}
                        </div>
                    </div>
                );
            }

            return (
                <div className='survey'>
                    <EndTopComponent theme={ctrl.theme} themePrefix={ctrl.themePrefix} />
                    <div className='survey-feedback survey-feedback-confirm'>
                        <img src={requireImage('survey-done.png')} alt='done' />
                        <h3>{translate('REACT.END.VIEW.SURVEY_DONE')}</h3>
                        <p>{translate('REACT.END.VIEW.SURVEY_SENT')}</p>
                        {skip}
                    </div>
                </div>
            );
        }

        return (
            <div className='survey'>
                <EndTopComponent theme={ctrl.theme} themePrefix={ctrl.themePrefix} />
                <div className='survey-feedback'>
                    <h3>{translate('REACT.END.VIEW.SURVEY_REQUEST')}</h3>
                    <p>{ctrl.questions.askRating}</p>
                    <Rating
                        className='survey-rating'
                        value={ctrl.rating}
                        onChange={(rating: number) => ctrl.setRating(rating)}
                    />
                    {ctrl.showFeedbackForm && (
                        <div className='survey-form'>
                            <p>{ctrl.questions.lowRatingFeedback}</p>
                            <form onSubmit={(event) => ctrl.submitFeedback(event)}>
                                <FormInput
                                    uid={'survey-answer'}
                                    componentClass={'textarea'}
                                    model={ctrl.form.fields.answer}
                                    maxLength={SURVEY_ANSWER_MAX_LENGTH}
                                />
                                <GenericButton
                                    className='survey-submit'
                                    buttonSize={ButtonSizes.Large}
                                    type={ButtonTypeAttributes.Submit}
                                    buttonType={ButtonTypes.Primary}>
                                    {translate('REACT.END.VIEW.SURVEY_FORM_SUBMIT')}
                                </GenericButton>
                            </form>
                        </div>
                    )}
                    {skip}
                </div>
            </div>
        );
    }
}

export default SurveyView;
