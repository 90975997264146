import SurveyController from './components/survey/controller';
import {IBrandingService} from '../../services/ts-branding-service/ts-branding-service';
import {IBrowserUtilsService} from '@techsee/techsee-client-infra/lib/services/BrowserUtilsService';
import {getRootStore} from '../../app.bootstrap';
import {ILocalizationService} from '../../services/LocalizationService';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IEndStores {
    [name: string]: object;
}

export class EndStores {
    stores?: IEndStores;

    private _rootScope: any;
    private _stateParams: any;
    private _tsStateHelper: any;
    private _translate: ILocalizationService;
    private _db: any;
    private _tsChatApi: any;
    private _tsTermsAndConditions: any;
    private _tsEnvironmentDetect: any;
    private _brandingService: IBrandingService;
    private _tsBrowserUtilsService: IBrowserUtilsService;

    //We use this type of injection, because ng-annotate-loader not runs on TypeScript files.
    static $inject = ['$scope', '$rootScope', '$stateParams', 'tsStateHelper', '$localStorage', 'db'];

    constructor($scope: any, $rootScope: any, $stateParams: any, tsStateHelper: any, $localStorage: any, db: any) {
        this._rootScope = $rootScope;
        this._stateParams = $stateParams;
        this._tsStateHelper = tsStateHelper;
        this._translate = getRootStore().localizationService;
        this._db = db;
        this._tsChatApi = getRootStore().chatApi;
        this._tsTermsAndConditions = getRootStore().termsAndConditionsController;
        this._tsEnvironmentDetect = getRootStore().environmentDetect;
        this._brandingService = getRootStore().brandingService;
        this._tsBrowserUtilsService = getRootStore().browserUtilsService;

        this.initStores().then((stores) => {
            $scope.$apply(() => (this.stores = stores));
        });
    }

    private initStores(): PromiseLike<IEndStores> {
        return new Promise((resolve) => {
            const stores: IEndStores = {};

            const surveyController = new SurveyController(
                this._rootScope,
                this._stateParams,
                this._tsStateHelper,
                this._translate,
                this._db,
                this._tsChatApi,
                this._tsTermsAndConditions
            );

            defineStore('surveyController', surveyController);
            defineStore('translate', this._translate.translate);
            defineStore('requireImage', this._rootScope.requireImage);
            defineStore('tsEnvironmentDetect', this._tsEnvironmentDetect);
            defineStore('stateParams', this._stateParams);
            defineStore('brandingData', this._brandingService.getBrandingData());
            resolve(stores);

            /*
             Because of technical issue with Angular digest loop,
             We define stores as non enumerable properties. This helps to avoid infinite digest of angular.
            */
            function defineStore(storeName: string, value: any) {
                Object.defineProperty(stores, storeName, {
                    value: value,
                    enumerable: false
                });
            }
        });
    }
}
