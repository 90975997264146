/**
 * Utility service that wraps the functionality required to re-orient and
 * resize a video
 */

export interface ITsPushNotificationService {
    needPermission: boolean;
    hasPermission: boolean;
    notification: Notification | null;
    getPermission(): Promise<void>;
    notify(text: string): void;
}
export class TsPushNotificationService {
    needPermission: boolean;
    hasPermission: boolean;
    notification: Notification | null;
    window: any;

    constructor(window: any) {
        this.window = window;
        this.needPermission = true;
        this.hasPermission = false;
        this.notification = null;
    }

    getPermission(): Promise<void> {
        if (!this.needPermission) {
            return Promise.resolve();
        }

        if (!this.window.Notification || this.window.Notification.permission === 'denied') {
            this.hasPermission = false;
            this.needPermission = false;
            return Promise.resolve();
        }

        if (this.window.Notification.permission === 'granted') {
            this.hasPermission = true;
            this.needPermission = false;
            return Promise.resolve();
        }

        let requestPermission = this.window.Notification.requestPermission();

        if (!(requestPermission instanceof Promise)) {
            requestPermission = new Promise((resolve) => this.window.Notification.requestPermission(resolve));
        }

        return requestPermission.then((permission: string) => {
            if (permission === 'granted') {
                this.hasPermission = true;
                this.needPermission = false;
            } else if (permission === 'denied') {
                this.hasPermission = false;
                this.needPermission = false;
            }
        });
    }

    notify(text: string): void {
        if (!this.hasPermission) {
            return;
        }

        const browserLocale = navigator.language.split('-')[0];

        //TODO - add translations
        const notificationMessage =
            browserLocale.toLowerCase() === 'fr' ? 'Message de TechSee' : 'Message from TechSee';

        this.notification = new this.window.Notification(text, {body: notificationMessage});
    }
}
