'use strict';

import filter from 'lodash/filter';
import get from 'lodash/get';
import {getRootStore} from '../../../_react_/app.bootstrap';

export class ExpertController {
    constructor(tsStateHelper, currentUser, accountData, csiSettings, db, patternsConstant, ROLES) {
        'ngInject';

        this.stateHelper = tsStateHelper;
        this.currentUser = currentUser;
        this.accountData = accountData;
        this.csiSettings = csiSettings;
        this.isCSI = !currentUser && csiSettings;
        this.db = db;
        this.phonePattern = patternsConstant.phone;
        this.roles = ROLES;

        this.voiceCallFromGroup =
            !this.isCSI &&
            this.accountData.settings &&
            this.accountData.settings.fsSettings &&
            this.accountData.settings.fsSettings.voiceCallFromGroup;
        this.voiceCallAddressBook =
            (this.isCSI && this.csiSettings.voiceCallAddressBook) ||
            get(this.accountData, 'settings.fsSettings.voiceCallAddressBook');

        this.groupPeers = [];
        this.expertViewTypePhoneTranslate =
            getRootStore().localizationService.translate('START.EXPERT.VIEW.TYPE_PHONE');
        this.expertViewChooseTypePhoneTranslate = getRootStore().localizationService.translate(
            'START.EXPERT.VIEW.CHOOSE_TYPE_PHONE'
        );

        if (!this.isCSI) {
            this.nextState = 'start.invite';
            this.getGroupPhoneNumbers();
        } else if (!this.csiSettings.voiceCallAddressBook) {
            this.stateHelper.safeGo('start.main');
        }
    }

    skip() {
        this.stateHelper.safeGo('start.invite');
    }

    selectExpertNumber(number) {
        this.modalOpen = false;
        this.expertForm.phone = number;
    }

    getGroupPhoneNumbers() {
        return this.db.User.groupPeers({
            params: {
                id: this.currentUser._id
            }
        }).then((peers) => {
            this.groupPeers = filter(peers.data, (peer) => {
                return (
                    peer._id !== this.currentUser._id &&
                    peer.phoneNumber &&
                    (peer.role === this.roles.TECHNICIAN || peer.role === this.roles.TECHNICIAN_SUPERVISOR)
                );
            });
        });
    }
}
