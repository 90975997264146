'use strict';

import tsBusySpinnerView from './ts-busy-spinner.view.html';
import './ts-busy-spinner.style.scss';

const DEFAULT_SIZE = 100;

class tsBusySpinnerController {
    constructor() {
        'ngInject';

        this.spinnerSize = this.size || DEFAULT_SIZE;
    }

    spinnerStyle() {
        return {
            width: `${this.spinnerSize}px`,
            height: `${this.spinnerSize}px`,
            'border-radius': `${this.spinnerSize}px`
        };
    }
}

export function tsBusySpinnerDirective() {
    'ngInject';

    return {
        template: tsBusySpinnerView,
        transclude: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            size: '='
        },
        controller: tsBusySpinnerController,
        controllerAs: 'vm'
    };
}
