'use strict';

import './change-url.style.scss';

export default class changeUrlController {
    constructor($uibModalInstance, initialUrl, patternsConstant) {
        'ngInject';

        this.$uibModalInstance = $uibModalInstance;
        this.initialUrl = initialUrl;
        this.urlToConfirm = initialUrl;
        this.urlPattern = patternsConstant.url;
    }

    ok() {
        this.$uibModalInstance.close({newUrl: this.urlToConfirm});
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }
}
