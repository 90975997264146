export function onCarouselChangeDirective($parse) {
    'ngInject';

    return {
        require: '^uibCarousel',
        link: function (scope, element, attrs, carouselCtrl) {
            const fn = $parse(attrs.onCarouselChange);
            const origSelect = carouselCtrl.select;

            // eslint-disable-next-line no-unused-vars
            carouselCtrl.select = function (nextSlide, direction, nextIndex) {
                if (nextSlide !== this.currentSlide) {
                    fn(scope, {
                        nextSlide: nextSlide,
                        direction: direction,
                        nextIndex: carouselCtrl.slides.indexOf(nextSlide)
                    });
                }

                return origSelect.apply(this, arguments);
            };
        }
    };
}
