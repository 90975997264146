'use strict';

import tsConnectToCenterView from './ts-connect-to-center.view.html';
import './ts-connect-to-center.style.scss';

export function tsConnectToCenterDirective() {
    'ngInject';

    return {
        template: tsConnectToCenterView,
        transclude: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            roomCode: '=',
            waitForExpert: '=',
            supportVoiceCalls: '=',
            defaultPhoneNumber: '=',
            icon: '=',
            launchPhoneOptions: '&'
        },
        controller: () => ({}),
        controllerAs: 'vm'
    };
}
