import React from 'react';
import {observer} from 'mobx-react';
import {GenericModal} from '@techsee/techsee-ui-common/lib/modals/generic';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {getRootStore} from '../../app.bootstrap';
import Translate from '../Translate';
import {IframeOverlay} from '../iframe-overlay/IfremOverlay';

import './terms-notice.style.scss';
import {TermsMessageType} from '@techsee/techsee-client-infra/lib/services/AccountService';
import {ITermsNoticeController} from './terms-notice.controller';

//TODO when move to react so pass the controller via props gilad bergmann
interface ITermsAndCondition {
    controller?: ITermsNoticeController;
}

@observer
export class TermsAndConditionComponent extends TechseeBaseComponent<ITermsAndCondition> {
    renderToolbar = () => {
        const termsController = getRootStore().termsAndConditionsController;

        return (
            <div>
                <div className='row'>
                    <button
                        type='button'
                        className='btn-default col-xs-6'
                        onClick={termsController.cancel}
                        aria-label={termsController.termsCancelButtonAria}>
                        {termsController.termInfo?.customCancelMessage && (
                            <span>{termsController.termInfo?.customCancelMessage}</span>
                        )}
                        {!termsController.termInfo?.customCancelMessage && (
                            <Translate text={'TERMS.VIEW.CANCEL'}></Translate>
                        )}
                    </button>
                    <button
                        type='button'
                        className='btn-default col-xs-6 terms-approval-button'
                        onClick={termsController.ok}
                        autoFocus
                        aria-label={termsController.termsAllowButtonAria}>
                        {termsController.termInfo?.allowTermsMessage && (
                            <span>{termsController.termInfo?.allowTermsMessage}</span>
                        )}
                        {!termsController.termInfo?.allowTermsMessage && (
                            <Translate text={'TERMS.VIEW.ALLOW'}></Translate>
                        )}
                    </button>
                </div>
            </div>
        );
    };

    renderCustomTerms = () => {
        const termsController = getRootStore().termsAndConditionsController;

        return termsController.termsTranslationStrings.map((part, index) => (
            <span key={index}>
                {part.type === TermsMessageType.TEXT && <span dangerouslySetInnerHTML={{__html: part.label}} />}
                {!termsController.openTermsLinksOnNewWindow &&
                    part.label &&
                    part &&
                    part.type !== TermsMessageType.TEXT && (
                        <IframeOverlay iframeSrc={part.url} onToggle={termsController.termsToggle}>
                            <span className='ts-link-appearance'>{part.label}</span>
                        </IframeOverlay>
                    )}

                {termsController.isPackageSupportedType && part.type === TermsMessageType.TERMS && !part.label && (
                    <IframeOverlay iframeSrc={part.url} onToggle={termsController.termsToggle}>
                        <span className='ts-link-appearance'>
                            TechSee <Translate text={'TERMS.VIEW.TERMS'}></Translate>
                        </span>
                    </IframeOverlay>
                )}

                {termsController.openTermsLinksOnNewWindow && part.type !== TermsMessageType.TEXT && (
                    <a href={part.url} target={'_blank'}>
                        <span className='ts-link-appearance'>{part.label}</span>
                    </a>
                )}
            </span>
        ));
    };

    renderDefaultTerms = () => {
        const termsController = getRootStore().termsAndConditionsController;

        return (
            <div>
                <Translate text={'TERMS.VIEW.TERMS_SUBTEXT_1B'}></Translate>

                {!termsController.openTermsLinksOnNewWindow && termsController.termInfo?.termsURL && (
                    <IframeOverlay
                        iframeSrc={termsController.termInfo?.termsURL}
                        onToggle={termsController.termsToggle}>
                        <span className='ts-link-appearance'>
                            TechSee <Translate text={'TERMS.VIEW.TERMS'}></Translate>
                        </span>
                    </IframeOverlay>
                )}

                {termsController.openTermsLinksOnNewWindow && termsController.termInfo?.termsURL && (
                    <a href={termsController.termInfo?.termsURL} target={'_blank'}>
                        <span className='ts-link-appearance'>
                            TechSee <Translate text={'TERMS.VIEW.TERMS'}></Translate>
                        </span>
                    </a>
                )}

                {termsController.termInfo?.accountTermsUrl && termsController.termInfo?.termsURL && (
                    <Translate text={'TERMS.VIEW.TERMS_SUBTEXT_2'}></Translate>
                )}

                {!termsController.openTermsLinksOnNewWindow && termsController.termInfo?.accountTermsUrl && (
                    <IframeOverlay
                        iframeSrc={termsController.termInfo?.accountTermsUrl}
                        onToggle={termsController.termsToggle}>
                        <span className='ts-link-appearance'>
                            {termsController.termInfo?.companyName}
                            <Translate text={'TERMS.VIEW.TERMS'}></Translate>
                        </span>
                    </IframeOverlay>
                )}

                {termsController.openTermsLinksOnNewWindow && termsController.termInfo?.accountTermsUrl && (
                    <a href={termsController.termInfo?.accountTermsUrl} target={'_blank'}>
                        <span className='ts-link-appearance'>
                            {termsController.termInfo?.companyName}
                            <Translate text={'TERMS.VIEW.TERMS'}></Translate>
                        </span>
                    </a>
                )}

                {termsController.termInfo?.privacyURL &&
                    (termsController.termInfo?.accountTermsUrl || termsController.termInfo?.termsURL) && (
                        <Translate text={'TERMS.VIEW.TERMS_SUBTEXT_3'}></Translate>
                    )}

                {!termsController.openTermsLinksOnNewWindow && termsController.termInfo?.privacyURL && (
                    <IframeOverlay
                        iframeSrc={termsController.termInfo?.privacyURL}
                        onToggle={termsController.termsToggle}>
                        <span className='ts-link-appearance'>
                            <Translate text={'TERMS.VIEW.PRIVACY'}></Translate>
                        </span>
                    </IframeOverlay>
                )}

                {termsController.openTermsLinksOnNewWindow && termsController.termInfo?.privacyURL && (
                    <a href={termsController.termInfo?.privacyURL} target={'_blank'}>
                        <span className='ts-link-appearance'>
                            <Translate text={'TERMS.VIEW.PRIVACY'}></Translate>
                        </span>
                    </a>
                )}
            </div>
        );
    };

    renderContent = () => {
        const termsController = getRootStore().termsAndConditionsController;

        if (termsController.isWaitingForAgentToAskReprompt) {
            return (
                <div>
                    <Translate text={'TERMS.VIEW.WAITING_FOR_AGENT'}></Translate>
                </div>
            );
        }

        const terms = termsController.useCustomTermsMessage ? this.renderCustomTerms : this.renderDefaultTerms;

        return (
            <div
                className={`${termsController.useCustomTermsMessage ? 'custom-terms-body' : ''}`}
                role='alert'
                aria-hidden={!!termsController.termsWindowOpen}>
                {terms()}
            </div>
        );
    };
    renderInternal() {
        const termsAndConditions = getRootStore().termsAndConditionsController;

        if (!termsAndConditions.isModalOpen) {
            return <React.Fragment></React.Fragment>;
        }

        return (
            <div className={'terms-container'}>
                <GenericModal
                    isCustomPosition={true}
                    className={`${this.renderCssClasses()} terms-notice-modal custom-position`}
                    header={() => <React.Fragment />}
                    content={this.renderContent}
                    toolbar={this.renderToolbar}
                    isCloseVisible={false}
                    onHideHandler={() => {}}
                    show={true}></GenericModal>
            </div>
        );
    }
}
