'use strict';

import './upload-confirmation.style.scss';

export default class uploadConfirmationController {
    constructor($uibModalInstance, urlToConfirm) {
        'ngInject';

        this.$uibModalInstance = $uibModalInstance;
        this.urlToConfirm = urlToConfirm;
    }

    ok() {
        this.$uibModalInstance.close();
    }

    cancel() {
        this.$uibModalInstance.dismiss();
    }
}
