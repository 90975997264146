'use strict';

export class CenterController {
    constructor(tsStateHelper, currentUser, csiSettings) {
        'ngInject';

        this.stateHelper = tsStateHelper;
        this.csiSettings = csiSettings;
        this.isCSI = !currentUser && csiSettings;

        if (this.isCSI && !this.csiSettings.voiceCallFixedNumber) {
            this.stateHelper.safeGo('start.main');
        }
    }
}
