'use strict';

import tsActionGuidanceView from './ts-action-guidance.view.html';
import './ts-action-guidance.style.scss';

export function tsActionGuidanceDirective() {
    'ngInject';

    return {
        template: tsActionGuidanceView,
        transclude: true,
        restrict: 'E',
        scope: {},
        bindToController: {
            guidanceWidth: '=',
            showArrow: '=',
            sideLabel: '='
        },
        controller: () => ({}),
        controllerAs: 'vm'
    };
}
